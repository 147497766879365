import React from 'react';
import Add from './BREAD/Add';
import QuestionCard from './QuestionCard';
import { Button } from '../../../../components/forms/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';
import { deleteTest } from '../../../../services/tests/testService';
import { toast } from 'react-toastify';

const AdminTest = ({ test, questions, refreshQuestions }) => {
  const navigate = useNavigate();
  const QUESTION_TYPES_MBTI = [
    'Introverted-Extraverted IE',
    'Intuitive-Observant NS',
    'Thinking-Feeling TF',
    'Judging-Prospecting JP',
  ];
  const QUESTION_TYPES_MIT = [
    'Naturalist Intelligence',
    'Musical Intelligence',
    'Logical Intelligence',
    'Existential Intelligence',
    'Interpersonal Intelligence',
    'Kinesthetic Intelligence',
    'Verbal Intelligence',
    'Intrapersonal Intelligence',
    'Visual Intelligence',
  ];
  const QUESTION_TYPES = [...QUESTION_TYPES_MBTI, ...QUESTION_TYPES_MIT];

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-row gap-2'>
        <Button
          className='max-w-min'
          text='back'
          onClick={() =>
            navigate({
              pathname: `/tests`,
            })
          }
        />
        <Button
          text={'descriptions'}
          onClick={() =>
            navigate({
              pathname: `/tests/${test.uuid}/descriptions`,
            })
          }
        />
      </div>

      <div className='flex flex-row justify-between gap-2'>
        <div />
        <span className='text-xl font-bold'>{test?.name}</span>
        <TrashIcon
          className='text-red-500  h-9 cursor-pointer hover:bg-error bg-red-100 hover:text-white rounded-lg p-2'
          onClick={() => {
            if (!window.confirm('Are you sure you want to delete?')) return;
            deleteTest(test.uuid)
              ?.then(() => {
                toast.success(`Test "${test.name}" deleted`);
                navigate({
                  pathname: `/tests`,
                });
              })
              .catch(() => {
                toast.error(`Error on deleting test "${test.name}"`);
              });
          }}
        />
      </div>

      <hr className='border-b border-slate-300' />
      <center>
        <div className='grid 2xl:grid-cols-5 grid-cols-4 gap-2 max-w-fit  '>
          {questions?.map((question, i) => {
            return (
              <QuestionCard
                key={question?.uuid}
                question={question}
                index={i}
                getData={refreshQuestions}
                questionTypes={QUESTION_TYPES}
                test={test}
                typeIndex={test?.type ? 4 : 0}
              />
            );
          })}
          <Add
            className='w-60 text-2xl lg:h-36'
            questionId={questions?.length + 1}
            test={test}
            getData={refreshQuestions}
            questionTypes={
              test?.type ? QUESTION_TYPES_MIT : QUESTION_TYPES_MBTI
            }
            typeIndex={test?.type ? 4 : 0}
          />
        </div>
      </center>
    </div>
  );
};

export default AdminTest;

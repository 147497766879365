import React from 'react';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../../forms/Buttons/Button';
import { useTranslation } from 'react-i18next';

export default function DeleteModal({ open, setOpen, onClose, proceed }) {
  const [t] = useTranslation();
  return (
    <div
      className={`${
        open ? '' : 'hidden'
      } fixed z-10 flex md:items-center md:justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-60
        inset-0`}
    >
      <div className='h-full px-4 md:h-auto flex flex-col justify-center'>
        <div
          className='flex flex-col gap-8 rounded-lg bg-white p-4 md:p-5 drop-shadow-lg
          w-[90vw] md:w-fit'
        >
          <div className='flex flex-wrap justify-between'>
            <div className='flex flex-row gap-2'>
              <TrashIcon className='h-6 text-error' />
              <h6 className='self-end text-xl font-semibold'>
                {t('buttons.delete')}
              </h6>
            </div>
            <XMarkIcon
              className='h-6 cursor-pointer text-secondary'
              onClick={() => {
                if (onClose) onClose();
                setOpen(false);
              }}
            />
          </div>
          <div className='flex flex-col items-center mx-auto w-fit md:w-96 gap-4'>
            <div className='text-lg -mt-2'>{t('roadmap.are_you_sure')}</div>
            <div className='flex flex-row gap-2'>
              <Button
                color='secondary'
                text={t('buttons.cancel')}
                onClick={() => {
                  setOpen(false);
                }}
              />
              <Button
                color='error'
                text={t('buttons.delete')}
                onClick={() => {
                  proceed();
                  setOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

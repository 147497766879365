import React from 'react';
// import headerImg from '../../assets/images/roadmap/Frame1846.svg';
// import blueHeaderImg from '../../assets/images/projects/Frame1883.svg';
import blueHeaderImgIns from '../../assets/images/projects/Frame1880.svg';
import { useMediaQuery } from 'react-responsive';

const Title = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <div className='flex flex-col text-white'>
      <div
        id='bluediv'
        className='flex flex-col gap-8 items-center justify-center py-4 rounded-xl
            4xl:bg-[length:50%] xl:bg-[length:55%] bg-[length:80%]
            transition-all ease-in duration-500 cursor-pointer'
        style={{
          backgroundImage: !isMobile && `url(${blueHeaderImgIns})`,
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#2F80ED',
          backgroundSize: 'cover',
        }}
      >
        <div className='flex flex-row justify-center items-center gap-8'>
          <div className='text-3xl font-bold'>Instructions</div>
        </div>
      </div>
    </div>
  );
};

export default Title;

import React from 'react';
import RichTextEditor from '../../../../../components/forms/Inputs/RichTextEditor';

const TextContent = ({ data }) => {
  return (
    <p className='flex flex-col'>
      <span>{'Text Content'}</span>
      <span className='text-sm'>
        Text:
        <RichTextEditor readOnly value={data.text} />
      </span>
    </p>
  );
};
export default TextContent;

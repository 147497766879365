import React from 'react';
import {
  patchAccount,
  patchAccountPicture,
} from '../../services/auth/authService';
import { useState } from 'react';
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { PencilIcon } from '@heroicons/react/24/solid';
import EmptyModal from '../../components/modals/EmptyModal/EmptyModal';
import InputWithIcon from '../../components/forms/Inputs/InputWithIcon';
import {
  AcademicCapIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  PhoneIcon,
  PuzzlePieceIcon,
  //   TrophyIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../components/forms/Buttons/Button';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const SIZE_LIMIT = 4194304;

const ProfileInfo = ({ isOwnProfile, refreshData, user, loading }) => {
  const inputRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  //   const [olympiad, setOlympiad] = useState();
  const [hobby, setHobby] = useState();
  const { t } = useTranslation();

  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    console.log('user', user);
    if (user) {
      setEmail(user?.email);
      setPhoneNumber(user?.phone_number);
      // setOlympiad(res?.olympiad);
      setHobby(user?.additional_info);
    }
  }, [user]);

  return (
    <div>
      {loading ? (
        <SimpleLoader className={'w-12 h-12 my-12'} />
      ) : (
        <div className='flex flex-col md:flex-row gap-4'>
          <div className='flex flex-row justify-center items-center mx-12'>
            <div className='relative inline-block w-40 h-40 group'>
              {user?.profile_picture ? (
                <img
                  src={user?.profile_picture}
                  alt='User Avatar'
                  className='w-40 h-40 rounded-full object-cover border-8 border-primary border-opacity-25'
                />
              ) : (
                <div className='w-40 h-40 bg-blue-500 text-white rounded-full flex items-center justify-center text-6xl font-semibold'>
                  {user?.first_name[0]}
                </div>
              )}
              {isOwnProfile && (
                <div
                  className='absolute top-0 rounded-full w-40 h-40 bg-slate-800 opacity-40 hidden group-hover:flex
                    flex-row justify-center items-center cursor-pointer'
                  onClick={() => inputRef.current.click()}
                >
                  <PencilSquareIcon className='h-12 text-white' />
                </div>
              )}
            </div>
          </div>
          <div className='w-full border rounded-lg p-4 flex flex-col gap-2'>
            <div className='flex flex-row justify-between border-b-2 pb-2'>
              <div className='flex flex-col'>
                <div className='font-semibold text-lg'>{`${user?.first_name} ${user?.last_name}`}</div>
                <div>{`${user?.username} | ${
                  user?.usergroup ?? user?.roles?.[0]?.name
                }`}</div>
              </div>
              {isOwnProfile && (
                <div
                  className='rounded-full border-2 border-primary h-9 w-9 flex flex-col justify-center items-center
            group hover:bg-primary cursor-pointer'
                  onClick={() => setOpen(true)}
                >
                  <PencilIcon className='h-5 w-5 text-primary group-hover:text-white' />
                </div>
              )}
            </div>
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row gap-4 items-center'>
                <a id='AcademicCapIcon'><AcademicCapIcon className='h-5' /></a>
                <Tooltip anchorSelect='#AcademicCapIcon' content={t('profile_info.olympiad_subject')} />
                <div>
                  {!!user?.olympiad
                    ? `${user?.olympiad} olympiad`
                    : !!user?.domain_subject?.name
                    ? `${user?.domain_subject?.name} subject`
                    : '-'}
                </div>
              </div>
              <div className='flex flex-row gap-4 items-center'>
                <a id='PhoneIcon'><PhoneIcon className='h-5' /></a>
                <Tooltip anchorSelect='#PhoneIcon' content={t('profile_info.phone_number')} />
                <div>{user?.phone_number || '-'}</div>
              </div>
              <div className='flex flex-row gap-4 items-center'>
                <a id='EnvelopeIcon'><EnvelopeIcon className='h-5' /></a>
                <Tooltip anchorSelect='#EnvelopeIcon' content={t('profile_info.email')} />
                <div>{user?.email || '-'}</div>
              </div>
              {/* <div className='flex flex-row gap-4 items-center'>
                <TrophyIcon className='h-5' />
                <div>{user?.olympiad || '-'}</div>
              </div> */}
              <div className='flex flex-row gap-4 items-center'>
                <a id='PuzzlePieceIcon'><PuzzlePieceIcon className='h-5' /></a>
                <Tooltip anchorSelect='#PuzzlePieceIcon' content={t('profile_info.hobbies')} />
                <div>{user?.additional_info || '-'}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <EmptyModal
        open={open}
        setOpen={setOpen}
        headerText={'Profile additional information'}
      >
        <div className='w-96 flex flex-col -mt-4 gap-4'>
          <div className='w-full flex flex-col gap-3 p-2'>
            <InputWithIcon
              label={'Email'}
              Icon={EnvelopeIcon}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={'Enter email'}
              type='email'
            />
            <InputWithIcon
              label={'Phone number'}
              Icon={PhoneIcon}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={'Enter phone number'}
              type='tel'
              pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
            />
            {/* <InputWithIcon
              label={'Olympiad'}
              Icon={TrophyIcon}
              value={olympiad}
              onChange={(e) => setOlympiad(e.target.value)}
              placeholder={'Enter olympiad'}
            /> */}

            <InputWithIcon
              label={'Hobbies'}
              Icon={PuzzlePieceIcon}
              value={hobby}
              onChange={(e) => setHobby(e.target.value)}
              placeholder={'Enter hobbies'}
            />
          </div>
          <div className='flex flex-row gap-2 justify-end'>
            <Button
              text={'Cancel'}
              color={'secondary'}
              onClick={() => setOpen(false)}
            />
            <Button
              loading={loadingSave}
              text={'Save'}
              color={'primary'}
              onClick={() => {
                if (
                  email &&
                  !email.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  )
                ) {
                  toast.error('Email is not valid');
                  return;
                }
                const body = new FormData();
                body.append('email', email);
                body.append('phone_number', phoneNumber);
                // body.append('olympiad', olympiad);
                body.append('additional_info', hobby);

                setLoadingSave(true);
                patchAccount(user?.uuid, body)
                  .then((res) => {
                    refreshData();
                    setOpen(false);
                  })
                  .finally(() => setLoadingSave(false));
              }}
            />
          </div>
        </div>
      </EmptyModal>
      <input
        ref={inputRef}
        type='file'
        accept='image/*'
        style={{ display: 'none' }}
        onChange={(e) => {
          if (!e?.target?.files[0]) {
            return;
          }
          if (e?.target?.files[0]?.size < SIZE_LIMIT) {
            // setFile(e?.target?.files[0]);
            const body = new FormData();
            body.append('profile_picture', e?.target?.files[0]);
            patchAccountPicture(user?.uuid, body).then(() => {
              window.location.reload();
            });
            console.log('e?.target?.files[0]', e?.target?.files[0]);
          } else {
            toast.error('File size is too big');
          }
        }}
      />
    </div>
  );
};

export default ProfileInfo;

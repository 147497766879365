import bilAPI from '../api';

export const getAccountData = ({ role, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/account_auth_data/`, {
          params: {
            role,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getAccountDatum = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/account_auth_data/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import { useMemo, useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../../components/forms/Buttons/Button';
import ReactApexChart from 'react-apexcharts';


const OrgRoadmapStats = ({ onSelect, orgs, setOrgs }) => {
  const [ordering, setOrdering] = useState('has_roadmap');
  const [view, setView] = useState('table'); // State to toggle between table and chart view

  const headers = useMemo(
    () => [
      { title: 'School', key: 'name' },
      { title: 'Total Students', key: 'total_students' },
      { title: 'Roadmaps Generated', key: 'has_roadmap' },
      { title: 'Roadmaps In Progress', key: 'doing_roadmap' },
    ],
    [],
  );

  useEffect(() => {
    // Sort orgs by "Roadmaps Generated" (has_roadmap) in descending order by default
    setOrgs((prev) =>
      [...prev].sort((a, b) => b.has_roadmap - a.has_roadmap)
    );
  }, [setOrgs]);

  const reorder = (col) => {
    let s = 1;
    if (col === 'name') {
      s = -1;
    }
    if (ordering === `-${col}`) {
      setOrdering(col);
      setOrgs((prev) => prev.sort((a, b) => (a[col] > b[col] ? s : -s)));
    } else if (ordering === col) {
      setOrdering('-name');
      s = -1;
      setOrgs((prev) => prev.sort((a, b) => (a.name < b.name ? s : -s)));
    } else {
      setOrdering(`-${col}`);
      setOrgs((prev) => prev.sort((a, b) => (a[col] < b[col] ? s : -s)));
    }
  };

  const sortedOrgs = [...orgs].sort((a, b) => {
    const aPercentage = a.has_roadmap / a.total_students;
    const bPercentage = b.has_roadmap / b.total_students;
    return bPercentage - aPercentage;
  });

  // Chart configuration
  const chartOptions = {
    chart: {
      type: 'bar',
      height: sortedOrgs.length * 50, // Dynamically adjust height based on number of schools
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%', // Adjust bar height for better readability
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(0)}%`,
      style: {
        fontSize: '18px',
      },
    },
    colors: ['#55c447', '#FFA500', '#427ef5'],
    xaxis: {
      categories: sortedOrgs.map((org) => org.name), // Display school names on y-axis, sorted by percentage
      title: {
        text: 'Percentage (%)',
      },
    },
    yaxis: {
      title: {
        text: 'School',
      },
      labels: {
        style: {
          fontSize: '18px',
        },
      },
    },
    
    title: {
      text: 'School Data',
      align: 'left',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left', // Center align the legend
      fontSize: '14px',
      offsetY: -10, // Move the legend closer to the chart
    },
  };

  // Series data for stacked bars, based on sorted orgs
  const chartSeries = [
    {
      name: 'Roadmaps In Progress',
      data: sortedOrgs.map((org) => (100 * org.doing_roadmap) / org.total_students),
    },
    {
      name: 'Roadmaps Generated (Not In Progress)',
      data: sortedOrgs.map((org) => (100 * (org.has_roadmap - org.doing_roadmap)) / org.total_students),
    },
    {
      name: 'No Roadmaps',
      data: sortedOrgs.map((org) => (100 * (org.total_students - org.has_roadmap)) / org.total_students),
    },
  ];

  return (
    <div className='flex flex-col p-6 border rounded-lg'>
      <div className='flex flex-row justify-between items-center mb-4'>
        <div className='flex flex-row gap-3'>
          <Button
            text={'Table'}
            rounded
            color={view === 'table' ? 'primary' : ''}
            onClick={() => setView('table')}
          />
          <Button
            text={'Chart'}
            rounded
            color={view === 'chart' ? 'primary' : ''}
            onClick={() => setView('chart')}
          />
        </div>
      </div>

      {view === 'chart' ? (
        <ReactApexChart options={chartOptions} series={chartSeries} type='bar' height={orgs.length * 50} />
      ) : (
        <table className='table-auto w-full mt-4'>
          <thead>
            <tr>
              <th className='text-center px-4 w-1/12'>#</th>
              {headers?.map((h, i) => (
                <th
                  className={`${!i && 'text-left'}  w-4 cursor-pointer`}
                  key={h.key}
                  onClick={() => reorder(h.key)}
                >
                  <div
                    className={`flex flex-row items-center gap-1 ${
                      !i ? 'text-left' : 'justify-center'
                    }`}
                  >
                    {h.title}
                    {h.key === ordering ? (
                      <ChevronUpIcon className='h-4' />
                    ) : `-${h.key}` === ordering ? (
                      <ChevronDownIcon className='h-4' />
                    ) : (
                      <ChevronDownIcon className='h-4 invisible' />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orgs?.map((s, i) => {
              return (
                <tr key={s?.uuid} className='even:bg-gray-100'>
                  <td className='text-center p-2 px-4 w-1/12'>{i + 1}</td>
                  {headers?.map((h, i) => (
                    <td
                      className={`${
                        i ? 'text-center' : 'cursor-pointer hover:text-primary'
                      } w-4`}
                      onClick={() => {
                        if (i) return;
                        onSelect({ name: s.name, uuid: s.uuid });
                      }}
                      key={h.key}
                    >
                      {s[h.key]}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OrgRoadmapStats;

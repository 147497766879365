import React, { useContext } from 'react';
import { UserContext } from '../../utils/contexts/UserContext';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import TeacherSubmissions from './TeacherSubmissions/TeacherSubmissions';
import AdminSubmissions from './AdminSubmissions/AdminSubmissions';

export default function Submissions() {
  const { user } = useContext(UserContext);

  if (user?.roles?.some((r) => r?.name === 'PROJECTADMIN')) {
    return <AdminSubmissions />;
  }

  if (user?.roles?.some((r) => r?.name === 'PROJECTREVIEWER')) {
    return <TeacherSubmissions />;
  }

  return <SimpleLoader />;
}

import React from 'react';
import CourseCard from './CourseCard';

const Student = ({ data, refreshData }) => {
  return (
    <div className='flex flex-row gap-2 flex-wrap'>
      {data?.length ? (
        data.map((d) => {
          return <CourseCard key={d.uuid} data={d} refreshData={refreshData} />;
        })
      ) : (
        <div className='w-full flex flex-row justify-center py-2 mt-4 text-gray-400 text-lg'>
          No content
        </div>
      )}
    </div>
  );
};
export default Student;

import React, { useState } from 'react';
import CustomDialog from '../../../../components/modals/CustomDialog/CustomDialog';
import { DocumentIcon } from '@heroicons/react/24/outline';
import FileCard from './FileCard';
import AddFile from './AddFile';

const Files = ({ description, refreshData }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DocumentIcon
        className='h-9 cursor-pointer text-blue-500 hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-1.5'
        onClick={() => setOpen(true)}
      />
      <CustomDialog isOpen={open} closeModal={() => setOpen(false)}>
        <div className='flex flex-col gap-2 w-full'>
          {description?.files?.map((file) => (
            <FileCard key={file?.uuid} file={file} refreshData={refreshData} />
          ))}
          <AddFile refreshData={refreshData} description={description} />
        </div>
      </CustomDialog>
    </>
  );
};

export default Files;

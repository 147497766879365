import bilAPI from '../api';

export const signInWithSSO = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/auth/sso_login/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const signUpWithSSO = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/auth/sso_signup/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

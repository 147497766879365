import bilAPI from '../api';

export const getProjectCredits = (uuid, indicator) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/projects/project_credits/${uuid}/`, {
          params: {
            indecator: indicator,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { deleteLesson } from '../../../../../services/courses/lessonService';

const DeleteLesson = ({ lesson, refreshData, data }) => {
  return (
    <TrashIcon
      className='text-red-500 h-9 cursor-pointer hover:bg-error bg-red-100 hover:text-white rounded-lg p-2'
      onClick={() => {
        let is_confimed = window.confirm(
          `Are you sure you want to delete lesson "${lesson.name}"?`,
        );
        if (!is_confimed) {
          return;
        }
        deleteLesson(lesson.uuid).then(() => {
          refreshData();
        });
      }}
    />
  );
};

export default DeleteLesson;

import bilAPI from '../api';

export const getRoadmapSubtasks = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`roadmap/subtasks/`, {
          params: {
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getRoadmapSubtask = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`roadmap/subtasks/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postRoadmapSubtask = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`roadmap/subtasks/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchRoadmapSubtask = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`roadmap/subtasks/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteRoadmapSubtask = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`roadmap/subtasks/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import React, { useRef } from 'react';
// import headerImg from '../../assets/images/roadmap/Frame1846.svg';
import blueHeaderImg from '../../assets/images/roadmap/Frame1841.svg';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Description = () => {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (open) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight('0px');
    }
  }, [open]);

  return (
    <div className='flex flex-col text-white'>
      <div
        id='bluediv'
        className='flex flex-col gap-8 items-center justify-center px-8 pt-12 pb-4 rounded-xl
            4xl:bg-[length:50%] xl:bg-[length:55%] bg-[length:80%]
            transition-all ease-in duration-500 cursor-pointer'
        style={{
          backgroundImage: `url(${blueHeaderImg})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#2F80ED',
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className='flex flex-row justify-center items-center gap-8'>
          <div className='text-3xl font-bold'>{t('roadmap.banner_title')}</div>
          {open ? (
            <ChevronUpIcon className='h-8' />
          ) : (
            <ChevronDownIcon className='h-8' />
          )}
        </div>
        <div
          ref={contentRef}
          className='flex flex-col gap-4 text-lg lg:px-24 md:px-20 px-4 transition-all duration-500 ease-in-out overflow-hidden'
          style={{ height: height }}
        >
          <div>
            <span className='font-semibold'>{t('roadmap.roadmap')}</span>{' '}
            {t('roadmap.banner_description')}
          </div>
          <div className='flex flex-col'>
            <p className='font-semibold'>{t('roadmap.why_use_roadmap')}</p>
            <div className='whitespace-pre-line'>
              {t('roadmap.banner_reasons')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;

import React from 'react';
import { replaceHtmlEntities } from '../../../../utils/helpers/helpers';
import { getTranlated } from '../../helper';

const Campus = ({ info }) => {
  return (
    <div className='flex flex-col md:gap-12 gap-8 border rounded-lg p-4 lg:px-36 md:px-24'>
      {info?.campusInformation?.map((ci) => (
        <div key={ci?.id} className='flex flex-col gap-2'>
          <div className='text-2xl font-semibold'>
            {getTranlated('title', ci)}
          </div>
          <div className='flex md:flex-row flex-col md:gap-8 gap-4 items-center'>
            <div className='md:w-1/2'>
              {replaceHtmlEntities(
                getTranlated('description', ci)?.replace(/(<([^>]+)>)/gi, ''),
              )}
            </div>
            {ci?.image && (
              <div className='md:w-1/2 w-full'>
                <img
                  src={
                    ci?.image.startsWith('http')
                      ? ci?.image
                      : `https://unitap.org/nova/files/${ci?.image}`
                  }
                  alt='img'
                  className='object-cover w-full h-72 rounded-xl object-bottom'
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Campus;

import React from 'react'
import ReactQuill,{Quill} from 'react-quill'
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';


const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji
  }, true);


const modules = {
    toolbar: [
      [{ 'font': [] }, { 'header': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['emoji'],
      ['link',],
      ['clean']
    ],
    'emoji-toolbar': true,
    "emoji-textarea": true,
    "emoji-shortname": true,
  }
  
const formats = ['font', 'header', 'bold',
                 'italic', 'underline', 'strike', 'blockquote', 
                 'code-block', 'color', 'background', 
                 'list', 'indent', 'align',
                 'link', 'image', 'clean', 
                 'emoji']


const CustomQuill = ({value,onChange}) => {
    return (
        <div>
        <ReactQuill
        placeholder='write something...'
        className="w-full h-72 border-none"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        />
        </div>
        
    )
}

export default CustomQuill
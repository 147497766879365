import { ArrowPathIcon, PlayIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../utils/helpers/helpers';

const TestCard = ({ test, i, isSelected, select }) => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col border rounded-lg mt-2 w-full md:max-w-fit min-w-[12rem]'>
      <div
        className={`relative p-4 pb-2 ${
          isSelected ? 'bg-blue-200' : 'bg-slate-100 cursor-pointer'
        }`}
        onClick={() => {
          if (isSelected) return;
          select();
        }}
      >
        {`${i + 1}. ${test.name}`}
        {test?.results?.length ? (
          <div className='flex flex-row gap-1 absolute right-2 -bottom-4'>
            <span
              title='Retry'
              onClick={() =>
                navigate({
                  pathname: `/tests/${test.uuid}`,
                })
              }
              className='w-8 hover:w-[4.25rem] p-1 text-red-400 border rounded-full bg-white hover:bg-slate-200 cursor-pointer
               flex flex-row justify-start group transition-[width] duration-300 ease-out overflow-hidden'
            >
              <center className='flex flex-row gap-1'>
                <ArrowPathIcon className='w-5' />
                <span className='group-hover:contents hidden text-sm'>
                  Retry
                </span>
              </center>
            </span>
            {/* <EyeIcon
              title='Show result description'
              onClick={() =>
                navigate({
                  pathname: `/results/${test.uuid}`,
                })
              }
              className='w-8 h-8 p-1 text-red-400 border rounded-full bg-white hover:bg-slate-200 cursor-pointer'
            /> */}
          </div>
        ) : (
          <div className='flex flex-row gap-1 absolute right-2 -bottom-4'>
            <span
              title='Start'
              onClick={() =>
                navigate({
                  pathname: `/tests/${test.uuid}`,
                })
              }
              className='w-8 hover:w-[4.25rem] p-1 text-red-400 border rounded-full bg-white hover:bg-slate-200 cursor-pointer
               flex flex-row justify-start group transition-[width] duration-300 ease-out overflow-hidden'
            >
              <center className='flex flex-row gap-1'>
                <PlayIcon className='w-5' />
                <span className='group-hover:contents hidden text-sm'>
                  Start
                </span>
              </center>
            </span>
          </div>
        )}
      </div>
      <div className='flex flex-col gap-1 text-sm border-t p-4 pt-2'>
        {!!test?.results?.length && (
          <>
            <span>Result: {test.results[0].result}</span>
            <span>Last attempt: {formatDate(test.results[0].created)}</span>
            <span>Attempts: {test?.results?.length}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default TestCard;

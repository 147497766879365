import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import { useParams } from 'react-router-dom';
import Student from './Student/Student';
import ContentCreater from './ContentCreater/ContentCreater';
import { getLessons } from '../../../services/courses/lessonService';
import { getSections } from '../../../services/courses/sectionService';
import { getCourses } from '../../../services/courses/courseService';

const Lesson = () => {
  const { user } = useContext(UserContext);
  const { uuid } = useParams();
  const [lesson, setLesson] = useState();
  const [section, setSection] = useState();
  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(false);

  const getLessonsData = useCallback(
    (loading = true) => {
      if (loading) setLoading(true);
      getLessons({ uuid })
        .then((res) => {
          setLesson(res);
          if (
            section?.uuid !== res.section &&
            !user?.roles?.some((r) => r?.name === 'ADMIN')
          ) {
            getSections({ uuid: res?.section }).then((resSection) => {
              setSection(resSection);
              if (!course) {
                getCourses({ uuid: resSection?.course }).then((resCourses) => {
                  setCourse(resCourses);
                });
              }
            });
          }
        })
        .finally(() => {
          if (loading) setLoading(false);
        });
    },
    [course, section?.uuid, user?.roles, uuid],
  );

  useEffect(() => {
    getLessonsData();
  }, [getLessonsData]);

  if (user?.roles?.some((r) => ['ADMIN']?.includes(r?.name))) {
    return (
      <ContentCreater
        data={lesson}
        refreshData={getLessonsData}
        loading={loading}
      />
    );
    // } else if (user?.roles?.some((r) => r?.name === 'STUDENT')) {
    //   return (
    //     <Student
    //       data={lesson}
    //       refreshData={getLessonsData}
    //       section={section}
    //       course={course}
    //     />
    //   );
  } else {
    return (
      <Student
        data={lesson}
        refreshData={getLessonsData}
        section={section}
        course={course}
      />
    );
  }
};

export default Lesson;

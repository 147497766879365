import React, { useState } from 'react';
import Delete from './BREAD/Delete';
import Edit from './BREAD/Edit';

const QuestionCard = ({
  question,
  index,
  getData,
  test,
  questionTypes,
  typeIndex,
}) => {
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  return (
    <div className=' flex h-36 w-60 flex-col rounded-md border-2 p-4 justify-between'>
      <div className='flex flex-col'>
        <p className='flex flex-row justify-center text-lg font-bold'>
          {'Question'} № {index + 1}
        </p>
        <p className='flex flex-row justify-center'>
          {questionTypes[question?.type]}
        </p>
      </div>
      <div className='flex flex-col'>
        <p className='text-sm'>{question?.type?.name}</p>
        <div className='mt-1 flex flex-row justify-center'>
          {!isDeleteMode && (
            <Edit
              question={question}
              questionId={index + 1}
              questionTypes={questionTypes}
              getData={getData}
              test={test}
              typeIndex={typeIndex}
            />
          )}
          <Delete
            question={question?.uuid}
            questionId={index + 1}
            getData={getData}
            isDeleteMode={isDeleteMode}
            setIsDeleteMode={setIsDeleteMode}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;

import React from 'react';

import {
  deleteResultDescriptionProffesionRelated,
  patchResultDescriptionProffesionRelated,
} from '../../../../services/tests/resultService';

import CustomCreatableSelect from '../../../../components/forms/Select/CustomCreatableSelect';
import { postProfession } from '../../../../services/professions/professions';
import { Button } from '../../../../components/forms/Buttons/Button';
import { TrashIcon } from '@heroicons/react/24/outline';

const ProfessionCard = ({ prof_ref, professions, refreshData }) => {
  return (
    <div className='flex flex-row gap-2 border p-4 rounded-lg items-center w-full'>
      <span>{prof_ref?.order}.</span>
      <CustomCreatableSelect
        className='w-full'
        create={(label) => postProfession({ name: label })}
        defaultOptions={professions.map((prof) => ({
          value: prof.uuid,
          label: prof.name,
        }))}
        value={prof_ref.profession?.uuid}
        onChange={(v) => {
          patchResultDescriptionProffesionRelated({
            uuid: prof_ref.uuid,
            profession: v.value,
          });
          refreshData();
        }}
      />
      <Button
        Icon={TrashIcon}
        color='error'
        onClick={() => {
          deleteResultDescriptionProffesionRelated({
            uuid: prof_ref.uuid,
          })?.then(() => {
            refreshData();
          });
        }}
      />
    </div>
  );
};

export default ProfessionCard;

/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import bilAPI from '../../services/api';

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [positions, setPositions] = useState([]);
  const [isLoading, setLoading] = useState(true);

  async function getUser() {
    try {
      const response = await bilAPI.get('/auth/account_info/');
      if (response?.data) {
        setUser(response.data);
        const userRoles = response.data.roles?.map((role) => {
          return role.name;
        });
        setRoles(userRoles);
        // const userPositions = response.data.positions?.map((position) => {
        //   return position.code;
        // });
        // setPositions(userPositions);
        setLoading(false);
        setDefaultLocale(localStorage.getItem('i18nextLng'));
      }
    } catch {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function findUser() {
      try {
        const response = await bilAPI.get('/auth/account_info/');
        if (response?.data) {
          setUser(response.data);
          const userRoles = response.data.roles?.map((role) => {
            return role.name;
          });
          setRoles(userRoles);
          // const userPositions = response.data.positions?.map((position) => {
          //   return position.code;
          // });
          // setPositions(userPositions);
          setLoading(false);
          setDefaultLocale(localStorage.getItem('i18nextLng'));
        }
      } catch {
        setLoading(false);
      }
    }

    findUser();
  }, []);

  return {
    user,
    setUser,
    getUser,
    roles,
    positions,
    isLoading,
  };
}

import bilAPI from '../api';

export const postStudyPlanSection = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/studyplans/sections/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchStudyPlanSection = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/studyplans/sections/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteStudyPlanSection = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/studyplans/sections/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

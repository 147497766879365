import React, { useState } from 'react';
import { ModalComponent } from './ModalComponent';

export default function CreatePost({ user, onPostCreated }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePostCreated = () => {
    console.log('Post created');
    onPostCreated();
    setIsModalOpen(false); // Закрываем модальное окно после создания поста
  };

  return (
    <div className='hidden md:block justify-start items-center w-full h-auto mb-8 px-3 pb-6 pt-3 border border-gray-200 cursor-pointer rounded-md shadow-lg'>
      <img
        alt='userImage'
        className='w-14 h-14 rounded-md'
        src={
          user?.profile_picture ||
          'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
        }
      ></img>
      <input
        type='text'
        placeholder=' Create a Post'
        onClick={handleInputClick}
        className=' w-4/5  ml-8 p-3 rounded-xl border outline-none caret-transparent border-gray-200'
      />
      <ModalComponent
        user={user}
        isOpen={isModalOpen}
        onPostCreated={handlePostCreated}
        onRequestClose={handleCloseModal}
      />
    </div>
  );
}

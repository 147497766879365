import React, { useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';

import ImageUploader from 'quill-image-uploader';
import { postFile } from '../../../services/files/fileService';

Quill.register('modules/imageUploader', ImageUploader);

var icons = Quill.import('ui/icons');
icons['undo'] =
  '<div title="undo"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" /></svg></div>';
icons['redo'] =
  '<div title="redo"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3" /></svg></div>';

const RichTextEditor = React.forwardRef(
  (
    {
      className,
      label,
      required,
      type,
      value,
      textCenter,
      error,
      readOnly,
      theme,
      inputStyles,
      onChange,
      ...rest
    },
    ref,
  ) => {
    window.katex = katex;

    const modules = useMemo(
      () => ({
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },

        toolbar: {
          container: [
            [
              { header: [6, 5, 4, 3, 2, 1, false] },
              { font: [] },
              { size: ['small', false, 'large', 'huge'] },
            ],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
            [{ script: 'sub' }, { script: 'super' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
            ['link', 'image'],
            ['clean'],
            ['formula'],
            ['undo', 'redo'],
          ],
          handlers: {
            undo: function () {
              this.quill.history.undo();
            },
            redo: function () {
              this.quill.history.redo();
            },
          },
        },
        imageUploader: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              const body = new FormData();
              body.append('upload', file);

              postFile({ body })
                ?.then((res) => {
                  resolve(res?.upload);
                })
                ?.catch((err) => {
                  console.log(err);
                  reject(err);
                });
            });
          },
        },
      }),
      [],
    );

    const formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'color',
      'clean',
      'code',
      'background',
      'script',
      'formula',
      'font',
      'align',
      'size',
      'image',
      'imageBlot',
    ];

    const handleTextChange = (content, delta, source, editor) => {
      if (source === 'user') {
        console.log(delta);
        // sendChangeToServer(delta, docId);
      }

      if (onChange) {
        onChange(content, delta, source, editor);
      }
    };

    return (
      <div className={'block ' + className}>
        <span className='flex flex-row gap-1 text-sm'>
          {label}
          <span className={`text-error`}>{required && '*'}</span>
        </span>
        <ReactQuill
          type={type}
          readOnly={readOnly}
          required={required}
          theme={theme || readOnly ? 'bubble' : 'snow'}
          className={`rounded-lg ${inputStyles} ${
            error && 'border border-error'
          }`}
          value={value}
          ref={ref}
          formats={formats}
          modules={modules}
          onChange={handleTextChange}
          {...rest}
        />
        {error && (
          <span className='mt-1 text-xs font-semibold text-error'>{error}</span>
        )}
      </div>
    );
  },
);

export default RichTextEditor;

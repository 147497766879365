import React, { useContext, useState } from 'react';
import RichTextEditor from '../../../../../components/forms/Inputs/RichTextEditor';
import { postUserQuestionAnswer } from '../../../../../services/courses/userAnswerService';
import { UserContext } from '../../../../../utils/contexts/UserContext';

const QuestionContent = ({ data, refreshData }) => {
  const [selected, setSelected] = useState();
  const { user } = useContext(UserContext);
  return (
    <div className='flex flex-col gap-2'>
      <RichTextEditor readOnly value={data.text} />
      {data?.answers.map((answer) => {
        return (
          <div
            key={answer.uuid}
            className={`border relative p-4 rounded-lg min-h-[3.5rem] ${
              !(selected || data.user_answer) && 'cursor-pointer'
            } ${
              [selected, data.user_answer?.answer].includes(answer.uuid) &&
              'bg-blue-200 flex flex-row justify-between'
            }
             ${
               ''
               // data.correct_answer?.find((q) => q.uuid === answer.uuid)?.uuid !==
               //   data.user_answer?.answer &&
               // data.user_answer?.answer === answer.uuid &&
               // 'bg-red-200'
             }
             ${
               data.correct_answer?.find((q) => q.uuid === answer.uuid) &&
               'bg-green-200'
             }`}
            onClick={() => {
              if (selected || data.user_answer) return;
              setSelected(answer.uuid);
              postUserQuestionAnswer({
                question: data.uuid,
                answer: { answer: answer.uuid },
                user: user.uuid,
              }).then(() => refreshData(false));
            }}
          >
            {answer?.answer?.answer}
            {data.user_answer?.answer === answer.uuid && (
              <span>your answer</span>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default QuestionContent;

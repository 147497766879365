import { useState } from 'react';
import { getLocalSpeciality } from '../../../../services/universities/localUniversitiesService';
import { ArrowUturnLeftIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

export default function FacultyCard({ universityId, faculty, params }) {
  const [open, setOpen] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  return (
    <>
      <div
        className='p-2 flex flex-row lg:text-base md:text-sm text-xs'
        onClick={(e) => {
          e.preventDefault();
          setOpen((prev) => {
            if (!prev) {
              getLocalSpeciality(universityId, faculty.id, params)?.then(
                (res) => {
                  setSpecialities(res.data);
                },
              );
            }
            return !prev;
          });
        }}
      >
        <span className='flex flex-row justify-between items-center w-1/3 min-w-[10rem]'>
          <div className='flex flex-row w-full gap-2'>
            <div className='bg-blue-100 h-min px-4 rounded-2xl'>
              {faculty.count_of_specialities}
            </div>
            <span className=''>{faculty.title_ru}</span>
          </div>
          <div className='flex flex-row justify-end'>
            <ChevronDownIcon
              className={`${open ? '' : '-rotate-90'} w-5 h-5 cursor-pointer`}
            />
          </div>
        </span>
        <span className='w-1/6 min-w-[5rem] pl-8'>{faculty.code}</span>
        <span className='w-1/6 min-w-[5rem] text-center'>
          {faculty.threshold_score}
        </span>
        <span className='w-1/6 min-w-[5rem] text-center'>-</span>
        <span className='w-1/6 min-w-[5rem] text-center'>-</span>
        <span className='w-1/6 min-w-[5rem] text-center'>-</span>
      </div>
      <div className={`${open ? 'h-fit' : 'h-0 overflow-y-hidden'}`}>
        {specialities?.map((speciality) => {
          return (
            <div className='p-2 flex flex-row text-xs lg:text-base md:text-sm'>
              <span className='flex flex-row gap-2 min-w-[10rem] w-1/3'>
                <ArrowUturnLeftIcon className='rotate-180 h-5 text-secondary' />
                {speciality.title_ru}
              </span>
              <span className='w-1/6 min-w-[5rem] pl-8'>{speciality.code}</span>
              <span className='w-1/6 min-w-[5rem] text-center'>
                {speciality.threshold_score}
              </span>
              <span className='w-1/6 min-w-[5rem] text-center'>
                {speciality.grant_threshold_score}
              </span>
              <span className='w-1/6 min-w-[5rem] text-center'>
                {speciality.grant_score}
              </span>
              <span className='w-1/6 min-w-[5rem] text-center'>
                {speciality.tuition_fee ?? '-'}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
}

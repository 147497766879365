// src/Sidebar.js
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useContext } from 'react';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';

const SidebarModal = ({
  uuid,
  children,
  HeaderIcon,
  headerText,
  onClose,
  className,
}) => {
  const { id } = useContext(SidebarContext);
  return (
    <div
      className={`overflow-y-scroll fixed bottom-0 right-0 h-[93vh] max-w-[screen] z-10 w-fit bg-white transform transition-transform p-4 border ${
        uuid === id
          ? `translate-x-0 duration-300 ${id ? '' : 'delay-200'}`
          : 'translate-x-full duration-300 z-20'
      }`}
    >
      <div className='flex flex-col gap-8'>
        <div className='flex flex-wrap justify-between'>
          <div className='flex flex-row gap-2'>
            {HeaderIcon && <HeaderIcon className='h-6 text-blue-600' />}
            <h6 className='self-end text-xl font-semibold'>{headerText}</h6>
          </div>
          <XMarkIcon
            className='h-6 cursor-pointer text-secondary'
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <div className={`flex items-center ${className}`}>{children}</div>
      </div>
    </div>
  );
};

export default SidebarModal;

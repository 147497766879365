import React, { useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { deleteInstruction } from '../../../services/instructions/instructionService';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/modals/DeleteModal/DeleteModal';

const Delete = ({ uuid, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button loading={loading} color={'error'} onClick={() => setOpen(true)}>
        Delete
      </Button>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        proceed={() => {
          setLoading(true);
          deleteInstruction(uuid)
            .then((res) => {
              refreshData();
              toast.success('Instruction has been deleted');
            })
            .catch((err) => toast.error(err?.message ?? 'Something went wrong'))
            .finally(() => setLoading(false));
        }}
      />
    </div>
  );
};

export default Delete;

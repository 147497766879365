import bilAPI from '../api';

export const getQuestions = ({ uuid, test, type, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tests/questions/${uuid ? uuid + '/' : ''}`, {
          params: { test, type, page, page_size },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postQuestion = ({ text, test, type }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tests/questions/`, {
          text,
          test,
          type,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchQuestion = ({ uuid, name, test, type }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tests/questions/${uuid}/`, {
          name,
          test,
          type,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteQuestion = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/tests/questions/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postScaleContent = ({
  question,
  min,
  max,
  minValue,
  maxValue,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tests/scale_content/`, {
          question,
          min,
          max,
          minValue,
          maxValue,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchScaleContent = ({
  uuid,
  question,
  min,
  max,
  minValue,
  maxValue,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tests/scale_content/${uuid}/`, {
          question,
          min,
          max,
          minValue,
          maxValue,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getSubmissionReviews = ({
  uuid,
  evaluation,
  evaluated_by,
  project_submission,
}) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .get(`/submissions/submission_review/`, {
          params: {
            uuid,
            evaluation,
            evaluated_by,
            project_submission,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getSubmissionReview = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/submissions/submission_review/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postSubmissionReview = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/submissions/submission_review/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchSubmissionReview = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/submissions/submission_review/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import bilAPI from '../api';

export const getProjects = ({
  uuid,
  skill,
  authors,
  level,
  status,
  search,
  code,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/projects/project/`, {
          params: {
            uuid,
            skill,
            authors,
            level,
            status,
            search,
            code,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getProject = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/projects/project/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getProjectAdvice = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/projects/project/${uuid}/get_advice/`)
        .then((res) => {
          resolve(res.data.advice);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postProject = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/projects/project/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchProject = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/projects/project/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteProject = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/projects/project/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const generateProjectWithAI = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/projects/ai_advice/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const generateBackgroundImageWithAI = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/projects/ai_image/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getImagesForCreateProject = (searchText) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/core/files/`, { params: { search: searchText, page_size: 50 } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

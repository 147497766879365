import { Popover, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function IconMenuItem({ item }) {
  return (
    <>
      {!item.children ? (
        <NavLink
          to={item.href}
          onClick={() => {
            // setCurrentPage(item?.name);
          }}
          className={({ isActive }) =>
            isActive
              ? 'flex flex-col items-center py-2 text-sm font-medium  text-center border-b-2 border-purple-500'
              : 'flex flex-col items-center py-2 text-sm font-medium  text-center border-b-2 border-transparent text-gray-500  hover:text-gray-800'
          }
        >
          <item.icon className='h-6 w-6' aria-hidden='true' />

          <div className='whitespace-pre-line leading-tight'>{item.name}</div>
        </NavLink>
      ) : (
        <Popover className='z-20 '>
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`relative flex flex-col items-center focus:outline-none py-2 text-sm font-medium text-center border-b-2 ${
                  item.children
                    ?.map((e) => `/${e.href}`)
                    ?.includes(window.location.pathname)
                    ? 'border-purple-500'
                    : 'border-transparent text-gray-500 hover:text-gray-800'
                }`}
              >
                {}
                <item.icon className='h-6 w-6' aria-hidden='true' />
                <div className='relative whitespace-pre-line leading-tight '>
                  {item.name}
                </div>
                {format(new Date(), 'yyyy') < '2025' && (
                  <div className='animate-pulse z-1 absolute top-1 -right-4 rotate-45 rounded-md border  border-red-600 bg-red-600 px-1  text-xs font-bold text-white'>
                    new
                  </div>
                )}
              </Popover.Button>

              <Transition
                show={open}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
              >
                <Popover.Panel static className='absolute top-0 -left-8 mt-2'>
                  <div className='grid grid-cols-3 bg-white p-4 rounded w-72 h-fit border items-center gap-2'>
                    {item.children?.map((child, index) => (
                      <div
                        key={index}
                        className='w-22 flex flex-row justify-center overflow-hidden '
                      >
                        <div
                          className='max-w-fit overflow-hidden'
                          onClick={close}
                        >
                          <IconMenuItem item={child} />
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
    </>
  );
}

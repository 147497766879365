import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../components/forms/Inputs/Input';
import { useTranslation } from 'react-i18next';
import Select from '../../../components/forms/Select/Select';
import { Button } from '../../../components/forms/Buttons/Button';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { generateProjectWithAI } from '../../../services/projects/projectService';
import { PROJECT_METHODS } from '../../../utils/constants/constants';
import { toast } from 'react-toastify';
import { UserContext } from '../../../utils/contexts/UserContext';
import { PreferredPairContext } from '../../../utils/contexts/PreferredPairContext';
import CustomCreatableSelect from '../../../components/forms/Select/CustomCreatableSelect';

const schema = yup.object({
  title: yup.string().required(),
  // level: yup.string().required(),
  // subject: yup.string().required(),
  // method: yup.number().required(),
  // tags: yup
  //   .array()
  //   .of(yup.string())
  //   .required('Tags are required')
  //   .min(1, 'At least one tag is required')
  //   .max(5, 'Maximum of 5 tags allowed'),
  description: yup.string().required(),
  essentialToolsResources: yup.string().required(),
  roadmapWithTasks: yup.string().required(),
  // evaluationCriteria: yup.string().required(),
});

export default function ContentPart({
  content,
  setContent,
  setBody,
  levels,
  subjects,
  setIsImagePart,
}) {
  const {
    formState: { errors },
    // trigger,
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [t] = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const { user } = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const { pair } = useContext(PreferredPairContext);

  const [aiLoading, setAiLoading] = useState(false);

  const handleCheckFields = async (data) => {
    // if (Object.keys(errors).length === 0) {
    const body = {
      name: data.title,
      level: data.level?.label,
      skill: subjects?.find((s) => s?.label === data?.subject?.label)?.value,
      method: data.method?.value,
      // ai_img_url: bgImageUrl,

      content: `\nDescription:\n${data.description}\n\nEssential tools/resources:\n${data.essentialToolsResources}\n\nRoadmap with tasks:\n${data.roadmapWithTasks}`,

      tags: data.tags,
      // description: data.description,
      // essential_tools_resources: data.essentialToolsResources,
      // roadmap_with_tasks: data.roadmapWithTasks,
      // evaluation_criteria: data.evaluationCriteria,
    };
    setBody(body);
    setContent(data);
    setIsImagePart(true);
    // } else {
    //   toast.error('Eroro');
    // }
  };

  useEffect(() => {
    setValue('title', content?.title);
    setValue('level', content?.level?.value);
    setValue('subject', content?.subject?.value);
    setValue('method', content?.method);
    setValue('tags', content?.tags);
    setValue('description', content?.description);
    setValue('essentialToolsResources', content?.essentialToolsResources);
    setValue('roadmapWithTasks', content?.roadmapWithTasks);

    return () => {};
  }, [content, setValue]);

  useEffect(() => {
    if (pair?.uuid && levels?.length) {
      if (pair?.childs?.length) {
        if (pair?.childs[0] >= pair?.childs[1]) {
          setValue('subject', pair?.childs[0]?.uuid);
          setValue(
            'level',
            levels?.find(
              (s) => s?.label === pair?.childs[0]?.recommended_difficulty,
            )?.value,
          );
        } else {
          setValue('subject', pair?.childs[1]?.uuid);
          setValue(
            'level',
            levels?.find(
              (s) => s?.label === pair?.childs[1]?.recommended_difficulty,
            )?.value,
          );
        }
      } else {
        setValue('subject', pair?.uuid);
        setValue(
          'level',
          levels?.find((s) => s?.label === pair?.recommended_difficulty)?.value,
        );
      }
    }

    return () => {};
  }, [pair, levels, setValue]);

  // useEffect(() => {
  //   if (user?.roles?.some((role) => role?.name === 'STUDENT') && pair) {
  //     setValue('subject')
  //     console.log('pair', pair);
  //   }

  //   return () => {};
  // }, [user, pair]);

  return (
    <form onSubmit={handleSubmit(handleCheckFields)}>
      <div>
        <div className='grid grid-cols-12 gap-6'>
          <div className='col-span-12'>
            <Input
              type='text'
              className='pr-1'
              label={t('Title')}
              required
              errorMsg={errors?.title?.message}
              {...register('title')}
            />
          </div>
          <div className='col-span-12 md:col-span-4'>
            <Controller
              control={control}
              name='level'
              render={({ field }) => (
                <Select
                  label={t('Level')}
                  textStyles='text-sm'
                  required
                  errorMsg={errors?.level?.message}
                  isSearchable={false}
                  className='w-full overflow-visible'
                  value={field.value}
                  options={levels}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => {
                    setValue('level', e);
                    // console.log('e', e);
                    // console.log('field', field);
                    // field.onChange(e.label);
                  }}
                  // onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className='col-span-12 md:col-span-4'>
            <Controller
              control={control}
              name='subject'
              render={({ field }) => (
                <Select
                  // {...field}
                  label={t('Subject')}
                  textStyles='text-sm'
                  required
                  errorMsg={errors?.subject?.message}
                  isSearchable={false}
                  className='w-full overflow-visible'
                  value={field.value}
                  options={subjects}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => setValue('subject', e)}
                  // onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className='col-span-12 md:col-span-4'>
            <Input
              disabled
              type='text'
              className='pr-1'
              label={t('Method')}
              required
              errorMsg={errors?.method?.message}
              {...register('method')}
            />
          </div>

          <div className='col-span-12 hidden'>
            <Controller
              control={control}
              id='tags'
              name='tags'
              render={({ field }) => (
                <label
                  htmlFor='tags'
                  className='block text-sm font-medium text-gray-700'
                >
                  <CustomCreatableSelect
                    create={(label) => {
                      // console.log('label', label);
                      // postProfession({ name: label })
                    }}
                    onChange={field.onChange}
                  />
                </label>
              )}
            />
          </div>

          <div className='col-span-12'>
            <label
              htmlFor='description'
              className='block text-sm font-medium text-gray-700'
            >
              Description
              <div className='mt-1'>
                <textarea
                  id='description'
                  // name='description'
                  rows={6}
                  className='mt-1 border p-2 block w-full rounded-md border-gray-400 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-slate-200 sm:text-sm'
                  {...register('description')}
                />
              </div>
            </label>
          </div>
          <div className='col-span-12'>
            <label
              htmlFor='essentialToolsResources'
              className='block text-sm font-medium text-gray-700'
            >
              {t('Essential tools')}
              <div className='mt-1'>
                <textarea
                  id='essentialToolsResources'
                  // name='description'
                  rows={4}
                  className='mt-1 border p-2 block w-full rounded-md border-gray-400 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-slate-200 sm:text-sm'
                  {...register('essentialToolsResources')}
                />
              </div>
            </label>
          </div>

          <div className='col-span-12'>
            <label
              htmlFor='roadmapWithTasks'
              className='block text-sm font-medium text-gray-700'
            >
              Roadmap and Evaluation Criteria
              <div className='mt-1'>
                <textarea
                  id='roadmapWithTasks'
                  // name='description'
                  rows={6}
                  className='mt-1 border p-2 block w-full rounded-md border-gray-400 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-slate-200 sm:text-sm'
                  {...register('roadmapWithTasks')}
                />
              </div>
            </label>
          </div>
          {/* <div className='col-span-12'>
              <Input
                type='text'
                className='w-full'
                label={t('Evaluation criteria')}
                required
                errorMsg={errors?.evaluationCriteria?.message}
                {...register('evaluationCriteria')}
              />
            </div> */}
        </div>
      </div>
      <div className='mt-10 flex flex-row justify-end gap-4'>
        <Button
          color='primary-solid'
          text='Fill with AI'
          reverse
          Icon={SparklesIcon}
          iconColor='text-yellow-400'
          type='button'
          loading={aiLoading}
          onClick={async () => {
            // reset();
            const values = getValues();
            let body = {};
            if (values?.subject && values?.level) {
              if (values?.title) {
                body = {
                  user_input: {
                    name: values?.title,
                    level: values?.level?.label,
                    subject: values?.subject?.label,
                  },
                };
              } else {
                body = {
                  user_input: {
                    level: values?.level?.label,
                    subject: values?.subject?.label,
                  },
                };
              }

              setAiLoading(true);

              await generateProjectWithAI(body)
                .then((res) => {
                  setValue('title', res?.title);
                  setValue(
                    'level',
                    levels?.find((l) => l.label === res?.level),
                  );
                  setValue(
                    'subject',
                    subjects?.find((s) => s.label === res?.subject),
                  );
                  setValue(
                    'method',
                    PROJECT_METHODS?.find((m) => m.value === res?.method)
                      ?.label,
                  );
                  setValue('tags', res?.tags);
                  setValue('description', res?.description);
                  setValue(
                    'essentialToolsResources',
                    res?.essential_tools_resources,
                  );
                  setValue('roadmapWithTasks', res?.roadmap_with_tasks);
                })
                .finally(() => {
                  setAiLoading(false);
                });
            } else {
              toast.info('Please choose Subject and Level');
            }
          }}
          // loading={loading}
        />
        <Button
          type='submit'
          // color='primary-'
          text='Next'
          // onClick={() => {
          //   setIsImagePart(true);
          // }}
          // onClick={handleCheckFields}
        />
      </div>
    </form>
  );
}

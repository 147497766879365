import { CalendarIcon } from '@heroicons/react/24/outline';
import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({
  date,
  setDate,
  label,
  required,
  className,
  readOnly,
  showTimeSelect,
  minDate,
  dateFormat,
}) => {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <label className={'block ' + className} ref={ref}>
      <span className='flex flex-row gap-1 w-full'>
        {label}
        <span className={`text-error`}>{required && '*'}</span>
      </span>
      <div
        className={`flex flex-row gap-4 w-48 rounded-lg items-center bg-sheet-gray
      px-4 py-2 shadow-sm cursor-pointer`}
        onClick={readOnly ? () => {} : onClick}
      >
        <CalendarIcon className='h-5' />
        <p>{value}</p>
      </div>
    </label>
  ));

  return (
    <div className='w-full'>
      <ReactDatePicker
        selected={date}
        onChange={(d) => {
          setDate(d);
        }}
        dateFormat={dateFormat ? dateFormat : 'dd/MM/yyyy'}
        customInput={<CustomInput />}
        showTimeSelect={showTimeSelect}
        timeFormat='HH:mm'
        timeIntervals={15}
        minDate={minDate}
      />
    </div>
  );
};

export default DatePicker;

import React from 'react';
import { t } from 'i18next';

const Rating = ({ ratingInfo }) => {
  return (
    <div className='flex flex-col border md:p-8 p-4 gap-4 rounded-lg'>
      <div className='flex flex-row gap-8 items-end'>
        <div className='text-xl font-medium'>
          {t('universities.university_page.subject_ranking')}
        </div>
        <div className='text-gray-500 text-lg'>QS WUR by subject 2024</div>
      </div>
      <div className=''>
        <table className='table-auto w-full'>
          <thead>
            <tr className='border-b-2 sticky top-0 bg-white'>
              <th className='pb-2 px-2 text-left font-medium'>
                {t('universities.university_page.field')}
              </th>
              <th className='font-medium'>
                {t('universities.university_page.worldwide')}
              </th>
              <th className='font-medium'>
                {t('universities.university_page.by_country')}
              </th>
            </tr>
          </thead>
          <tbody>
            {ratingInfo.map((d, index) => (
              <tr key={index} className='border-b-2 last:border-b-0'>
                <td className='py-1 px-2'>
                  <div>{d?.category?.nameRU}</div> {/*translate */}
                </td>
                <td className='text-center'>{d?.rating}</td>
                <td className='text-center'>{d?.ratingCountry}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Rating;

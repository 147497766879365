import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getResultDescriptions } from '../../../services/tests/resultService';
import { Button } from '../../../components/forms/Buttons/Button';
import Add from './Add';
import { getProfessions } from '../../../services/professions/professions';
import ResultDesciptionCard from './ResultDesciptionCard';

const ResultDescription = () => {
  const { uuid } = useParams();
  const [resultDescription, setResultDescription] = useState([]);
  const [professions, setProfessions] = useState([]);
  const navigate = useNavigate();

  const getResultDescriptionData = useCallback(() => {
    getResultDescriptions({ test: uuid })?.then((res) => {
      setResultDescription(res.results);
    });
  }, [uuid]);

  const getProfessionsData = useCallback(() => {
    getProfessions({ page_size: 200 }).then((res) => {
      setProfessions(res.results);
    });
  }, []);

  useEffect(() => {
    getResultDescriptionData();
    getProfessionsData();
  }, [getResultDescriptionData, getProfessionsData]);

  return (
    <div className='flex flex-col p-6 gap-2'>
      <div className='flex flex-row justify-between'>
        <Button
          text='back'
          onClick={() =>
            navigate({
              pathname: `/tests/${uuid}`,
            })
          }
        />
        <Add test={uuid} refreshData={getResultDescriptionData} />
      </div>

      {resultDescription?.map((res) => (
        <ResultDesciptionCard
          key={res.uuid}
          description={res}
          refreshData={getResultDescriptionData}
          refreshProfessions={getProfessionsData}
          professions={professions}
        />
      ))}
    </div>
  );
};

export default ResultDescription;

import bilAPI from '../api';

export const getTests = ({ uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tests/tests/${uuid ? uuid + '/' : ''}`, {
          params: {},
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};
export const postTest = ({ name, description, type }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tests/tests/`, {
          name,
          description,
          type,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteTest = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/tests/tests/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const completeTest = ({ uuid, body }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tests/complete_test/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

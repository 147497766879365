import React, { useState } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import InputWithIcon from '../../../../../components/forms/Inputs/InputWithIcon';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import Select from '../../../../../components/forms/Select/Select';
import {
  deleteContent,
  postContent,
  postFileContent,
  postProjectContent,
  postQuestionContent,
  postTextContent,
  postVideoContent,
} from '../../../../../services/courses/contentService';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import RichTextEditor from '../../../../../components/forms/Inputs/RichTextEditor';
import Upload from '../../../../../components/upload/Upload';
import { getProjects } from '../../../../../services/projects/projectService';
import { toast } from 'react-toastify';

const Add = ({ refreshData, lesson }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('text');
  const [projectCode, setProjectCode] = useState('');
  const [project, setProject] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
    setType('text');
    setProjectCode('');
    setProject();
  };

  const post = (body) => {
    switch (type) {
      case 'text':
        return postTextContent(body);
      case 'video':
        return postVideoContent(body);
      case 'project':
        return postProjectContent(body);
      case 'question':
        return postQuestionContent(body);
      case 'file':
        return postFileContent(body);
      default:
        break;
    }
  };

  const onSubmit = (data) => {
    postContent({ lesson }).then((res) => {
      let body = { ...data };
      body.content = res.uuid;
      if (type === 'file') {
        body = new FormData();
        Object.keys(data).forEach((k) => {
          body.append(k, data[k]);
        });
        body.append('content', res.uuid);
      }

      post(body)
        ?.then(() => {
          refreshData();
          onClose();
        })
        .catch(() => {
          deleteContent(res.uuid);
        });
    });
  };

  return (
    <>
      <Button Icon={PlusIcon} onClick={() => setOpen(true)} />
      <CustomDialog isOpen={open} closeModal={() => onClose()}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-4 pt-4'
        >
          <div className='flex flex-col text-center w-full gap-2'>
            <Select
              className='text-sm'
              options={[
                { label: 'Text', value: 'text' },
                { label: 'Video', value: 'video' },
                { label: 'Project', value: 'project' },
                { label: 'Question', value: 'question' },
                { label: 'File', value: 'file' },
              ]}
              value={type}
              onChange={(v) => {
                setType(v.value);
              }}
            />

            {['question', 'text'].includes(type) && (
              <Controller
                control={control}
                name='text'
                render={({ field }) => (
                  <>
                    <RichTextEditor
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                    <span>
                      To write the formula please use{' '}
                      <a
                        className='text-primary hover:underline'
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.overleaf.com/learn/latex/Integrals%2C_sums_and_limits'
                      >
                        LaTeX
                      </a>
                    </span>
                  </>
                )}
              />
            )}
            {type === 'question' &&
              'You can add options after creating the question'}
            {type === 'video' && (
              <Input
                errorMsg={errors.name}
                required
                label='URL'
                {...register('url', {
                  required: 'URL is required field',
                })}
              />
            )}
            {type === 'file' && (
              <Controller
                control={control}
                name='file'
                render={({ field }) => (
                  <>
                    <Upload
                      file={field.value}
                      setFile={(e) => {
                        console.log(e);
                        field.onChange(e);
                      }}
                    />
                  </>
                )}
              />
            )}
            {type === 'project' && (
              <Controller
                control={control}
                name='project'
                render={({ field }) => (
                  <>
                    <InputWithIcon
                      label='Project code'
                      value={projectCode}
                      Icon={MagnifyingGlassIcon}
                      onChange={(e) => setProjectCode(e.target.value)}
                      onIconClick={() =>
                        getProjects({ code: projectCode }).then((res) => {
                          if (!res.count) {
                            toast(
                              `There is no Project with code "${projectCode}"`,
                            );
                            return;
                          }
                          field.onChange(res.results[0].uuid);
                          setProject(res.results[0]);
                        })
                      }
                    />
                    {project?.name}
                  </>
                )}
              />
            )}
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              onClick={() => onClose()}
              type='button'
            />
            <Button color='success' text={t('buttons.create')} type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Add;

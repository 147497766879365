import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function EmptyModal({
  children,
  open,
  setOpen,
  headerText,
  HeaderIcon,
  onClose,
}) {
  return (
    <div>
      <div
        className={`${
          open ? '' : 'hidden'
        } fixed right-0 left-0 z-10 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-60 sm:h-full md:inset-0`}
      >
        <div className='h-full px-4 md:h-auto'>
          <div className='flex flex-col gap-8 rounded-lg bg-white p-6 drop-shadow-lg'>
            <div className='flex flex-wrap justify-between'>
              <div className='flex flex-row gap-2'>
                {HeaderIcon && <HeaderIcon className='h-6 text-blue-600' />}
                <h6 className='self-end text-xl font-semibold'>{headerText}</h6>
              </div>
              <XMarkIcon
                className='h-6 cursor-pointer text-secondary'
                onClick={() => {
                  if (onClose) onClose();
                  setOpen(false);
                }}
              />
            </div>
            <div className='flex items-center'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

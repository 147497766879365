import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import AdminTests from './AdminTests/AdminTests';
import StudentTests from './StudentTests/StudentTests';
import { getTests } from '../../../services/tests/testService';

const Tests = () => {
  const { user } = useContext(UserContext);

  const [tests, setTests] = useState([]);

  const getTestsData = useCallback(() => {
    getTests({}).then((res) => {
      setTests(res.results);
    });
  }, []);

  useEffect(() => {
    getTestsData();
  }, [getTestsData]);

  if (user?.roles?.some((r) => r?.name === 'ADMIN')) {
    return <AdminTests data={tests} refreshData={getTestsData} />;
  } else {
    return <StudentTests data={tests} refreshData={getTestsData} />;
  }
};

export default Tests;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import { useParams } from 'react-router-dom';
import {
  getResultDescriptions,
  getResults,
} from '../../../services/tests/resultService';
import MBTI from './Results/MBTI';
import MIT from './Results/MIT';
import Description from './Description';

const Result = ({ test, userUUID, toScroll }) => {
  const { user } = useContext(UserContext);
  const { uuid } = useParams();

  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [resultDescription, setResultDescription] = useState();

  const COLORS = [
    'bg-yellow-500',
    'bg-red-500',
    'bg-emerald-500',
    'bg-purple-500',
    'bg-blue-500',
    'bg-green-500',
  ];

  const getResultDescription = useCallback((result) => {
    getResultDescriptions({
      result,
    })?.then((res_desc) => {
      if (res_desc.count) {
        setResultDescription(res_desc.results[0]);
      }
    });
  }, []);

  const getResult = useCallback(() => {
    setIsLoading(true);
    getResults({ test: test ?? uuid, user: userUUID ?? user.uuid })
      ?.then((res) => {
        if (res.count) {
          setResult(res.results[0]);
          getResultDescription(res.results[0]?.result?.result);
        } else {
          setResult();
          setResultDescription();
        }
      })
      .finally(() => setIsLoading(false));
  }, [getResultDescription, test, user.uuid, userUUID, uuid]);

  useEffect(() => {
    if (test ?? uuid) {
      getResult();
    }
  }, [getResult, test, uuid]);

  return (
    <div className='flex flex-col gap-2'>
      {!result && !isLoading && <Description test={test} />}
      {result?.test?.type === 0 ? (
        <MBTI result={result} description={resultDescription} colors={COLORS} />
      ) : (
        result?.test?.type === 1 && (
          <MIT
            result={result}
            description={resultDescription}
            colors={COLORS}
            setDescription={setResultDescription}
            toScroll={toScroll}
          />
        )
      )}
    </div>
  );
};

export default Result;

import React from 'react';
import CreateTestButton from './CreateTestButton';
import { PlayIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const AdminTests = ({ data, refreshData }) => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col p-6'>
      <div className='flex flex-row justify-end'>
        <CreateTestButton refreshData={refreshData} />
      </div>
      <div className='flex flex-row gap-2'>
        {data?.map((test, i) => {
          return (
            <div
              key={test.uuid}
              className='flex flex-col border rounded-lg mt-2 max-w-fit min-w-[12rem]'
            >
              <div className='relative p-4 pb-2 bg-slate-100'>
                {`${i + 1}. ${test.name}`}
                <PlayIcon
                  onClick={() =>
                    navigate({
                      pathname: `/tests/${test.uuid}`,
                    })
                  }
                  className='w-8 h-8 p-1 text-red-400 border rounded-full absolute right-2 -bottom-4 bg-white hover:bg-slate-200 cursor-pointer'
                />
              </div>
              <div className='border-t p-4 pt-2'></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminTests;

import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import {
  MagnifyingGlassIcon,
  PhotoIcon,
  SparklesIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { Button } from '../../../components/forms/Buttons/Button';
import {
  generateBackgroundImageWithAI,
  getImagesForCreateProject,
} from '../../../services/projects/projectService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/forms/Inputs/Input';

export default function ImagePart({
  loading,
  handleCreateProject,
  setIsImagePart,
  content,
  bgImageUrl,
  setBgImageUrl,
}) {
  const { user } = useContext(UserContext);

  const [aiLoading, setAiLoading] = useState(false);

  return (
    <div>
      {user?.roles?.some((role) => role?.name === 'STUDENT') ? (
        <StudentImageSide setBgImageUrl={setBgImageUrl} />
      ) : (
        <TeacherImageSide bgImageUrl={bgImageUrl} />
      )}
      <div className='flex flex-row justify-between mt-4'>
        <div>
          <Button
            type='button'
            // color='primary-'
            text='Back'
            onClick={() => {
              setIsImagePart(false);
            }}
          />
        </div>
        <div className='flex flex-row gap-2'>
          {!user?.roles?.some((role) => role?.name === 'STUDENT') && (
            <Button
              color='primary-solid'
              text='Generate with AI'
              reverse
              Icon={SparklesIcon}
              iconColor='text-yellow-400'
              type='button'
              loading={aiLoading}
              onClick={async () => {
                // reset();
                if (content?.subject?.label && content?.level?.label) {
                  const body = {
                    project_info: {
                      title: content?.title,
                      subject: content?.subject?.label,
                    },
                  };

                  setAiLoading(true);

                  await generateBackgroundImageWithAI(body)
                    .then((res) => {
                      setBgImageUrl(res?.image_url);
                    })
                    .finally(() => {
                      setAiLoading(false);
                    });
                } else {
                  toast.info('Please choose Subject and Level');
                }
              }}
            />
          )}
          <Button
            type='button'
            color='success'
            text='Create'
            loading={loading}
            onClick={() => {
              handleCreateProject();
            }}
          />
        </div>
      </div>
    </div>
  );
}

function TeacherImageSide({ bgImageUrl }) {
  return (
    <div>
      <div className='text-lg font-semibold'>Background Image</div>
      <div className='my-2'>
        <span className='text-red-600'>*</span>
        Generating a picture can be done only once and takes{' '}
        <span className='font-semibold'>30-40 seconds</span>.
      </div>
      <div className='mb-2 box-border flex h-72 w-full flex-col items-center justify-center rounded-md border bg-white align-middle'>
        {bgImageUrl ? (
          <img src={bgImageUrl} alt='bgImage' className='h-64' />
        ) : (
          <PhotoIcon className='h-24 w-24' />
        )}
      </div>
    </div>
  );
}

function StudentImageSide({ setBgImageUrl }) {
  const [t] = useTranslation();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setLoading(true);
    getImagesForCreateProject()
      .then((res) => {
        // console.log('res', res);
        setImages(res.results);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, []);

  return (
    <div>
      <h3 className='text-lg my-2'>
        {t('Please select appropriate background picture')}
      </h3>
      <div className='flex flex-row gap-2 w-full items-end mb-2'>
        <Input
          type='text'
          className='pr-1'
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />

        <Button
          Icon={MagnifyingGlassIcon}
          type='button'
          onClick={() => {
            if (searchText) {
              setLoading(true);
              getImagesForCreateProject(searchText)
                .then((res) => {
                  setImages(res.results);
                  setSelectedImage(null);
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              toast.info('Add text');
            }
          }}
        />
        <Button
          Icon={XMarkIcon}
          type='button'
          onClick={() => {
            setSearchText('');
            setLoading(true);
            getImagesForCreateProject()
              .then((res) => {
                setImages(res.results);
                setSelectedImage(null);
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        />
      </div>

      <div className='border rounded-md p-2 max-h-[32rem] overflow-x-scroll'>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 '>
          {!loading ? (
            <React.Fragment>
              {images?.map((image) => (
                <div
                  key={image?.uuid}
                  className='hover:transform hover:scale-90 cursor-pointer transition-transform duration-300 ease-in-out'
                  onClick={() => {
                    setSelectedImage(image?.uuid);
                    setBgImageUrl(image?.upload);
                  }}
                >
                  <img
                    src={image?.upload_compr}
                    alt='img'
                    className={`border-4 ${
                      selectedImage === image?.uuid
                        ? 'border-purple-600'
                        : 'border-gray-200'
                    } rounded-md`}
                  />
                </div>
              ))}
              {/* <div
								key={image?.uuid}
								className='hover:transform hover:scale-90 cursor-pointer transition-transform duration-300 ease-in-out'
								onClick={() => {
									setSelectedImage(image?.uuid);
									setBgImageUrl(image?.upload);
								}}
								>

								</div> */}
            </React.Fragment>
          ) : (
            Array.from({ length: 12 }).map((_, i) => (
              <div
                key={i}
                className='border-2 animate-pulse w-56 h-32 rounded-md bg-slate-200'
              ></div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

import { EyeIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { Button } from '../../../../../components/forms/Buttons/Button';
import TextArea from '../../../../../components/forms/Inputs/TextArea';
import Input from '../../../../../components/forms/Inputs/Input';

const ViewTask = ({ task }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        text='View'
        color='primary'
        className='text-primary max-h-6 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg'
        onClick={() => setOpen(true)}
      />
      {/* <EyeIcon
        className='text-primary h-9 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-2'
        onClick={() => setOpen(true)}
      /> */}
      <CustomDialog isOpen={open} closeModal={() => onClose()}>
        <div className='flex flex-col gap-4 pt-4'>
          <div className='w-full'>
            <div className='flex flex-row w-full gap-2'>
              <Input
                readOnly
                label='title'
                inputClassName='focus:outline-primary'
                className='w-full'
                value={task?.name}
              />
              <Input
                readOnly
                label='deadline'
                className='w-52'
                inputClassName='focus:outline-primary'
                value={task?.deadline}
              />
            </div>

            <TextArea
              readOnly
              rows={7}
              label='description'
              value={task?.description}
            />
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <Button
              color='primary'
              text={t('Ok')}
              onClick={() => setOpen(false)}
              type='button'
            />
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default ViewTask;

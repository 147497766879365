import React, { useCallback, useEffect, useState } from 'react';
import PlacedholderCard from '../../../../components/cards/PlaceholderCard';
import CourseCard from '../../../Courses/Courses/Student/CourseCard';
import { getStartedCourses } from '../../../../services/courses/courseService';
import NoContentCard from '../../../../components/cards/NoContentCard';

const Courses = ({ uuid }) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(() => {
    setLoading(true);
    getStartedCourses(uuid)
      .then((res) => {
        setCourses(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [uuid]);

  useEffect(() => {
    getData();

    return () => {};
  }, [getData]);

  return (
    <div className='flex flex-col w-full p-4 pt-0'>
      <div className='flex flex-row flex-wrap gap-4'>
        {loading ? (
          <PlacedholderCard />
        ) : courses.length ? (
          courses.map((d) => {
            return <CourseCard key={d.uuid} data={d} refreshData={getData} />;
          })
        ) : (
          <div className='w-full flex flex-row justify-center py-6 text-gray-400 text-lg'>
            No content
          </div>
        )}
      </div>
    </div>
  );
};

export default Courses;

import React, { useEffect, useState } from 'react';
import {
  getAccounts,
  getProjectAssignments,
} from '../../services/submissions/projectAssignmentService';
import { Tab } from '@headlessui/react';
import SelectReviewerTable from './SelectReviewerTable';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import Select from '../../components/forms/Select/Select';
import { useTranslation } from 'react-i18next';
import { getOrganizations } from '../../services/catalogs/organizationService';
import Description from './Description';

const PAGE_SIZE = 10;

export default function Assignments() {
  const [t] = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0);

  const [withoutMentorProjects, setWithoutMentorProjects] = useState([]);
  const [withoutMentorLoading, setWithoutMentorLoading] = useState(false);
  const [withoutMentorPage, setWithoutMentorPage] = useState(1);
  const [withoutMentorCount, setWithoutMentorCount] = useState(0);

  const [withMentorProjects, setWithMentorProjects] = useState([]);
  const [withMentorLoading, setWithMentorLoading] = useState(false);
  const [withMentorPage, setWithMentorPage] = useState(1);
  const [withMentorCount, setWithMentorCount] = useState(0);

  const [mentors, setMentors] = useState([]);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);

  useEffect(() => {
    console.log('withMentorCount', withMentorCount);
  }, [withMentorCount]);

  useEffect(() => {
    console.log('withoutMentorCount', withoutMentorCount);
  }, [withoutMentorCount]);

  const onWithoutMentorPageChange = async (pg) => {
    setWithoutMentorLoading(true);

    getProjectAssignments({
      uuid: null,
      submitted: true,
      organization: selectedSchool,
      has_mentor: false,
      page: pg,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setWithoutMentorProjects(res?.results);
        setWithoutMentorCount(res?.count);
      })
      .finally(() => {
        setWithoutMentorLoading(false);
      });

    setWithoutMentorPage(pg);
  };

  const onWithMentorPageChange = async (pg) => {
    setWithMentorLoading(true);

    getProjectAssignments({
      uuid: null,
      submitted: true,
      organization: selectedSchool,
      has_mentor: true,
      page: pg,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setWithMentorProjects(res?.results);
        setWithMentorCount(res?.count);
      })
      .finally(() => {
        setWithMentorLoading(false);
      });

    setWithMentorPage(pg);
  };

  const updateTables = (school) => {
    setWithoutMentorPage(1);
    setWithMentorPage(1);
    setWithoutMentorLoading(true);
    setWithMentorLoading(true);

    getProjectAssignments({
      uuid: null,
      submitted: true,
      organization: school,
      has_mentor: false,
      page: 1,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setWithoutMentorProjects(res?.results);
        setWithoutMentorCount(res?.count);
      })
      .finally(() => {
        setWithoutMentorLoading(false);
      });

    getProjectAssignments({
      uuid: null,
      submitted: true,
      organization: school,
      has_mentor: true,
      page: 1,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setWithMentorProjects(res?.results);
        setWithMentorCount(res?.count);
      })
      .finally(() => {
        setWithMentorLoading(false);
      });
  };

  useEffect(() => {
    updateTables(null);

    getAccounts({ role: 'PROJECTREVIEWER' }).then((res) => {
      setMentors(
        res.results?.map((m) => ({
          label: m?.first_name + ' ' + m?.last_name,
          value: m.uuid,
        })),
      );
    });

    getOrganizations().then((res) => {
      setSchools(res.results);
    });

    return () => {};
  }, []);

  return (
    <div className='w-full flex flex-col gap-4 rounded-md px-4 py-4'>
      <Description />
      <div className='flex flex-col gap-4'>
        {schools.length > 1 && (
          <Select
            options={[
              { label: t('stats.all'), value: null },
              ...schools.map((d) => ({ label: d?.name, value: d?.uuid })),
            ]}
            onChange={(e) => {
              updateTables(e.value);
              setSelectedSchool(e?.value);
            }}
            // loading={loading}
            value={selectedSchool}
            className='w-full lg:w-96'
          />
        )}
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className='flex flex-row gap-5 border-b border-gray-200'>
            <Tab
              key={'New'}
              className={({ selected }) =>
                selected
                  ? 'py-2 text-sm font-medium px-4 border-b-2 border-blue-500'
                  : 'py-2 text-sm font-medium px-4 text-gray-500 hover:border-b-2 hover:border-gray-300 hover:text-gray-800'
              }
            >
              New
            </Tab>
            <Tab
              key={'Archive'}
              className={({ selected }) =>
                selected
                  ? 'py-2 text-sm font-medium px-4 border-b-2 border-blue-500'
                  : 'py-2 text-sm font-medium px-4 text-gray-500 hover:border-b-2 hover:border-gray-300 hover:text-gray-800'
              }
            >
              Archive
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              {withoutMentorLoading ? (
                <SimpleLoader className='h-12' />
              ) : (
                <SelectReviewerTable
                  tab={0}
                  data={withoutMentorProjects}
                  updateTable={updateTables}
                  mentors={mentors}
                  page={withoutMentorPage}
                  onPageChange={onWithoutMentorPageChange}
                  totalPages={withoutMentorCount / PAGE_SIZE}
                  count={withoutMentorCount}
                />
              )}
            </Tab.Panel>
            <Tab.Panel>
              {withMentorLoading ? (
                <SimpleLoader className='h-12' />
              ) : (
                <SelectReviewerTable
                  tab={1}
                  data={withMentorProjects}
                  updateTable={updateTables}
                  mentors={mentors}
                  page={withMentorPage}
                  onPageChange={onWithMentorPageChange}
                  totalPages={withMentorCount / PAGE_SIZE}
                  count={withMentorCount}
                />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

import bilAPI from '../api';

export const getProjectSubmissions = ({
  uuid,
  is_checking,
  project_assignment,
  evaluated,
  student,
  mentor,
  organization,
  page,
  //   page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/submissions/project_submission/`, {
          params: {
            uuid,
            is_checking,
            project_assignment,
            evaluated,
            student,
            mentor,
            organization,
            page_size: 20,
            page,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postProjectSubmission = ({
  is_checking,
  project_assignment,
  comment,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/submissions/project_submission/`, {
          is_checking,
          project_assignment,
          comment,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getProjectSubmission = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/submissions/project_submission/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchProjectSubmission = (
  uuid,
  { is_checking, project_assignment, comment },
) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/submissions/project_submission/${uuid}/`, {
          is_checking,
          project_assignment,
          comment,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteProjectSubmission = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/submissions/project_submission/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

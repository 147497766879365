import bilAPI from '../api';

export const getProjectAssignments = ({
  uuid,
  is_in_progress,
  student,
  mentor,
  project,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/submissions/project_assignment/`, {
          params: {
            uuid,
            is_in_progress,
            student,
            mentor,
            project,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getProjectAssignment = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/submissions/project_assignment/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postProjectAssignment = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/submissions/project_assignment/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchProjectAssignment = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/submissions/project_assignment/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

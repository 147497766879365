import React, { useEffect, useState } from 'react';
import Input from '../../../components/forms/Inputs/Input';
import { Controller, useForm } from 'react-hook-form';
import TextArea from '../../../components/forms/Inputs/TextArea';
import Select from '../../../components/forms/Select/Select';
import { Button } from '../../../components/forms/Buttons/Button';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import {
  createVideoConference,
  startVideoConference,
} from '../../../services/videoConference/videoConferenceService';
import { toast } from 'react-toastify';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

export default function CreateVideoConference() {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [conferenceDate, setConferenceDate] = useState(null);
  const [createdConference, setCreatedConference] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    setCurrentPath(window.location.href);

    return () => {};
  }, []);

  const onSubmit = (data) => {
    const body = {
      ...data,
      start_time: conferenceDate,
      logout_url: currentPath.substring(0, currentPath.length - 7),
    };
    if (conferenceDate) {
      setLoading(true);
      createVideoConference(body)
        .then((res) => {
          // console.log('res', res);
          setCreatedConference(res);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error('Please select date and time');
    }
  };

  return (
    <div className='flex flex-col gap-4 md:m-0 m-2'>
      <div>
        <h1 className='text-xl font-semibold'>Create conference</h1>
      </div>
      {false && (
        <div className='flex flex-col gap-2'>
          <p className='font-semibold'>Instructions:</p>
          <p>To create...</p>
        </div>
      )}
      <div className='flex flex-col md:flex-row gap-8 w-full'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-4 border-2 rounded-md max-w-lg p-4'
        >
          <Input
            errorMsg={errors?.name?.message}
            required
            className='md:w-96'
            label='Name of your video conference'
            {...register('name', {
              required: 'Title is a required field',
            })}
          />
          <TextArea
            className='text-sm font-medium text-gray-700'
            errorMsg={errors?.welcome_text?.message}
            rows={7}
            label='Welcome message for chat'
            {...register('welcome_text', {
              required: 'Welcome text is a required field',
            })}
          />
          <TextArea
            className='text-sm font-medium text-gray-700'
            errorMsg={errors?.banner_text?.message}
            rows={7}
            label='Banner message'
            {...register('banner_text', {
              required: 'Banner text is a required field',
            })}
          />
          <Controller
            control={control}
            name='guest_policy'
            render={({ field }) => (
              <Select
                label='Guest policy'
                value={field.value}
                onChange={(v) => {
                  field.onChange(v?.value);
                }}
                options={[
                  { label: 'Accept guests', value: 'ALWAYS_ACCEPT' },
                  { label: 'Deny guests', value: 'ALWAYS_DENY' },
                  { label: 'Ask moderator', value: 'ASK_MODERATOR' },
                ]}
                className='text-sm font-medium text-gray-700'
              />
            )}
          />
          <DatePicker
            date={conferenceDate}
            setDate={setConferenceDate}
            label={'Planned Date and Time'}
            dateFormat={'dd/MM/yyyy  HH:mm'}
            showTimeSelect
            className={'text-sm font-medium text-gray-700'}
            minDate={new Date()}
          />

          <div className='flex flex-row justify-end'>
            <Button
              loading={loading}
              type='submit'
              text='Create conference'
              color='success'
            />
          </div>
        </form>
        {createdConference?.uuid && (
          <div className='flex flex-col gap-2 border-2 rounded-md p-4 max-h-48'>
            <div className='felx flex-row gap-2'>
              <p className=' text-lg'>Get link</p>
            </div>
            <div className='flex flex-row gap-2'>
              <input
                type='text'
                disabled
                value={`${currentPath.substring(
                  0,
                  currentPath.length - 24,
                )}/join-video-conference/${createdConference?.uuid}`}
                className='block bg-green-100 text-green-600 font-semibold w-full min-w-[20rem] rounded-md border-green-400 px-3 py-2  border-2 shadow-sm sm:text-sm'
              />
              <Button
                Icon={ClipboardDocumentListIcon}
                className='min-w-[4rem] hidden md:block'
                color='primary'
                type='button'
                onClick={() => {
                  var copyText = `${currentPath.substring(
                    0,
                    currentPath.length - 24,
                  )}/join-video-conference/${createdConference?.uuid}`;
                  // var copyText1 = `https://8q.org.kz/join-video-conference/${createdConference?.uuid}`;

                  navigator.clipboard.writeText(copyText);

                  toast.success('Share link successfully copied!');
                }}
              />
            </div>
            <div>
              <p className='italic text-sm'>
                Please share this link with your audeince
              </p>
            </div>
            <div className='border-t pt-2 flex flex-row gap-2'>
              <Button
                text='Start meeting'
                color='primary'
                type='button'
                onClick={() => {
                  if (createdConference?.uuid) {
                    startVideoConference(createdConference.uuid)
                      .then((res) => {
                        window.open(res.moder_link, '_blank').focus();
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }
                }}
              />
              <Button
                text='Copy link'
                className='min-w-[4rem] md:hidden'
                color='primary'
                type='button'
                onClick={() => {
                  var copyText = `${currentPath.substring(
                    0,
                    currentPath.length - 24,
                  )}/join-video-conference/${createdConference?.uuid}`;
                  // var copyText1 = `https://8q.org.kz/join-video-conference/${createdConference?.uuid}`;

                  navigator.clipboard.writeText(copyText);

                  toast.success('Share link successfully copied!');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

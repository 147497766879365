import { useContext, useEffect } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import OrgRoadmapStats from './OrgRoadmapStats/OrgRoadmapStats';
import { useState } from 'react';
import GroupRoadmapStats from './GroupRoadmapStats/GroupRoadmapStats';
import StudentRoadmaps from './StudentRoadmaps/StudentRoadmaps';
import { getOrgRoadmapStats } from '../../../services/roadmapTask/roadmapStatsService';
import { useLocation } from 'react-router-dom';

const Roadmaps = () => {
  const { state } = useLocation();
  const { user } = useContext(UserContext);
  const [lvl, setLvl] = useState(0);
  const [org, setOrg] = useState();
  const [orgs, setOrgs] = useState([]);

  const [group, setGroup] = useState();

  useEffect(() => {
    getOrgRoadmapStats({ page_size: 100 })?.then((res) => {
      setOrgs(res?.results);
      if (state?.organization) {
        setOrg(state?.organization);
        setGroup(state?.group);
        setLvl(state.group ? 1 : 2);
      } else if (res.count === 1) {
        setOrg({ name: res.results[0]?.name, uuid: res.results[0]?.uuid });
        setLvl(2);
      } else {
        setLvl(3);
      }
    });
  }, [setOrgs, state, user.organization]);

  return (
    <>
      {lvl === 3 ? (
        <OrgRoadmapStats
          orgs={orgs}
          setOrgs={setOrgs}
          onSelect={(e) => {
            setLvl((prev) => prev - 1);
            setOrg(e);
          }}
        />
      ) : lvl === 2 ? (
        <GroupRoadmapStats
          goBack={() => {
            setLvl((prev) => prev + 1);
            setOrg();
          }}
          org={org}
          onSelect={(e) => {
            setLvl((prev) => prev - 1);
            setGroup(e);
          }}
        />
      ) : (
        lvl === 1 && (
          <StudentRoadmaps
            group={group}
            org={org}
            goBack={() => {
              setLvl((prev) => prev + 1);
              setGroup();
            }}
          />
        )
      )}
    </>
  );
};

export default Roadmaps;

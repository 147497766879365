import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import DatePicker from '../../../../../components/forms/DatePicker/DatePicker';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { postRoadmapUpdate } from '../../../../../services/roadmapTask/roadmapCreateService';
import { Controller, useForm } from 'react-hook-form';

const RepeatTask = ({ task, body, setData }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data) => {
    // if (period <= 0) {
    //   toast.error("The period can't be less than 1 day");
    //   return;
    // }

    body.repeat = {
      ...body?.repeat,
      ...data,
      end_date: format(data.end_date, 'yyyy-MM-dd'),
    };
    postRoadmapUpdate({ ...body })?.then((res) => {
      setData(res);

      onClose();
    });
  };

  return (
    <>
      <Button
        text='Repeat'
        color='success'
        className='text-primary max-h-6 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg'
        onClick={() => setOpen(true)}
      />

      <CustomDialog isOpen={open} closeModal={() => onClose()}>
        <form
          className='flex flex-col gap-4 pt-4'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='flex flex-row gap-2 max-w-fit'>
            <div className=''>
              {t('roadmap.start_date')} <span className='text-error'>*</span>
              <div className='flex flex-row gap-4 p-2 px-4 bg-sheet-gray rounded-lg w-48'>
                <CalendarIcon className='h-5' />
                {task?.start_date &&
                  format(new Date(task.start_date), 'dd/MM/yyyy')}
              </div>
            </div>
            <Controller
              control={control}
              name='end_date'
              rules={{
                required: 'End date is required field',
              }}
              defaultValue={null}
              render={({ field }) => (
                <div>
                  <DatePicker
                    date={field.value}
                    setDate={(d) => {
                      if (new Date(task.start_date) > d && task.start_date) {
                        toast.error(t('roadmap.reversed_date_error'));
                        return;
                      }
                      field.onChange(d);
                    }}
                    label={t('roadmap.end_date')}
                    required
                  />
                  {errors?.end_date?.message && (
                    <span className='mt-1 text-xs font-semibold text-error'>
                      {errors?.end_date?.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <Input
            className='w-32'
            type='Number'
            label={t('Duration')}
            min='1'
            errorMsg={errors?.period?.message}
            {...register('period', {
              required: 'Period is required field',
              value: 1,
              min: 1,
            })}
          />
          <Controller
            control={control}
            name='frequency'
            rules={{
              required: 'End date is required field',
            }}
            defaultValue={1}
            render={({ field }) => (
              <>
                <div className='flex flex-row gap-2'>
                  {['daily', 'weekly', 'monthly']?.map((r, i) => (
                    <Button
                      type='button'
                      key={i}
                      text={r}
                      color={i + 1 === field.value && 'primary'}
                      onClick={() => field.onChange(i + 1)}
                    />
                  ))}
                </div>
                {errors?.frequency?.message && (
                  <span className='mt-1 text-xs font-semibold text-error'>
                    {errors?.frequency?.message}
                  </span>
                )}
              </>
            )}
          />

          <div className='flex flex-row justify-between gap-2'>
            <Button
              color=''
              text={t('Cancel')}
              onClick={() => onClose()}
              type='button'
            />
            <Button
              color='primary'
              text={t('Submit')}
              onClick={() => {}}
              type='submit'
            />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default RepeatTask;

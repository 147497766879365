import React, { Fragment, useState } from 'react';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { exportResult } from '../../../../services/tests/resultService';
import jsPDF from 'jspdf';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../../../utils/helpers/helpers';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
// import './Roboto-MediumItalic-normal';
// import './Roboto-Regular-normal';
// import './export.css';
import FlagRU from '../../../../components/Icons/FlagRU';
import FlagEN from '../../../../components/Icons/FlagEN';
import html2canvas from 'html2canvas';
import { useMediaQuery } from 'react-responsive';

const Export = ({ result, file, fileName, text, params = {}, screen_ref }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [loadingFile, setLoadingFile] = useState(false);

  const handleImg = async () => {};
  const handleGeneratePdf = ({ lang }) => {
    const letterWidth = 8.5 * 72;
    const letterHeight = 11 * 72;

    const doc = new jsPDF({
      format: [letterWidth, letterHeight],
      unit: 'pt',
    });

    let div;
    const cop = screen_ref.current;

    // if cyrylic: screenshot
    // if (localStorage.getItem('i18nextLng') === 'ru') {
    return html2canvas(cop, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
    })?.then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // const imgWidth = 210;
      // const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // doc.addImage(
      //   imgData,
      //   'PNG',
      //   0,
      //   0,
      //   canvas.width,
      //   canvas.height,
      //   undefined,
      //   'FAST',
      // );
      const imgProperties = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();

      const pageHeight = pdfWidth * (letterHeight / letterWidth);
      const imgHeight = pdfWidth * (imgProperties.height / imgProperties.width);
      // (imgProperties.height * imgWidth) / imgProperties.width;
      let heightLeft = imgHeight;

      let position = 10;
      doc.addImage(
        imgData,
        'PNG',
        0,
        position,
        pdfWidth,
        imgHeight,
        undefined,
        'FAST',
      );

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          'PNG',
          0,
          position,
          pdfWidth,
          imgHeight,
          undefined,
          'FAST',
        );
        heightLeft -= pageHeight;
      }

      const pdfBlob = doc.output('blob');
      handle({ reportScreen: pdfBlob, lang: lang })
        .then((res) => {})
        .catch((err) => {
          setLoadingFile(false);
        });
      // const url = window.URL.createObjectURL(pdfBlob);
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', `${fileName}.pdf`);
      // document.body.appendChild(link);
      // link.click();
      // link.parentNode.removeChild(link);
      // window.URL.revokeObjectURL(url);
      setLoadingFile(false);
    });

    // toast.error(
    //   'Персоналые отчеты работают только на английском языке, пожалуйста поменяйте язык сайта!',
    // );
    // setLoadingFile(false);
    // return new Promise((resolve, reject) => {});
    // div = document.createElement('div');
    // div.style.fontFamily = 'Roboto-MediumItalic';
    // div.style.letterSpacing = '0.125rem';

    // const cop = screen_ref.current;
    // div.innerHTML = cop.outerHTML;
    // document.body.appendChild(div);
    // }

    // const contentWidth = (div || cop).offsetWidth;
    // const scaleX = letterWidth / contentWidth;

    // return new Promise((resolve, reject) => {
    //   doc.html(div || cop, {
    //     html2canvas: {
    //       useCORS: true,
    //       allowTaint: true,
    //       scale: scaleX,
    //     },
    //     callback: (doc) => {
    //       if (div) {
    //         document.body.removeChild(div);
    //       }

    //       let blob = doc.output('blob');

    //       handle({ reportScreen: blob, lang: lang })
    //         .then((res) => {
    //           resolve(res);
    //         })
    //         .catch((err) => {
    //           reject(err);
    //           setLoadingFile(false);
    //         });
    //     },
    //     margin: [20, 0, 20, 0],
    //     autoPaging: 'text',
    //   });
    // });
  };

  const handle = ({ reportScreen, lang }) => {
    const body = new FormData();
    if (reportScreen) {
      body.append('reportScreen', reportScreen);
    }
    Object.keys(params).forEach((k) => {
      body.append(k, params[k]);
    });

    return new Promise((resolve, reject) => {
      exportResult({ uuid: result, body, lang })
        .then((res) => {
          const blobFile = new Blob([res], {
            type: 'text/plain',
          });
          const url = window.URL.createObjectURL(blobFile);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <>
      <Menu as='div' className='relative'>
        {file.add_screen && isMobile && (
          <span>Can't be download in mobile</span>
        )}
        <Menu.Button
          disabled={loadingFile || (file.add_screen && isMobile)}
          className={`p-2 px-4 w-56 justify-center flex flex-row items-center bg-blue-600 bg-opacity-20 text-blue-600 hover:bg-opacity-100 hover:text-white rounded-md 
          disabled:border disabled:bg-transparent disabled:border-secondary`}
        >
          {loadingFile ? (
            <SimpleLoader className='h-5' />
          ) : (
            <>
              <DocumentArrowDownIcon className='h-5' />
              {text}
            </>
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className=' ring-black absolute mt right-0 z-10  w-56 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none'>
            {[
              { key: 'en', value: 'en-US', label: 'English', icon: FlagEN },
              { key: 'ru', value: 'ru', label: 'Русский', icon: FlagRU },
            ]?.map((item) => (
              <Menu.Item key={item.key}>
                {({ active }) => (
                  <button
                    // href={item.href}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'flex flex-row items-center gap-2 w-full px-4 py-2 text-left text-sm font-semibold text-gray-700',
                    )}
                    onClick={() => {
                      setLoadingFile(true);
                      (screen_ref && file.add_screen
                        ? handleGeneratePdf({
                            lang: item.value,
                          })
                        : handle({ lang: item.value })
                      ).finally(() => setLoadingFile(false));
                    }}
                  >
                    <item.icon /> {item.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default Export;

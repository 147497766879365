import React, { useEffect, useState } from 'react';
import { getProjectAdvice } from '../../../services/projects/projectService';
import { useParams } from 'react-router-dom';

const ChatAI = ({ isVisible }) => {
  const [projectAdvice, setProjectAdvice] = useState('');
  const { uuid } = useParams();

  useEffect(() => {
    let currentIndex = 0;
    let intervalId;

    const fetchData = async () => {
      try {
        const res = await getProjectAdvice(uuid);
        setProjectAdvice('');
        currentIndex = 0;
        animateText(res);
      } catch (error) {
        console.error('Error fetching advice:', error);
      }
    };

    const animateText = (text) => {
      intervalId = setInterval(() => {
        if (currentIndex < text.length - 1) {
          setProjectAdvice((prevAdvice) => prevAdvice + text[currentIndex]);
          currentIndex++;
        } else {
          clearInterval(intervalId);
        }
      }, 2);
    };

    if (isVisible) {
      fetchData();
    }

    return () => clearInterval(intervalId);
  }, [isVisible, uuid]);

  return (
    <div className={isVisible ? 'flex gap-2' : 'hidden'}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='#cf4dfb'
        viewBox='0 0 24 24'
        strokeWidth='1'
        stroke='#3832bc'
        className='w-8 h-8'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z'
        />
      </svg>

      {projectAdvice ? (
        <div className='whitespace-pre-line w-11/12'>{projectAdvice}</div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default ChatAI;

import React from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';

const Edit = ({ description }) => {
  return (
    <>
      <PencilIcon
        className='text-blue-500  h-9 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-2'
        onClick={() => {
          window.open(`description/${description.uuid}/edit`, '_blank');
        }}
      />
    </>
  );
};

export default Edit;

// src/Sidebar.js
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useContext } from 'react';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';

const MobileSidebarModal = ({
  uuid,
  children,
  HeaderIcon,
  headerText,
  onClose,
}) => {
  const { id } = useContext(SidebarContext);
  return (
    <div
      style={{ width: uuid === id ? '100vw' : '0' }}
      className='fixed bottom-0 left-0 z-10 h-[93vh] bg-black bg-opacity-70'
    >
      <div className='w-full h-full' onClick={onClose}></div>
      <div
        className={`fixed bottom-0 right-0 h-[93vh] max-w-[screen] z-20 w-fit bg-white transform transition-transform p-4 border ${
          uuid === id
            ? `translate-x-0 duration-300 ${id ? '' : 'delay-200'}`
            : 'translate-x-full duration-300 z-20'
        }`}
      >
        <div className='flex flex-col gap-8'>
          <div className='flex flex-wrap justify-between'>
            <div className='flex flex-row gap-2'>
              {HeaderIcon && <HeaderIcon className='h-6 text-blue-600' />}
              <h6 className='self-end text-xl font-semibold'>{headerText}</h6>
            </div>
            <XMarkIcon
              className='h-6 cursor-pointer text-secondary'
              onClick={() => {
                onClose();
              }}
            />
          </div>
          <div className='flex items-center'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MobileSidebarModal;

import { toast } from 'react-toastify';
import { Button } from '../../../../../components/forms/Buttons/Button';

export default function DeleteSub({ name, subName, setData }) {
  const handleDeleteConfirmation = (deleteFunc) => {
    toast.dark(
      <>
        <div>Are you sure you want to delete the subtask?</div>
        <span className='text-xs'>Deleted tasks can’t be recovered</span>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text='Yes'
            color='error-solid'
            onClick={() => deleteFunc()}
            autoFocus
          />
          <Button text='No' className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };
  return (
    <Button
      type='button'
      color='error-solid'
      text='Delete'
      className='max-h-6'
      onClick={() => {
        handleDeleteConfirmation(() =>
          setData((prev) => {
            const newArray = prev?.body?.map((item) => {
              if (item?.name === name) {
                const newSubArray = item?.subtasks?.filter(function (obj) {
                  return obj.name !== subName;
                });

                let minStartDate = newSubArray.reduce(
                  (prev, cur) =>
                    prev < cur.start_date ? prev : cur.start_date,
                  newSubArray[0]?.start_date,
                );
                let newTime = Math.floor(
                  (newSubArray.reduce((prev, next) => {
                    let next_deadline =
                      new Date(next.start_date).getTime() +
                      Number(next?.time?.replace('day', '')?.replace('s', '')) *
                        24 *
                        60 *
                        60 *
                        1000;
                    return prev > next_deadline ? prev : next_deadline;
                  }, 0) -
                    new Date(minStartDate)) /
                    (24 * 60 * 60 * 1000),
                );
                if (!newTime) {
                  newTime = 0;
                }
                return {
                  ...item,
                  time: `${newTime} day${newTime > 1 ? 's' : ''}`,
                  subtasks: newSubArray,
                };
              } else {
                return item;
              }
            });
            return { ...prev, body: newArray };
          }),
        );
      }}
    />
  );
}

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  DocumentIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../components/forms/Buttons/Button';
import { UserContext } from '../../../utils/contexts/UserContext';
import avatarPlaceholder from '../../../assets/images/avatar_placeholder.jpg';
import moment from 'moment';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { patchProjectSubmission } from '../../../services/projects/projectSubmissionService';
import { toast } from 'react-toastify';
import { postSubmissionReview } from '../../../services/projects/projectSubmissionReviewService';

const CheckSubmission = ({ submission, refreshData }) => {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);

  const [comment, setComment] = useState();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-4 border-2 shadow-sm p-4 rounded-lg'>
        <div className='flex flex-row justify-between items-center'>
          <div className='flex flex-row items-center gap-4'>
            {submission?.evaluation === 1 ? (
              <div className='text-success flex flex-row items-center gap-2'>
                {t('projects.accepted')}
                <CheckCircleIcon className='h-6' />
              </div>
            ) : submission?.evaluation === 0 ? (
              <div className='text-error flex flex-row items-center gap-2'>
                {t('projects.declined')}
                <XCircleIcon className='h-6' />
              </div>
            ) : submission?.is_checking ? (
              <div className='text-primary flex flex-row items-center gap-2'>
                {t('projects.checking')}
                <ClockIcon className='h-6' />
              </div>
            ) : (
              <div className='text-secondary flex flex-row items-center gap-2'>
                {t('projects.submitted')}
                <ClockIcon className='h-6' />
              </div>
            )}
            <div className='text-secondary'>
              {moment(submission?.updated).format('MMM DD, yyyy')}
            </div>
          </div>
          <div className='flex flex-row gap-2'>
            <Button
              color='primary'
              text={t('projects.download')}
              Icon={ArrowDownTrayIcon}
              onClick={() => {
                if (submission?.file1) {
                  fetch(submission?.file1, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                    .then((response) => response.blob())
                    .then((blob) => {
                      // Create blob link to download
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement('a');
                      link.href = url;
                      const filenameParts = submission?.file1.split('/');
                      const filename = filenameParts[filenameParts?.length - 1];
                      link.setAttribute('download', filename);

                      // Append to html link element page
                      document.body.appendChild(link);

                      // Start download
                      link.click();

                      // Clean up and remove the link
                      link.parentNode.removeChild(link);
                    });
                }
                if (submission?.file2) {
                  fetch(submission?.file2, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                    .then((response) => response.blob())
                    .then((blob) => {
                      // Create blob link to download
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement('a');
                      link.href = url;
                      const filenameParts = submission?.file2.split('/');
                      const filename = filenameParts[filenameParts?.length - 1];
                      link.setAttribute('download', filename);

                      // Append to html link element page
                      document.body.appendChild(link);

                      // Start download
                      link.click();

                      // Clean up and remove the link
                      link.parentNode.removeChild(link);
                    });
                }
              }}
            />
          </div>
        </div>
        <div className='flex flex-row gap-4'>
          <div className='flex flex-row gap-2'>
            {submission?.file1 && (
              <div className='border-2 rounded-lg flex flex-col gap-2 items-center p-4'>
                <DocumentIcon className='h-16 text-secondary' />
                <div>{t('projects.file_1')}</div>
              </div>
            )}
            {submission?.file2 && (
              <div className='border-2 rounded-lg flex flex-col gap-2 items-center p-4'>
                <DocumentIcon className='h-16 text-secondary' />
                <div>{t('projects.file_2')}</div>
              </div>
            )}
          </div>
          <div className='flex flex-row gap-4 items-center border-2 rounded-lg p-4 w-full'>
            <div className='shrink-0'>
              <img
                src={
                  submission?.project_assignment?.student?.profile_picture ||
                  avatarPlaceholder
                }
                alt='avatar'
                className='md:w-20 md:h-20 lg:w-20 lg:h-20 w-16 h-16
                  rounded-full object-cover'
              />
            </div>
            <div className='flex flex-col gap-2'>
              <div className='font-medium'>
                <span className='text-primary'>{`${submission?.project_assignment?.student?.first_name} ${submission?.project_assignment?.student?.last_name}`}</span>{' '}
                {t('projects.messaged')}:
              </div>
              <div>{submission?.comment}</div>
            </div>
          </div>
        </div>
      </div>
      <TextArea
        // label={t('accounting.comment')}
        rows={8}
        className='border rounded-lg shadow-md'
        placeholder={t('projects.type_comment')}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className='flex flex-row justify-end gap-2'>
        <Button
          color={'error-solid'}
          text={t('projects.decline')}
          Icon={XMarkIcon}
          onClick={() => {
            const body = new FormData();
            body.append('evaluated_by', user?.uuid);
            body.append('project_submission', submission?.uuid);
            body.append('evaluation', 0);
            body.append('comment', comment);

            const patchBody = new FormData();
            patchBody.append('is_checking', false);
            patchProjectSubmission(submission?.uuid, patchBody)
              .then(() => {
                refreshData();
              })
              .catch(() => {});

            setLoading(true);
            postSubmissionReview(body)
              .then((res) => {
                toast.success(t('projects.status_changed'));
              })
              .catch(() => {})
              .finally(() => setLoading(false));
          }}
        />
        <Button
          color={'success'}
          text={t('projects.accept')}
          Icon={CheckIcon}
          onClick={() => {
            const body = new FormData();
            body.append('evaluated_by', user?.uuid);
            body.append('project_submission', submission?.uuid);
            body.append('evaluation', 1);
            body.append('comment', comment);

            setLoading(true);

            const patchBody = new FormData();
            patchBody.append('is_checking', false);
            patchProjectSubmission(submission?.uuid, patchBody)
              .then(() => {
                refreshData();
              })
              .catch(() => {});

            postSubmissionReview(body)
              .then((res) => {
                toast.success(t('projects.status_changed'));
              })
              .catch(() => {})
              .finally(() => setLoading(false));
          }}
        />
      </div>
    </div>
  );
};

export default CheckSubmission;

import Select from '../../../components/forms/Select/Select';
import Input from '../../../components/forms/Inputs/Input';
import { Button } from '../../../components/forms/Buttons/Button';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextArea from '../../../components/forms/Inputs/TextArea';
import Upload from './Upload';
import { postInstruction } from '../../../services/instructions/instructionService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#f2f3f4',
    borderWidth: '1px',
    borderColor: 'rgb(156 163 175)',
    borderRadius: '0.375rem',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: '1rem',
    fontSize: '14px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: '0.5rem',
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#374151',
    };
  },
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#E9ECF5',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    display: state.selectProps.menuIsOpen ? 'none' : 'block',
  }),
};

const CreateInstructionForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const options = [
    { value: 0, label: 'Documentation link' },
    { value: 1, label: 'Video-instruction' },
  ];
  // Watch the selected instruction type
  const instructionType = watch('type');

  const onSubmit = (data) => {
    postInstruction(data)?.then((res) => {
      toast.success('Successfully created Instruction');
      navigate('/instructions/');
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className='flex flex-col gap-4 max-w-md m-auto pt-4'>
        <h1 className='font-bold'>Instruction Form</h1>
        <div className='flex flex-col'>
          <Input
            type='text'
            errorMsg={errors.title?.message}
            label='Title'
            id='title'
            required
            inputClassName='bg-custom-gray border'
            {...register('title', {
              required: 'Title is a required field!',
            })}
          />
        </div>
        <div className='flex flex-col'>
          <Controller
            control={control}
            rules={{ required: 'Choose type of instruction!' }}
            name='type'
            render={({ field }) => (
              <Select
                customStyles={customStyles}
                onChange={(e) => {
                  field.onChange(e.value);
                  // If the user selects 'doc', reset the image field
                  if (e.value === 'doc') {
                    setValue('image', null);
                  }
                }}
                value={field.value}
                placeholder='Choose type of instruction'
                label='Type'
                options={options}
                required
                errorMsg={errors.instructionType?.message}
                textStyles='text-sm font-medium'
              />
            )}
          />
          {errors.type?.message && (
            <span className='mt-1 text-xs font-semibold text-error'>
              {errors.type?.message}
            </span>
          )}
        </div>

        <div className='flex flex-col'>
          <TextArea
            errorMsg={errors.description?.message}
            required
            label='Description'
            className='text-sm font-medium'
            inputClassName='bg-custom-gray ring-0 ring-transparent'
            {...register('description', {
              required: 'Description is a required field!',
            })}
          />
        </div>
        <div className='flex flex-col'>
          <Input
            errorMsg={errors.link?.message}
            type='url'
            label='Link'
            id='link'
            required
            inputClassName='bg-custom-gray'
            {...register('link', {
              required: 'Link is a required field!',
            })}
          />
        </div>
        {instructionType === 0 && (
          <div className='flex flex-col'>
            <p className='text-sm font-medium text-gray-700'>
              Title Photo{' '}
              <span className='text-sm font-medium text-error'>*</span>
            </p>
            <Controller
              control={control}
              rules={{ required: 'Upload the photo!' }}
              name='image'
              render={({ field }) => <Upload setFile={field.onChange} />}
            />
            {errors.image?.message && (
              <span className='mt-1 text-xs font-semibold text-error'>
                {errors.image?.message}
              </span>
            )}
          </div>
        )}
        <div className='flex justify-center'>
          <Button
            type='submit'
            text='Save'
            className='w-44'
            // onClick={() => console.log('onClick')}
          />
        </div>
      </section>
      {/* {submitedData && <InstructionCard data={submitedData} />} */}
    </form>
  );
};

export default CreateInstructionForm;

import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getProjectSubmissions = ({
  uuid,
  is_checking,
  project_assignment,
}) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .get(`/submissions/project_submission/`, {
          params: {
            uuid,
            is_checking,
            project_assignment,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getProjectSubmission = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/submissions/project_submission/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postProjectSubmission = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/submissions/project_submission/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchProjectSubmission = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/submissions/project_submission/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import AdminTest from './AdminTest/AdminTest';
import StudentTest from './StudentTest/StudentTest';
import { useParams } from 'react-router-dom';
import { getTests } from '../../../services/tests/testService';
import { getQuestions } from '../../../services/tests/questionService';

const Test = () => {
  const { user } = useContext(UserContext);
  const { uuid } = useParams();
  const [test, setTest] = useState();
  const [questions, setQuestions] = useState([]);

  const getTest = useCallback(
    () =>
      getTests({ uuid })?.then((res) => {
        setTest(res);
      }),
    [uuid],
  );
  const getQuestionsData = useCallback(() => {
    getQuestions({ test: uuid, page_size: 100 }).then((res) => {
      setQuestions(res.results);
    });
  }, [setQuestions, uuid]);

  useEffect(() => {
    getTest();
    getQuestionsData();
  }, [getTest, getQuestionsData]);

  if (user?.roles?.some((r) => r?.name === 'ADMIN')) {
    return (
      <AdminTest
        test={test}
        questions={questions}
        refreshQuestions={getQuestionsData}
      />
    );
  } else {
    return <StudentTest test={test} questions={questions} />;
  }
};

export default Test;

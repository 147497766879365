import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilIcon, PlayIcon } from '@heroicons/react/24/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { deleteStudyPlan } from '../../services/studyPlans/studyPlanService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../modals/DeleteModal/DeleteModal';
import { UserContext } from '../../utils/contexts/UserContext';
import bgImage from '../../assets/images/smallImg.jpg';
import CircleProgressBar from '../charts/CircleProgressBar';

export default function StudyPlanCard({ studyPlan, refreshData }) {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   console.log('studyPlan', studyPlan);
  // }, [studyPlan]);

  // useEffect(() => {
  //   console.log('user', user);
  // }, [user]);

  const [loadingDelete, setLoadingDelete] = useState(false);

  return (
    <div className='relative w-full md:w-72 h-48 rounded-xl overflow-hidden shadow-md border'>
      <img
        className='w-full'
        src={studyPlan?.picture ?? bgImage}
        alt='Placeholder'
      />
      <div className='absolute inset-0 bg-black opacity-50'></div>
      <div className='absolute top-0 left-0 p-4 text-white'>
        <div className='font-bold text-xl mb-2'>{studyPlan?.title}</div>
        <p className='text-base'>
          Author:{' '}
          {studyPlan?.author?.first_name
            ? studyPlan?.author?.first_name + ' ' + studyPlan?.author?.last_name
            : 'no author'}
        </p>
      </div>
      <div className='absolute flex flex-row justify-between bottom-0 left-0 w-full px-4 py-1 bg-white'>
        <div>
          <div className='inline-block mr-4 text-center'>
            <div className='text-xl font-medium'>
              {studyPlan?.sections_count}
            </div>
            <div className='text-xs text-gray-600'>Sections</div>
          </div>
          <div className='inline-block mr-4 text-center'>
            <div className='text-xl font-medium'>
              {studyPlan?.projects_count}
            </div>
            <div className='text-xs text-gray-600'>Projects</div>
          </div>
          {/* <div className='inline-block mr-4 text-center'>
            <div className='text-lg font-medium'>
              {studyPlan?.sections?.length}
            </div>
            <div className='text-xs text-gray-600'>Sections</div>
          </div> */}
        </div>
        <div className='flex flex-row gap-1 items-center'>
          {user &&
          (studyPlan?.author?.uuid === user?.uuid ||
            user?.roles?.some((r) => r?.name === 'PROJECTADMIN')) ? (
            <span className=''>
              <button
                onClick={() => {
                  setOpen(true);
                }}
                className='relative w-8 h-8 bg-white rounded-full border-4 border-gray-300 hover:border-error focus:outline-none'
              >
                {/* Play icon (you can replace this with your own SVG or icon library) */}
                <TrashIcon
                  className={`w-4 h-4 text-error absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                    loadingDelete && 'animate-pulse'
                  }`}
                />
              </button>
            </span>
          ) : undefined}
          {user &&
          (studyPlan?.author?.uuid === user?.uuid ||
            user?.roles?.some((r) => r?.name === 'PROJECTADMIN')) ? (
            <span className=''>
              <button
                onClick={() => {
                  navigate(`/study-plans/edit/${studyPlan?.uuid}`);
                }}
                className='relative w-8 h-8 bg-white rounded-full border-4 border-gray-300 hover:border-gray-400 focus:outline-none'
              >
                {/* Play icon (you can replace this with your own SVG or icon library) */}
                <PencilIcon className='w-4 h-4  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
              </button>
            </span>
          ) : undefined}
          <span
            className='pl-1 rounded-full cursor-pointer'
            onClick={() => {
              navigate(`/study-plans/${studyPlan?.uuid}`);
            }}
          >
            {studyPlan.accepted_projects_count ? (
              <CircleProgressBar
                progress={(
                  (studyPlan.accepted_projects_count /
                    studyPlan.projects_count) *
                  100
                ).toFixed()}
              />
            ) : (
              <div className='relative w-11 h-11 bg-white rounded-full border-4 border-gray-300 hover:border-gray-400 focus:outline-none'>
                <PlayIcon className='w-5 h-5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
              </div>
            )}
          </span>
        </div>
      </div>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        proceed={() => {
          setLoadingDelete(true);
          deleteStudyPlan(studyPlan?.uuid)
            .then(() => {
              refreshData();
              toast.success(t('roadmap.deleted_successfully'));
            })
            .catch(() => {})
            .finally(() => setLoadingDelete(false));
        }}
      />
    </div>
  );
}

import React from 'react';
import { Button } from '../../../../components/forms/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import Add from './BREAD/Add';
import Content from './Contents/Content';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';

const ContentCreater = ({ data, refreshData, loading }) => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col'>
      <div className='flex flex-row'>
        <Button text='Back' onClick={() => navigate(-1)} />
      </div>
      <div className='flex flex-col border rounded-2xl p-8 m-8 gap-2'>
        {loading ? (
          <SimpleLoader className={'h-12'} />
        ) : (
          data?.content?.map((d) => {
            return (
              <Content
                data={d}
                refreshData={refreshData}
                contentCount={data?.content?.length}
              />
            );
          })
        )}
        <Add
          refreshData={refreshData}
          lesson={data?.uuid}
          order={data?.content?.length}
        />
      </div>
    </div>
  );
};
export default ContentCreater;

import { useContext, useEffect, useState } from 'react';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import {
  deleteRoadmap,
  getRoadmaps,
} from '../../../../services/roadmapTask/roadmapService';
import { getStudents } from '../../../../services/accountData/studentsService';
import { PlayIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/forms/Buttons/Button';
import { UserContext } from '../../../../utils/contexts/UserContext';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

const StudentRoadmaps = ({ goBack, group, org }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (group) {
      setLoading(true);
      getStudents({ page_size: 100, group: group?.uuid })
        ?.then((res) => {
          setStudents(res?.results);
          getRoadmaps({ page_size: 100, group: group?.uuid })
            ?.then((res) => {
              setStudents((prev) =>
                prev
                  .map((p) => ({
                    ...p,
                    roadmap: res.results?.find(
                      (r) => r.student?.uuid === p.uuid,
                    ),
                  }))
                  ?.sort((a, b) => (a.roadmap ? -1 : 1)),
              );
            })
            ?.finally(() => setLoading(false));
        })
        ?.catch(() => setLoading(false));
    }
  }, [group]);

  const handleDeleteConfirmation = (uuid) => {
    toast.dark(
      <>
        <div>Are you sure you want to delete the roadmap?</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text='Yes'
            color='error-solid'
            onClick={() => handleDelete(uuid)}
            autoFocus
          />
          <Button text='No' className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  function handleDelete(uuid) {
    deleteRoadmap(uuid)
      .then((res) => {
        toast.info('Roadmap successfully deleted');
        setStudents((prev) =>
          prev.map((std) =>
            std.roadmap?.uuid === uuid ? { ...std, roadmap: null } : std,
          ),
        );
      })
      .catch((error) => {
        toast.info('Something went wrong');
      });
  }
  return (
    <>
      <p className='font-bold text-lg'>
        {org?.name}, {group?.name}
      </p>
      <Button onClick={() => goBack()} text='Back' />
      <div className='flex flex-row flex-wrap py-4 gap-4'>
        {loading ? (
          <SimpleLoader className='h-8' />
        ) : (
          students?.map((std, i) => {
            return (
              <div
                // onClick={() => onSelect(std.uuid)}
                key={std.uuid}
                className={`relative w-80 h-48 rounded-xl border-4 cursor-pointer
        flex flex-col justify-between text-lgfont-bold bg-gradient-to-tr group ${
          std.roadmap
            ? std?.roadmap?.assignor?.uuid === user.uuid
              ? 'from-red-500 to-red-200'
              : 'from-green-400 to-blue-400'
            : 'bg-gray-400'
        }`}
                onClick={() => {
                  if (std.roadmap) {
                    navigate(`/profile/${std.roadmap.student.uuid}`, {
                      target: '_blank',
                      state: {
                        tab: 'roadmap',
                      },
                    });
                    return;
                  }
                  navigate('/roadmap/create', {
                    state: { student: std, group, organization: org },
                  });
                }}
              >
                {std?.roadmap?.assignor?.uuid === user.uuid && (
                  <div className='flex flex-row gap-1 absolute -right-3 -top-3'>
                    <PencilIcon
                      className='h-8 p-1.5 hidden group-hover:block rounded-full text-primary bg-blue-100'
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`edit/${std?.roadmap.uuid}`);
                        return;
                      }}
                    />
                    <TrashIcon
                      className='h-8 p-1.5 hidden group-hover:block rounded-full text-error bg-red-100'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConfirmation(std?.roadmap.uuid);
                      }}
                    />
                  </div>
                )}
                <div className='flex flex-col h-full justify-between p-4 '>
                  <div className='flex flex-col'>
                    <div className='flex flex-col text-white text-2xl font-bold'>
                      <span>
                        {std.first_name} {std.last_name}
                      </span>
                      <span className='text-sm'>
                        {std.roadmap?.name}
                        {!std.roadmap && (
                          <span>This student does not have a Roadmap</span>
                        )}
                      </span>
                    </div>
                  </div>

                  {std.roadmap && (
                    <span className='text-sm text-gray-200'>
                      Author:{' '}
                      {`${std.roadmap.assignor?.first_name} ${std.roadmap.assignor?.last_name}`}
                    </span>
                  )}
                </div>
                {std.roadmap ? (
                  <div className='relative bg-white flex flex-row justify-between p-1 px-2'>
                    <div className='flex flex-row gap-4'>
                      <div className='flex flex-col items-center -space-y-2'>
                        <span className='text-lg font-semibold'>
                          {Math.floor(
                            (new Date(std.roadmap.end_date).getTime() -
                              new Date(std.roadmap.start_date).getTime()) /
                              (7 * 24 * 60 * 60 * 1000),
                          )}
                        </span>
                        <span className='text-gray-500 text-sm'>weeks</span>
                      </div>
                      <div className='flex flex-col items-center -space-y-2'>
                        <span className='text-lg font-semibold'>
                          {std.roadmap.task_count}
                        </span>
                        <span className='text-gray-500 text-sm'>Tasks</span>
                      </div>
                      <div className='flex flex-col items-center -space-y-2'>
                        <span className='text-lg font-semibold'>
                          {std.group}
                        </span>
                        <span className='text-gray-500 text-sm'>Grade</span>
                      </div>
                    </div>

                    <div>
                      <div className='absolute right-2 bottom-2.5 flex flex-col justify-end items-center text-sm'>
                        <PlayIcon
                          className='h-9 rounded-full border p-1.5 hover:bg-slate-200 bg-white'
                          onClick={() => {
                            navigate(`/profile/${std.roadmap.student.uuid}`, {
                              target: '_blank',
                              state: {
                                tab: 'roadmap',
                              },
                            });
                          }}
                        />
                        <div className='flex flex-row items-end gap-1'>
                          {std.roadmap.task_count &&
                            Math.floor(
                              (std.roadmap.done_count /
                                std.roadmap.task_count) *
                                100,
                            )}
                          <span className='text-xs'>%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='relative bg-white flex flex-row justify-between p-1 px-2'>
                    <div className='flex flex-row gap-4'>
                      <div className='flex flex-col items-center -space-y-2 invisible'>
                        <span className='text-lg font-semibold'>.</span>
                        <span className='text-gray-500 text-sm'>weeks</span>
                      </div>
                    </div>

                    <div>
                      <div className='absolute right-2 bottom-2.5 flex flex-col justify-end items-center text-sm'>
                        <PlusIcon className='h-9 stroke-2 rounded-full border p-1.5 hover:bg-slate-200 bg-white text-primary' />
                        <div className='flex flex-row items-end gap-1 invisible'>
                          0<span className='text-xs'>%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default StudentRoadmaps;

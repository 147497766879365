import React, { useEffect, useRef, useState } from 'react';

import { postResultDescriptionFile } from '../../../../services/tests/resultService';

import { Button } from '../../../../components/forms/Buttons/Button';
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import Input from '../../../../components/forms/Inputs/Input';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react';

const AddFile = ({ refreshData, close, description }) => {
  const fileRef = useRef();
  const [doc, setDoc] = useState();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    reset();
  }, [close, reset]);

  const handleFileChange = (e) => {
    e.preventDefault();
    console.log(e.target.files[0].type);
    if (e.target.files[0].type !== 'application/pdf') {
      toast.error(
        'Жүктелген файл PDF болуы керек! / Загружаемый вами файл должен быть PDF!',
        'error',
      );
      return;
    }
    if (e.target.files[0].size > 1048576) {
      toast.error(
        'Жүктелген файл 1 мегабайттан аспау керек! / Загружаемый вами файл не должен превышать 1 МБ',
        'error',
      );
      return;
    }
    let doc = e.target.files[0];
    setDoc(doc);
  };

  const onSubmit = (data) => {
    const body = new FormData();
    body.append('name', data.name);
    body.append('add_screen', data.add_screen);
    body.append('result_description', description.uuid);
    if (doc) body.append('file', doc);

    postResultDescriptionFile(body)?.then(() => {
      refreshData();
      reset();
      setDoc();
    });
  };

  return (
    <form
      className='flex flex-row gap-2 border p-4 rounded-lg items-end w-full justify-between'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-row gap-4 items-end'>
        <Input
          className='flex flex-col items-start'
          errorMsg={errors?.result?.message}
          required
          label='Name'
          {...register('name', {
            required: 'Name is required field',
          })}
        />
        <div className='flex flex-row items-center gap-2'>
          <DocumentArrowUpIcon
            className='h-9 cursor-pointer text-blue-500 hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-1.5'
            onClick={() => fileRef.current.click()}
          />
          <span>{doc && doc.name}</span>
          <Controller
            control={control}
            name='add_screen'
            defaultValue={false}
            render={({ field }) => (
              <Switch
                title='Screenshot'
                checked={field.value}
                onChange={field.onChange}
                className={`${
                  field.value ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className='sr-only'>Enable screenshot</span>
                <span
                  className={`${
                    field.value ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            )}
          />
        </div>
      </div>
      <input hidden type='file' ref={fileRef} onChange={handleFileChange} />

      <Button text='add' type='submit' />
    </form>
  );
};

export default AddFile;

import React from 'react';

const Input = React.forwardRef(
  (
    {
      name,
      label,
      required,
      errorMsg,
      textCenter,
      className,
      type,
      inputClassName = '',
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={className}>
        <label
          htmlFor={name}
          className='block text-sm font-medium text-gray-700'
        >
          {label}
          <span className={`text-error`}>{required && '*'}</span>
          <input
            id={name}
            name={name}
            className={
              `block w-full rounded-md border-gray-400 px-3 py-2 pr-12 border shadow-sm disabled:bg-slate-200 sm:text-sm ${
                textCenter && 'text-center'
              } ` + inputClassName
            }
            {...rest}
            type={type ? type : 'text'}
            ref={ref}
          />
        </label>
        {errorMsg && (
          <span className='mt-1 text-xs font-semibold text-error'>
            {errorMsg}
          </span>
        )}
      </div>
    );
  },
);

export default Input;

import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { deleteSection } from '../../../../../services/courses/sectionService';

const Delete = ({ section, selected, setSelected, refreshData, data }) => {
  return (
    <TrashIcon
      className='text-red-500 h-9 cursor-pointer hover:bg-error bg-red-100 hover:text-white rounded-lg p-2'
      onClick={() => {
        let is_confimed = window.confirm(
          `Are you sure you want to delete section "${section.name}"?`,
        );
        if (!is_confimed) {
          return;
        }
        deleteSection(section.uuid).then(() => {
          if (selected === section.uuid) {
            if (section.order === 1 && data.length > 1) {
              setSelected(data[1].uuid);
            } else if (section.order <= data.length) {
              setSelected(data[section.order - 2].uuid);
            }
          }
          refreshData();
        });
      }}
    />
  );
};

export default Delete;

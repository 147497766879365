import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { extractVideoID } from '../../utils/helpers/helpers';
import { DocumentIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

export default function InstructionsPreview({ instruction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [youtubeId, setYoutubeId] = useState(extractVideoID(instruction?.link));

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <>
          <div className='flex flex-col md:flex-row md:gap-6 gap-2'>
            <div className='grid col-start-1 col-end-4'>
              <Link
                to={
                  instruction.type === 0
                    ? instruction.link
                    : `/instructions/${instruction?.uuid}`
                }
              >
                <div className='flex flex-row overflow-y-hidden w-80 h-44 group hover:bg-blue-400 border border-transparent rounded-2xl'>
                  {instruction.type === 0 ? (
                    instruction?.image ? (
                      <img
                        style={{
                          width: '320px',
                          height: 'auto',
                          objectFit: 'cover',
                        }}
                        src={instruction?.image}
                        alt='instruction'
                        className='rounded-2xl img-responsive border-2 hover:border-blue-400'
                      />
                    ) : (
                      <div
                        className='flex flex-col justify-center items-center w-full text-primary group-hover:text-white 
                    rounded-2xl border-2 hover:border-blue-400'
                      >
                        <DocumentTextIcon className='h-10 text-primary' />
                      </div>
                    )
                  ) : (
                    <img
                      alt='Instruction preview'
                      src={
                        youtubeId?.length > 0
                          ? `https://img.youtube.com/vi/${youtubeId}/0.jpg`
                          : 'https://pharem-project.eu/wp-content/themes/consultix/images/no-image-found-360x250.png'
                      }
                      style={{
                        width: '320px',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                      className='rounded-2xl img-responsive border-2 hover:border-blue-400'
                    />
                  )}
                </div>
              </Link>
            </div>
            <div
              className='grid col-start-5 col-end-12'
              style={{ width: '100%' }}
            >
              <div className='flex flex-col gap-2'>
                <h3 style={{ fontWeight: 'bold', fontSize: '16pt' }}>
                  {instruction?.title}
                </h3>
                <h4>{instruction?.description}</h4>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

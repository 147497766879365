import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import React from 'react';
import {
  postDecreaseSectionOrder,
  postIncreaseSectionOrder,
} from '../../../../services/courses/sectionService';
import Add from './BREAD/Add';
import Delete from './BREAD/Delete';
import Edit from './BREAD/Edit';

const Sections = ({ data, selected, setSelected, refreshData, course }) => {
  return (
    <div className='flex flex-col shadow-xl hover:shadow-2xl rounded-2xl w-2/5 overflow-hidden border h-min'>
      {data.map((d) => {
        return (
          <>
            <div
              key={d.uuid}
              className={`flex flex-row justify-between py-2 px-8 cursor-pointer border-b-2 last:border-b-0 items-center ${
                selected === d.uuid && 'bg-black text-white'
              }`}
              onClick={() => setSelected(d.uuid)}
            >
              <div className='flex flex-row gap-4 w-2/3 overflow-hidden truncate'>
                <div className='flex flex-col gap-2'>
                  <ChevronUpIcon
                    className={`h-5 hover:opacity-40 ${
                      d.order === 1 && 'invisible'
                    }`}
                    onClick={() => {
                      postIncreaseSectionOrder(d.uuid).then(() => {
                        refreshData();
                      });
                    }}
                  />
                  <ChevronDownIcon
                    className={`h-5 hover:opacity-40 ${
                      d.order === data.length && 'invisible'
                    }`}
                    onClick={() => {
                      postDecreaseSectionOrder(d.uuid).then(() => {
                        refreshData();
                      });
                    }}
                  />
                </div>
                <p className='flex flex-col text-left'>
                  <span className='text-lg truncate' title={d.name}>
                    {d.name}
                  </span>
                  <span className='text-sm truncate' title={d.description}>
                    {d.description}
                  </span>
                  <span className='text-sm truncate' title={d.description}>
                    {`Lessons: ${d.lessons}`}
                  </span>
                </p>
              </div>
              <div className='flex flex-row gap-2'>
                <Edit section={d} refreshData={refreshData} />
                <Delete
                  section={d}
                  data={data}
                  refreshData={refreshData}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </div>
          </>
        );
      })}
      <div className='h-14 flex flex-row justify-center items-center'>
        <Add course={course.uuid} refreshData={refreshData} />
      </div>
    </div>
  );
};

export default Sections;

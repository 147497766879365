import bilAPI from '../api';

export const getUniversities = ({
  page,
  size,
  fullScholarship,
  ratings,
  faculties,
  country,
  name,
  search,
  fee,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/universities/university_landing/`, {
          params: {
            page,
            size,
            fullScholarship,
            ratings,
            faculties,
            country,
            name,
            search,
            fee,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getUniversityInfo = (key) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/universities/university_info/${key}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getUniversityRatingInfo = (key) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/universities/university_rating_info/${key}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getUniversityCountries = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/universities/university_country/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import { PlusSmallIcon } from '@heroicons/react/24/outline';
import Input from '../../../../../components/forms/Inputs/Input';
import TextArea from '../../../../../components/forms/Inputs/TextArea';
import SidebarModal from '../../../../../components/modals/SidebarModal/SidebarModal';
import { Button } from '../../../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SidebarContext } from '../../../../../utils/contexts/SidebarContext';
import DatePicker from '../../../../../components/forms/DatePicker/DatePicker';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

const EditSub = ({ data, setData, task, subtask, taskIndex }) => {
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [period, setPeriod] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const onClose = () => {
    reset();
    setId('');
  };

  const reset = useCallback(() => {
    setTitle(subtask?.name);
    setDescription(subtask?.description);
    setPeriod(subtask?.time?.replace('day', '')?.replace('s', ''));
    setStartDate(new Date(subtask?.start_date));
  }, [subtask]);

  useEffect(() => {
    if (id !== uuid) {
      reset();
    }
  }, [id, uuid]);

  return (
    <>
      <Button
        color='primary'
        text='Edit'
        className='max-h-6'
        onClick={() => {
          setId(uuid);
        }}
      />

      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_task')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            required
          />
          <DatePicker
            date={startDate}
            setDate={(d) => {
              // if (endDate < d && endDate) {
              //   toast.error(t('roadmap.reversed_date_error'));
              //   return;
              // }
              setStartDate(d);
            }}
            label={t('roadmap.start_date')}
            required
          />
          <TextArea
            label={t('Description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            type='number'
            label={t('Period')}
            min='1'
            onChange={(e) => {
              setPeriod(e.target.value);
            }}
            value={Number(period)}
          />

          <div className='flex flex-row justify-center mt-4'>
            <Button
              text={t('Save')}
              loading={loadingSubmit}
              onClick={() => {
                if (period <= 0) {
                  toast.error("The period can't be less than 1 day");
                  return;
                }

                const newSubArray = data?.body[taskIndex]?.subtasks?.map(
                  (item, i) => {
                    if (item?.name === subtask?.name) {
                      return {
                        ...item,
                        name: title,
                        description,
                        time: `${Number(period)} day${period > 1 ? 's' : ''}`,
                        start_date: format(startDate, 'yyyy-MM-dd'),
                      };
                    } else {
                      return item;
                    }
                  },
                );
                let minStartDate = newSubArray.reduce(
                  (prev, cur) =>
                    prev < cur.start_date ? prev : cur.start_date,
                  newSubArray[0]?.start_date,
                );

                const newArray = data?.body?.map((item) => {
                  if (item?.name === task?.name) {
                    let newTime = Math.floor(
                      (newSubArray.reduce((prev, next) => {
                        let next_deadline =
                          new Date(next.start_date).getTime() +
                          Number(
                            next?.time?.replace('day', '')?.replace('s', ''),
                          ) *
                            24 *
                            60 *
                            60 *
                            1000;
                        return prev > next_deadline ? prev : next_deadline;
                      }, 0) -
                        new Date(minStartDate)) /
                        (24 * 60 * 60 * 1000),
                    );
                    return {
                      ...item,
                      subtasks: newSubArray.sort((a, b) =>
                        a.start_date > b.start_date ? 1 : -1,
                      ),
                      time: `${newTime} day${newTime > 1 ? 's' : ''}`,
                      start_date: minStartDate,
                    };
                  } else {
                    return item;
                  }
                });

                const newRoadmap = { ...data, body: newArray };
                setData(newRoadmap);

                onClose();
              }}
            />
          </div>
        </div>
      </SidebarModal>
    </>
  );
};

export default EditSub;

import React, { useContext, useEffect, useState } from 'react';
import { getStudyPlans } from '../../services/studyPlans/studyPlanService';
import StudyPlanCard from '../../components/cards/StudyPlanCard';
import NewStudyPlanCard from '../../components/cards/NewStudyPlanCard';
import PlacedholderCard from '../../components/cards/PlaceholderCard';
import { UserContext } from '../../utils/contexts/UserContext';
import Description from './Description';

export default function StudyPlans() {
  const [studyPlans, setStudyPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  const getData = () => {
    setLoading(true);
    getStudyPlans({})
      .then((res) => {
        // console.log('res', res);
        setStudyPlans(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='p-4 rounded-md bg-white flex flex-col gap-4'>
      {/* <h2 className='text-lg font-semibold'>Project sets</h2> */}
      <Description />
      <div className='flex flex-row flex-wrap gap-4'>
        {user?.roles?.some(
          (r) =>
            r?.name === 'ADMIN' ||
            r?.name === 'STUDENT' ||
            r?.name === 'PROJECTADMIN',
        ) ? (
          loading ? (
            <PlacedholderCard />
          ) : (
            <NewStudyPlanCard />
          )
        ) : undefined}
        {loading ? (
          <PlacedholderCard />
        ) : (
          studyPlans.map((sp) => (
            <StudyPlanCard
              key={sp?.uuid}
              studyPlan={sp}
              refreshData={getData}
            />
          ))
        )}
      </div>
    </div>
  );
}

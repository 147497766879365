import React, { useContext, useEffect, useState } from 'react';
import InputWithIcon from '../../../components/forms/Inputs/InputWithIcon';
import {
  CheckIcon,
  ClockIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import Select from '../../../components/forms/Select/Select';
import { FILTER_SELECT_STYLE } from '../../../utils/constants/constants';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/Pagination/Pagination';
import { getProjectSubmissions } from '../../../services/submissions/projectSubmissionService';
import moment from 'moment';
import { UserContext } from '../../../utils/contexts/UserContext';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import Title from '../Title';

const PAGE_SIZE = 20;

export default function TeacherSubmissions() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [submissionsToReview, setSubmissionsToReview] = useState([]);
  const [isChecking, setIsChecking] = useState(null);
  const [loading, setLoading] = useState(false);

  const onPageChange = async (pg) => {
    setLoading(true);
    await getProjectSubmissions({
      uuid: null,
      page: pg,
    })
      .then((res) => {
        submissionsToReview(res.results);
        setCount(res?.count);
      })
      .finally(() => {
        setLoading(false);
      });
    setPage(pg);
  };

  useEffect(() => {
    if (user?.uuid) {
      setLoading(true);
      getProjectSubmissions({ uuid: null, mentor: user.uuid })
        .then((res) => {
          setSubmissionsToReview(res?.results);
          setCount(res?.count);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {};
  }, [user]);

  const handleSearch = (arr, searchInput) => {
    // eslint-disable-next-line no-unused-vars
    const filteredData = arr?.filter((value) => {
      const searchStr = searchInput.toLowerCase();
      const nameMatches = value?.project_assignment?.student?.first_name
        ?.toLowerCase()
        .includes(searchStr);

      return nameMatches;
    });
    // console.log(filteredData);
    //this.setState({ list: filteredData });
  };

  const getStatus = (check, evaluate) => {
    if (check === false && evaluate === null) {
      return {
        status: 'Waiting',
        statusColor: 'text-orange-600',
        projectStatus: 'Not checked',
        statusIcon: 'clock',
      };
    }
    if (check === false && evaluate === 0) {
      return {
        status: 'Checked',
        statusColor: 'text-green-600',
        projectStatus: 'Declined',
        statusIcon: 'check',
      };
    }
    if (check === false && evaluate === 1) {
      return {
        status: 'Checked',
        statusColor: 'text-green-600',
        projectStatus: 'Accepted',
        statusIcon: 'check',
      };
    }
    if (check === true && evaluate === null) {
      return {
        status: 'Checking',
        statusColor: 'text-blue-600',
        projectStatus: 'Not checked',
        statusIcon: 'clock',
      };
    }
  };

  return (
    <div className='w-full flex flex-col gap-4 rounded-md p-4'>
      <Title />
      <div className='flex flex-col gap-4'>
        <div className='flex felx-row w-full h-8'>
          {false && (
            <InputWithIcon
              Icon={MagnifyingGlassIcon}
              onChange={(e) => {
                setSubmissionsToReview(
                  handleSearch(submissionsToReview, e.target.value),
                );
              }}
            />
          )}
          <Select
            customStyles={FILTER_SELECT_STYLE}
            placeholder='Status'
            isSearchable={false}
            value={isChecking}
            options={[
              {
                label: 'All',
                value: null,
              },
              {
                label: 'Waiting',
                value: 1,
                data: { check: false, evaluated: false },
              },
              {
                label: 'Checking',
                value: 2,
                data: { check: true, evaluated: null },
              },
              {
                label: 'Checked',
                value: 3,
                data: { check: false, evaluated: true },
              },
            ]}
            onChange={(e) => {
              getProjectSubmissions({
                uuid: null,
                mentor: user.uuid,
                is_checking: e.data?.check,
                evaluated: e.data?.evaluated,
              })
                .then((res) => {
                  // console.log('res', res);
                  setSubmissionsToReview(res.results);
                })
                .finally(() => {
                  setIsChecking(e.value);
                });
            }}
          />
          {false && (
            <Select
              customStyles={FILTER_SELECT_STYLE}
              placeholder='Class'
              isSearchable={false}
            />
          )}
          {false && (
            <Select
              customStyles={FILTER_SELECT_STYLE}
              placeholder='Letter'
              isSearchable={false}
            />
          )}
        </div>
        <div className='w-full flex flex-col border rounded-md overflow-x-scroll'>
          {loading ? (
            <SimpleLoader className='h-12 my-4' />
          ) : (
            <table className='table-auto w-full'>
              <thead>
                <tr className='border-b border-gray-200'>
                  <th className='px-4 py-2 text-center text-gray-500 font-normal'>
                    Status
                  </th>
                  <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                    Student
                  </th>
                  <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                    Title
                  </th>
                  <th className='px-4 py-2 text-left text-gray-500 font-normal'>
                    Evaluation
                  </th>
                  <th className='px-4 py-2 text-center text-gray-500 font-normal'>
                    Date
                  </th>
                  <th className='px-4 py-2 text-center text-gray-500 font-normal'>
                    Difficulty
                  </th>
                  <th className='px-4 py-2 text-center text-gray-500 font-normal'>
                    Class
                  </th>
                  <th className='px-4 py-2 text-center text-gray-500 font-normal'>
                    Letter
                  </th>
                </tr>
              </thead>
              <tbody>
                {submissionsToReview?.map((s) => (
                  <tr
                    key={s?.uuid}
                    className='border-b border-gray-200 even:bg-gray-100 cursor-pointer hover:bg-gray-300 text-sm'
                    onClick={() => {
                      navigate(`/submissions/${s?.uuid}`);
                    }}
                  >
                    <td
                      className={`px-4 py-2 text-center flex items-center ${
                        getStatus(s?.is_checking, s?.evaluation)?.statusColor
                      }`}
                    >
                      {getStatus(s?.is_checking, s?.evaluation)?.statusIcon ===
                        'check' && (
                        <CheckIcon className='h-4 pr-2 inline-block' />
                      )}
                      {getStatus(s?.is_checking, s?.evaluation)?.statusIcon ===
                        'clock' && (
                        <ClockIcon className='h-4 pr-2 inline-block' />
                      )}

                      <span>
                        {getStatus(s?.is_checking, s?.evaluation)?.status}
                      </span>
                    </td>
                    <td className='px-4 py-2 text-left text-blue-600'>
                      {s?.project_assignment?.student?.first_name +
                        ' ' +
                        s?.project_assignment?.student?.last_name}
                    </td>
                    <td className='px-4 py-2 text-left border-l'>
                      {s?.project_assignment?.project?.name}
                    </td>
                    <td className='px-4 py-2 text-center border-r italic'>
                      {getStatus(s?.is_checking, s?.evaluation)?.projectStatus}
                    </td>

                    <td className='px-4 py-2 text-center'>
                      {moment(s?.updated).format('MMM DD, yyyy')}
                    </td>
                    <td
                      className={`px-4 py-2 text-center  ${
                        s?.project_assignment?.project?.level === 'Hard' &&
                        'text-red-600 '
                      }`}
                    >
                      {s?.project_assignment?.project?.level}
                    </td>
                    <td className='px-4 py-2 text-center text-green-600'>
                      {1 % 2 === 1 ? '10' : '9'}
                    </td>
                    <td className='px-4 py-2 text-center text-green-600'>
                      {1 % 2 === 1 ? 'A' : 'B'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <Pagination
            currentPage={page}
            onPageChange={onPageChange}
            totalPages={count / PAGE_SIZE}
            count={count}
          />
        </div>
      </div>
    </div>
  );
}

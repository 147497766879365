import React, { useEffect, useState, useMemo } from 'react';
import { getPosts } from '../../../services/Feeds/FeedService';
import PostCard from './PostCard';
import SimpleLoader from '../../../components/Loader/SimpleLoader';

export default function Posts({ postCreated }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPosts()
      .then((res) => {
        setPosts(res.results);
      })
      .catch((err) => {
        console.log('error', err);
      })
      .finally(() => setLoading(false));
  }, [postCreated]);

  const memoizedPosts = useMemo(() => posts, [posts]);

  return (
    <div className='flex flex-col justify-start items-start gap-8'>
      {loading ? (
        <center>
          <SimpleLoader className='w-10 h-10' />
        </center>
      ) : (
        memoizedPosts.map((post) => <PostCard key={post.uuid} post={post} />)
      )}
    </div>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayIcon } from '@heroicons/react/24/solid';
import CircleProgressBar from '../../../../components/charts/CircleProgressBar';

export default function CourseCard({ data, refreshData }) {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col md:max-w-[18rem] w-full rounded overflow-hidden shadow-lg border'>
      <div className='p-4 inset-0 bg-blue-50'>
        <div className='font-bold text-xl mb-2'>{data?.name}</div>
        <p className='text-base'>
          Author:{' '}
          {data?.authors?.map((author, i) => {
            return (
              <span key={author.uuid}>
                {(!!i ? ', ' : '') +
                  author.author.first_name +
                  ' ' +
                  author.author?.last_name}
              </span>
            );
          })}
        </p>
      </div>
      <div className='flex flex-row justify-between border-t p-4 text-center bg-white'>
        <div className='flex flex-row gap-4'>
          <div>
            <div className='text-xl font-medium'>{data?.sections}</div>
            <div className='text-xs text-gray-600'>Sections</div>
          </div>
          <div>
            <div className='text-xl font-medium'>{data?.lessons}</div>
            <div className='text-xs text-gray-600'>Lessons</div>
          </div>
        </div>
        <div className='flex flex-row gap-1 items-center '>
          <span
            className='pl-1 rounded-full cursor-pointer'
            onClick={() => {
              navigate(`/courses/${data?.uuid}`);
            }}
          >
            {data.course_progress ? (
              <CircleProgressBar
                progress={(data.course_progress * 100).toFixed()}
              />
            ) : (
              <div className='relative w-11 h-11 bg-white rounded-full border-4 border-gray-300 hover:border-gray-400 focus:outline-none'>
                <PlayIcon className='w-5 h-5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
              </div>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

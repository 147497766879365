import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import {
  deleteResultDescriptionFile,
  patchResultDescriptionFile,
} from '../../../../services/tests/resultService';

import { Button } from '../../../../components/forms/Buttons/Button';
import {
  CheckIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Input from '../../../../components/forms/Inputs/Input';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Menu, Switch, Transition } from '@headlessui/react';
import { classNames } from '../../../../utils/helpers/helpers';

const FileCard = ({ file, refreshData, close }) => {
  const filesRef = useRef({});
  const [edit, setEdit] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    control,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    reset();
  }, [close, reset]);

  const handleFileChange = (e, file_lang) => {
    e.preventDefault();
    if (!window.confirm('Are you sure you want to update the file?')) {
      return;
    }

    if (e.target.files[0].type !== 'application/pdf') {
      toast.error(
        'Жүктелген файл PDF болуы керек! / Загружаемый вами файл должен быть PDF!',
        'error',
      );
      return;
    }
    if (e.target.files[0].size > 1048576) {
      toast.error(
        'Жүктелген файл 1 мегабайттан аспау керек! / Загружаемый вами файл не должен превышать 1 МБ',
        'error',
      );
      return;
    }
    let doc = e.target.files[0];
    const body = new FormData();
    switch (file_lang) {
      case 'en':
        body.append('file', doc);
        body.append('file_en_us', doc);
        break;

      case 'ru':
        body.append('file', doc);
        body.append('file_ru', doc);
        break;

      default:
        break;
    }

    patchResultDescriptionFile({ uuid: file.uuid, body })
      ?.then(() => {
        toast.success('File success updated');
        refreshData();
      })
      ?.catch(() => toast.error('File update error'));
  };

  const onSubmit = (data) => {
    patchResultDescriptionFile({
      uuid: file.uuid,
      body: { ...data, name_en_us: data.name },
    }).then(() => {
      refreshData();
    });
    setEdit(false);
  };

  const FILES_LANG = useMemo(
    () => [
      { key: 'en', link: file.file_en_us },
      { key: 'ru', link: file.file_ru },
    ],
    [file.file_en_us, file.file_ru],
  );
  return (
    <form
      className='flex flex-row gap-2 border p-4 rounded-lg items-end w-full justify-between'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-row gap-4 items-end'>
        <Input
          className='flex flex-col items-start'
          errorMsg={errors?.result?.message}
          required
          disabled={!edit}
          label='Name'
          {...register('name', {
            required: 'Name is required field',
            value: file.name,
          })}
        />
        <div className='flex flex-row items-center gap-2'>
          <Menu as='div' className='relative'>
            <Menu.Button
              disabled={!edit}
              className={`h-9 cursor-pointer ${
                edit
                  ? 'text-blue-500 hover:bg-primary bg-blue-100'
                  : 'text-slate-500 hover:bg-secondary bg-slate-100'
              } hover:text-white rounded-lg p-1.5 focus:outline-none`}
            >
              <DocumentArrowUpIcon className='h-6' aria-hidden='true' />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className=' ring-black absolute left-0 z-10 mt-2 min-w-fit max-w-fit origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none'>
                {FILES_LANG?.map((item) => (
                  <Menu.Item key={item.key}>
                    {({ active }) => (
                      <button
                        // href={item.href}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block w-full px-4 py-2 text-left text-sm font-semibold text-gray-700',
                        )}
                        onClick={() => filesRef.current[item.key].click()}
                      >
                        {item.key}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          {FILES_LANG?.map((item) => (
            <input
              disabled={!edit}
              hidden
              type='file'
              ref={(e) => (filesRef.current[item.key] = e)}
              onChange={(e) => handleFileChange(e, item.key)}
            />
          ))}

          <Menu as='div' className='relative'>
            <Menu.Button
              className={`h-9 cursor-pointer text-blue-500 hover:bg-primary bg-blue-100 
                hover:text-white rounded-lg p-1.5 focus:outline-none ${
                  FILES_LANG?.some((e) => e.link) ? '' : 'hidden'
                }`}
            >
              <DocumentArrowDownIcon className='h-6' />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className=' ring-black absolute left-0 z-10 mt-2 min-w-fit max-w-fit origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none'>
                {FILES_LANG?.filter((e) => e.link)?.map((item) => (
                  <Menu.Item key={item.key}>
                    {({ active }) => (
                      <button
                        // href={item.href}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block w-full px-4 py-2 text-left text-sm font-semibold text-gray-700',
                        )}
                        onClick={() => filesRef.current[item.key].click()}
                      >
                        <a href={item.link} target='_blank' rel='noreferrer'>
                          {item.key}
                        </a>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          <Controller
            control={control}
            name='add_screen'
            defaultValue={file.add_screen}
            render={({ field }) => (
              <Switch
                disabled={!edit}
                title={edit ? 'Screenshot' : 'Disable while not in edit mode'}
                checked={field.value}
                onChange={field.onChange}
                className={`${
                  field.value ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className='sr-only'>Enable screenshot</span>
                <span
                  className={`${
                    field.value ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            )}
          />
        </div>
      </div>

      <div className='flex flex-row gap-2'>
        {edit ? (
          <>
            <Button
              Icon={XMarkIcon}
              onClick={() => {
                setEdit(false);
              }}
              type='button'
            />
            <Button color='success' Icon={CheckIcon} type='submit' />
          </>
        ) : (
          <>
            <Button
              Icon={PencilSquareIcon}
              onClick={() => {
                setEdit(true);
              }}
              type='button'
            />
            <Button
              Icon={TrashIcon}
              color='error'
              onClick={() => {
                deleteResultDescriptionFile({
                  uuid: file.uuid,
                })?.then(() => {
                  refreshData();
                });
              }}
              type='button'
            />
          </>
        )}
      </div>
    </form>
  );
};

export default FileCard;

import bilAPI from '../api';

export const getSections = ({ uuid, course, order }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/sections/${uuid ? uuid + '/' : ''}`, {
          params: { course, order },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postSection = ({ name, description, course }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/sections/`, {
          name,
          description,
          course,
          order: 0, // order sets on backend, but need provide some value
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchSection = ({ uuid, name, description }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/sections/${uuid}/`, {
          name,
          description,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteSection = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/sections/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postIncreaseSectionOrder = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/sections/${uuid}/increase_order/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postDecreaseSectionOrder = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/sections/${uuid}/decrease_order/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

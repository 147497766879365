import { PuzzlePieceIcon } from '@heroicons/react/24/outline';
import React from 'react';

const ProjectContent = ({ data }) => {
  return (
    <p className='flex flex-col'>
      <span className='text-sm'>
        <a
          className='text-primary hover:underline flex flex-row gap-1'
          href={`/projects/${data.project?.uuid}`}
          target='_blank'
          rel='noreferrer'
        >
          <PuzzlePieceIcon className='h-5' />
          {`${data.project?.name}`}
        </a>
      </span>
    </p>
  );
};
export default ProjectContent;

import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../../components/forms/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import Sections from './Sections';
import { getLessons } from '../../../../services/courses/lessonService';
import Lessons from './Lessons';

const ContentCreater = ({ course, data, refreshData }) => {
  const [selected, setSelected] = useState();
  const [lessons, setLessons] = useState([]);
  const navigate = useNavigate();

  const getLessonsData = useCallback(() => {
    getLessons({ section: selected }).then((res) => {
      setLessons(res.results);
    });
  }, [selected]);

  useEffect(() => {
    if (!selected && data.length) {
      setSelected(data[0].uuid);
    }
  }, [data, selected]);

  useEffect(() => {
    if (selected) getLessonsData();
  }, [getLessonsData, selected]);

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row justify-between'>
        <Button
          text={'Back'}
          onClick={() =>
            navigate({
              pathname: '/courses/',
            })
          }
        />
        <span className='font-bold text-xl'>{course?.name}</span>
        <span />
      </div>
      <div className='flex flex-row justify-between pt-8'>
        <Sections
          className='absolute top-16 left-0'
          data={data}
          selected={selected}
          setSelected={setSelected}
          refreshData={refreshData}
          course={course}
        />
        {selected && (
          <Lessons
            data={lessons}
            section={selected}
            refreshData={getLessonsData}
          />
        )}
      </div>
    </div>
  );
};
export default ContentCreater;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import TestCard from './TestCard';
import Result from '../../Result/Result';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { ScrollContext } from '../../../../utils/contexts/ScrollContext';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './StudentTests.css';
import { useMediaQuery } from 'react-responsive';

const StudentTests = ({ data }) => {
  const { scrollRef } = useContext(ScrollContext);
  const [test, setTest] = useState();
  const [isVisible, setIsVisible] = useState(false);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const sliderSettings = {
    // dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SampleNextArrow />,
    // autoplay: true,
    autoplaySpeed: 3700,
    cssEase: 'ease-out',
  };

  const listenToScroll = useCallback(
    (a) => {
      const { scrollTop } = scrollRef?.current;
      let H = 200;
      if (scrollTop > H) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    },
    [scrollRef],
  );

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', listenToScroll);
    return () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollRef?.current?.removeEventListener('scroll', listenToScroll);
  }, [listenToScroll, scrollRef]);

  useEffect(() => {
    if (!test) setTest(data[0]?.uuid);
  }, [data, test]);

  return (
    <div className='flex flex-col p-6'>
      {isVisible && (
        <ArrowUpCircleIcon
          className={`h-10 bottom-10 left-10 absolute z-20 text-primary cursor-pointer animate-[bounce_1s_ease-in-out_2.5] `}
          onClick={() => {
            scrollRef?.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
              block: 'start',
            });
          }}
        />
      )}
      {isMobile ? (
        <div className='px-4'>
          <Slider {...sliderSettings}>
            {data?.map((t, i) => {
              return (
                <TestCard
                  key={t.uuid}
                  test={t}
                  i={i}
                  isSelected={test === t?.uuid}
                  select={() => setTest(t.uuid)}
                />
              );
            })}
          </Slider>
        </div>
      ) : (
        <div className='flex flex-row gap-4'>
          {data?.map((t, i) => {
            return (
              <TestCard
                key={t.uuid}
                test={t}
                i={i}
                isSelected={test === t?.uuid}
                select={() => setTest(t.uuid)}
              />
            );
          })}
        </div>
      )}
      <div className='w-full px-8 border mt-6' />

      {!!data?.length && <Result test={test} />}
    </div>
  );
};

export default StudentTests;

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext
} from 'react';
import { Reorder } from 'framer-motion';
import Title from './Title';
import { UserContext } from '../../utils/contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import { getInstructions, patchInstruction } from '../../services/instructions/instructionService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import Delete from './BREAD/Delete';
import Edit from './BREAD/Edit';
import { SidebarContext } from '../../utils/contexts/SidebarContext';
import InstructionsPreview from './InstructionPreview';

const PAGE_SIZE = 12;

export const Instructions = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const createFormLink = '/instructionCreate';
  const isAdmin = user?.roles?.some((r) => r?.name === 'ADMIN');

  const [page, setPage] = useState(1);
  const [N, setN] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [data, setData] = useState([]);
  const [datum, setDatum] = useState();

  const initializeOrder = async (instructions) => {
    const needsOrderUpdate = instructions.every((instr) => instr.order === 0);

    if (needsOrderUpdate) {
      const orderedInstructions = instructions.map((instr, index) => ({
        ...instr,
        order: index,
      }));

      setData(orderedInstructions);

      for (const instruction of orderedInstructions) {
        try {
          await patchInstruction(instruction.uuid, { order: instruction.order });
        } catch (error) {
          console.error('Error initializing instruction order:', error);
        }
      }
    } else {
      setData(instructions.sort((a, b) => a.order - b.order));
    }
  };

  const getData = useCallback(() => {
    setLoadingData(true);
    getInstructions({ page: 1, page_size: PAGE_SIZE })
      .then((res) => {
        initializeOrder(res?.results);
        setN(res?.count);
        setPage(1);
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  }, []);

  const getMoreData = () => {
    if (loadingMoreData || data?.length >= N) return;
    setLoadingMoreData(true);
    getInstructions({
      page_size: PAGE_SIZE,
      page: page + 1,
    })
      .then((res) => {
        setData((prev) => [
          ...prev,
          ...res?.results.sort((a, b) => a.order - b.order),
        ]);
        setPage((prev) => prev + 1);
      })
      .catch(() => {})
      .finally(() => setLoadingMoreData(false));
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 200) {
      if (data?.length < N) getMoreData();
    }
  };

  const updateOrderInBackend = async (updatedInstructions) => {
    for (let index = 0; index < updatedInstructions.length; index++) {
      const instruction = updatedInstructions[index];
      try {
        await patchInstruction(instruction.uuid, { order: index });
      } catch (error) {
        console.error('Error saving instruction order:', error);
      }
    }
  };

  const handleReorder = (updatedData) => {
    const reorderedData = updatedData.map((item, index) => ({
      ...item,
      order: index,
    }));
    setData(reorderedData);
    updateOrderInBackend(reorderedData);
  };

  return (
    <div
      onScroll={handleScroll}
      className="flex flex-col gap-4 lg:text-sm md:text-sm text-xs 
        h-[88vh] overflow-y-auto w-full p-4"
    >
      <Title />
      {isAdmin && (
        <div>
          <Button
            color={'success'}
            text={'+ Create Instruction'}
            onClick={() => {
              navigate(createFormLink);
            }}
          />
        </div>
      )}
      {loadingData ? (
        <div>
          <SimpleLoader className={'w-10'} />
        </div>
      ) : data?.length ? (
        isAdmin ? (
          <Reorder.Group
            axis="y"
            values={data}
            onReorder={handleReorder}
            className="flex flex-col gap-4"
          >
            {data?.map((d, i) => (
              <Fragment key={d.uuid}>
                {!!i && <div className="w-full border-b" />}
                <Reorder.Item value={d}>
                  <div className="flex flex-row justify-between">
                    <InstructionsPreview instruction={d} />
                    {isAdmin && (
                      <div className="flex flex-col gap-2">
                        <Button
                          text={'Edit'}
                          onClick={() => {
                            setDatum(d);
                            setId(uuid);
                          }}
                        />
                        <Delete uuid={d?.uuid} refreshData={getData} />
                      </div>
                    )}
                  </div>
                </Reorder.Item>
              </Fragment>
            ))}
          </Reorder.Group>
        ) : (
          <div className="flex flex-col gap-4">
            {data?.map((d, i) => (
              <Fragment key={d.uuid}>
                {!!i && <div className="w-full border-b" />}
                <div className="flex flex-row justify-between">
                  <InstructionsPreview instruction={d} />
                </div>
              </Fragment>
            ))}
          </div>
        )
      ) : (
        <div className="flex flex-col items-center pt-8 text-lg text-gray-500 italic">
          No instructions
        </div>
      )}
      <Edit
        refreshData={getData}
        setDatum={setDatum}
        setId={setId}
        uuid={uuid}
        datum={datum}
      />
    </div>
  );
};
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getParameters } from '../../../services/parameters/parametersService';
import { getSkills } from '../../../services/projects/skillsService';
import { postProject } from '../../../services/projects/projectService';
import { Button } from '../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import ContentPart from './ContentPart';
import ImagePart from './ImagePart';
import { UserContext } from '../../../utils/contexts/UserContext';
import { useNavigate } from 'react-router-dom';

export default function CreateProject() {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [levels, setLevels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  // const [tags] = useState([]);
  const [bgImageUrl, setBgImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  // const fileRef = useRef(null);
  const [isImagePart, setIsImagePart] = useState(false);
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    getParameters({ category: 'level' }).then((res) => {
      setLevels(res?.results?.map((l) => ({ label: l?.name, value: l?.uuid })));
    });

    getSkills({ uuid: null }).then((res) => {
      setSubjects(
        res?.results
          ?.filter((s) => s?.level === 3 || s?.name === 'Programming')
          ?.map((s) => ({ label: s?.name, value: s?.uuid })),
      );
    });

    return () => {};
  }, []);

  const handleCreateProject = async () => {
    if (body && bgImageUrl) {
      setLoading(true);

      const formData = new FormData();

      for (var key in body) {
        if (body[key] && body[key] !== 'not_in') {
          formData.append(key, body[key]);
        }
      }
      formData.append('ai_img_url', bgImageUrl);

      postProject(formData)
        .then(() => {
          setLoading(false);
          toast.success('Success');
        })
        .catch((err) => {
          setLoading(false);
          toast.error('error');
        })
        .finally(() => {
          setLoading(false);
          navigate(-1);
        });
    } else {
      toast.error('Please select image');
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className='mx-2 md:m-0'>
      <CustomDialog
        headerText={t('Information')}
        isOpen={open}
        closeModal={() => onClose()}
        // ConfirmType='success'
        // confirmText={t('buttons.create')}
      >
        <p>
          <span className=' font-semibold'>Title:</span> Provide a clear and
          descriptive title for your project. It should convey the main topic or
          theme of the project in a concise manner. <br />
          <br />
          <span className=' font-semibold'>Subject:</span> Specify the subject
          that your project will focus on. Select appropriate one from given
          options. <br />
          <br />
          <span className=' font-semibold'>Level:</span> Determine the
          appropriate level of difficulty for your project based on the target
          audience (high school students). Choose from the options: Beginner,
          Easy, Medium, or Hard. <br />
          <br />
          <span className=' font-semibold'>Description:</span> Write a detailed
          description of your project. Explain the objectives, goals, and main
          activities involved. Describe how the project will benefit the
          students and why it's relevant and engaging.
          <br />
          <br />
          <span className=' font-semibold'>
            Essential Tools / Resources:
          </span>{' '}
          List all the necessary tools, materials, and resources required to
          complete the project. Ensure that they are accessible to students
          participating in the project. <br />
          <br />
          <span className=' font-semibold'>Roadmap with Tasks:</span> Create a
          step-by-step roadmap outlining the tasks and activities that students
          will undertake throughout the project. Each task should be clearly
          defined and sequenced in a logical order. <br />
          <br />
          <span className=' font-semibold'>Evaluation Criteria:</span> Define
          the criteria by which you will assess and evaluate the students'
          performance and project outcomes. This could include research
          accuracy, presentation quality, creativity, organization, etc. <br />
          <br />
          <span className=' font-semibold'>Tags:</span> Choose relevant keywords
          or tags that describe the main topics, themes, and concepts covered in
          your project. These tags will help others easily identify and search
          for your project.
        </p>
        <div>
          <Button type='button' text='Accept' onClick={() => onClose()} />
        </div>
      </CustomDialog>
      <div className='flex flex-row w-full gap-2 mb-4'>
        <h1 className=' text-xl font-semibold'>Project Constructor</h1>
        <Button
          text='Instructions'
          Icon={InformationCircleIcon}
          className='max-h-8'
          onClick={() => {
            setOpen(true);
          }}
        />
        {/* <button
          className={`h-8 w-8 p-1 rounded-md group relative text-center inline-flex items-center transition duration-100 ease-in disabled:cursor-default bg-yellow-600 text-white hover:bg-yellow-700`}
          >
          <InformationCircleIcon className='h-6' />
        </button> */}
      </div>
      {user?.roles?.some((role) => role?.name === 'STUDENT') && (
        <p className='italic mb-2'>
          <span className='text-red-600'>*</span> By default we put your
          preferred subject and knowledge level, but you can change them
        </p>
      )}
      <div className='flex-col border shadow-md rounded-md p-4'>
        {!isImagePart ? (
          <ContentPart
            setBody={setBody}
            content={content}
            setContent={setContent}
            setIsImagePart={setIsImagePart}
            levels={levels}
            subjects={subjects}
          />
        ) : (
          <ImagePart
            content={content}
            loading={loading}
            setIsImagePart={setIsImagePart}
            bgImageUrl={bgImageUrl}
            setBgImageUrl={setBgImageUrl}
            handleCreateProject={handleCreateProject}
          />
        )}
      </div>
    </div>
  );
}

import bilAPI from '../api';

export const getDraftRoadmaps = ({ author }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/draft_roadmaps/`, { params: { author } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteDraftRoadmap = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/roadmap/draft_roadmaps/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getRoadmaps = ({ student, page, page_size, assignor, group }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/roadmaps/`, {
          params: {
            student,
            page,
            page_size,
            assignor,
            group,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getRoadmap = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/roadmaps/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postRoadmap = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/roadmap/roadmaps/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchRoadmap = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/roadmap/roadmaps/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteRoadmap = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/roadmap/roadmaps/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import moment from 'moment';

  export const formatDate = (dateString) => {
      const momentDate = moment(dateString)
      const today = moment().format('YYYY-MM-DD');
    
        if(momentDate.isSame(today, 'day')){
          return `Today ${momentDate.format('HH:mm')}`
      } else {
        return momentDate.format('DD.MM.YYYY')
      }
    }


import React from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { deleteQuestion } from '../../../../../services/tests/questionService';
import { toast } from 'react-toastify';

const Delete = ({ question, getData, isDeleteMode, setIsDeleteMode }) => {
  const deleteQuestionHandler = () => {
    deleteQuestion(question)
      ?.then(() => {
        toast.success('Success deleted');
        getData();
      })

      ?.catch((e) => {
        toast.error('error');
        return;
      })
      ?.finally(() => {});
  };

  return (
    <>
      {isDeleteMode && (
        <Button
          Icon={CheckIcon}
          className='rounded-r-none '
          color='error-solid'
          onClick={() => {
            deleteQuestionHandler();
            setIsDeleteMode(false);
          }}
        />
      )}
      <Button
        Icon={isDeleteMode ? XMarkIcon : TrashIcon}
        className={`rounded-l-none `}
        color={isDeleteMode ? 'primary-solid' : 'error'}
        onClick={() => setIsDeleteMode((v) => !v)}
      />
    </>
  );
};

export default Delete;

export const PROJECT_METHODS = [
  { label: 'Experiment based research/review', value: 1 },
  { label: 'Data based research/review', value: 2 },
  { label: 'Design/construction', value: 3 },
  { label: 'Product based Project', value: 4 },
  { label: 'Event based Project', value: 5 },
  { label: 'Reading/Writing', value: 6 },
  { label: 'Problem-solving', value: 7 },
];

export const FILTER_SELECT_STYLE = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#f2f3f4',
    // borderWidth: '0.125rem',
    borderRadius: '0.375rem',
    opacity: state.isDisabled ? 0.5 : 1,
    minHeight: '2rem',
    height: '2rem',
    minWidth: '8rem',
    // width: '8rem',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // paddingLeft: '1rem',
    // height: '10px',
    fontSize: '12px',
    height: '2rem',
    padding: '0 6px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    // paddingRight: '0.5rem',
    height: '2rem',
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#374151',
    };
  },
};

import React from 'react';
import AddLesson from './BREAD/AddLesson';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import {
  postDecreaseLessonOrder,
  postIncreaseLessonOrder,
} from '../../../../services/courses/lessonService';
import DeleteLesson from './BREAD/DeleteLesson';
import EditLesson from './BREAD/EditLesson';
import { useNavigate } from 'react-router-dom';

const Lessons = ({ data, refreshData, section }) => {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col border rounded-2xl shadow-xl hover:shadow-2xl h-min w-7/12 mt-8'>
      {data.map((d) => {
        return (
          <div
            key={d.uuid}
            className='flex flex-row justify-between py-2 px-8 border-b-2 last:border-b-0 items-center'
          >
            <div className='flex flex-col gap-2'>
              <ChevronUpIcon
                className={`h-5 cursor-pointer hover:opacity-40 ${
                  d.order === 1 && 'invisible'
                }`}
                onClick={() => {
                  postIncreaseLessonOrder(d.uuid).then(() => {
                    refreshData();
                  });
                }}
              />
              <ChevronDownIcon
                className={`h-5 cursor-pointer hover:opacity-40 ${
                  d.order === data.length && 'invisible'
                }`}
                onClick={() => {
                  postDecreaseLessonOrder(d.uuid).then(() => {
                    refreshData();
                  });
                }}
              />
            </div>
            <p
              className='flex flex-col max-w-max pl-4 text-left text-sm truncate'
              title={d.name}
            >
              {d.name}
            </p>
            <div className='flex flex-row gap-2 min-w-fit pl-4'>
              <EyeIcon
                className='text-primary h-9 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-2'
                onClick={() =>
                  navigate({
                    pathname: `lesson/${d.uuid}`,
                  })
                }
              />
              <EditLesson lesson={d} refreshData={refreshData} />
              <DeleteLesson lesson={d} data={data} refreshData={refreshData} />
            </div>
          </div>
        );
      })}
      <div className='h-14 flex flex-row justify-center items-center'>
        <AddLesson refreshData={refreshData} section={section} />
      </div>
    </div>
  );
};

export default Lessons;

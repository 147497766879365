import { DocumentIcon } from '@heroicons/react/24/outline';
import React from 'react';

const FileContent = ({ data }) => {
  let file = data.file.replace(`${data.uuid}_`, '').split('/');
  return (
    <p className='flex flex-col'>
      <span>{'File Content'}</span>
      <span className='text-sm'>
        <a
          className='text-primary hover:underline flex flex-row gap-1'
          href={data.file}
          target='_blank'
          rel='noreferrer'
        >
          <DocumentIcon className='h-5' />
          {file[file.length - 1]}
        </a>
      </span>
    </p>
  );
};
export default FileContent;

import {
  ChevronDownIcon,
  GlobeAltIcon,
  HomeIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { useState, useContext } from 'react';
import { getLocalFaculty } from '../../../../services/universities/localUniversitiesService';
import FacultyCard from './FacultyCard';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { ScrollContext } from '../../../../utils/contexts/ScrollContext';

export default function UniversityCard({ university, params }) {
  const [t] = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { scrollRef } = useContext(ScrollContext);

  const [open, setOpen] = useState(false);
  const [faculties, setFaculties] = useState([]);
  const onClick = () => {
    setOpen((prev) => {
      if (!prev) {
        getLocalFaculty(university.id, params)?.then((res) => {
          setFaculties(res.data);
        });
      }
      return !prev;
    });
  };
  return (
    <div className='px-4 p-2 border rounded-lg flex flex-col gap-2'>
      <p className='flex flex-row md:hidden' onClick={onClick}>
        <span>{university.title_ru}</span>
      </p>
      <div
        className='flex flex-row gap-2 md:gap-4 text-xs lg:text-base md:text-sm cursor-pointer'
        onClick={onClick}
      >
        <p className='hidden md:flex flex-row w-1/3'>
          <span>{university.title_ru}</span>
        </p>
        <p
          className='flex flex-row items-center gap-0.5 w-1/12'
          title={t('universities.local.code')}
        >
          <span className='text-primary italic'>#</span>{' '}
          <span>{university.id}</span>
        </p>
        <p
          className='flex flex-row items-center gap-0.5 w-1/3 md:w-1/4'
          title={t('universities.local.location')}
        >
          <MapPinIcon className='min-w-4 min-w-fit h-4 text-primary' />
          <span className='first-letter:uppercase lowercase'>
            {university.region_ru?.replace('Г.', '')?.replace('ОБЛАСТЬ', '.об')}
          </span>
        </p>
        <p
          className='flex flex-row items-center gap-0.5 w-1/3 md:w-1/6'
          title={t('universities.local.website')}
        >
          <GlobeAltIcon className='h-4 w-4 min-w-fit text-primary' />
          <a
            href={university.url}
            className='text-primary hover:underline'
            target='_blank'
            rel='noopener noreferrer'
          >
            {university.url
              ?.replace('https://', '')
              ?.replace('http://', '')
              ?.replace('www.', '')}
          </a>
        </p>
        <p
          className='flex flex-row items-center gap-0.5 w-1/6'
          title={t('universities.local.dormitory')}
        >
          <HomeIcon className='h-4 w-4 min-w-fit text-primary' />
          <span>{university.is_hostel_included ? 'есть' : 'нет'}</span>
        </p>
        <div className='w-1/6 flex flex-col justify-center'>
          <ChevronDownIcon className={`${open ? '' : '-rotate-90'} w-5 h-5`} />
        </div>
      </div>
      <div
        className={`${
          open ? 'h-fit' : 'h-0 overflow-hidden'
        } md:overflow-hidden overflow-x-scroll`}
      >
        <div className='flex flex-row min-w-fit bg-blue-100 p-2 rounded-lg lg:text-base md:text-sm text-xs'>
          <span className='w-1/3 min-w-[10rem]'>Название</span>
          <span className='w-1/6 min-w-[5rem] text-center'>Код</span>
          <span className='w-1/6 min-w-[5rem] text-center'>Проходной</span>
          <span className='w-1/6 min-w-[5rem] text-center'>Грант балл</span>
          <span className='w-1/6 min-w-[5rem] text-center'>
            Проходной грант универ
          </span>
          <span className='w-1/6 min-w-[5rem] text-center'>Оплата за год</span>
        </div>
        {faculties?.map((faculty) => {
          return (
            <FacultyCard
              universityId={university.id}
              faculty={faculty}
              params={params}
            />
          );
        })}
      </div>
    </div>
  );
}

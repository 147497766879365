import React from 'react';
import TextContent from './TextContent';
import VideoContent from './VideoContent';
import ProjectContent from './ProjectContent';
import QuestionContent from './QuestionContent';
import FileContent from './FileContent';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  deleteContent,
  postDecreaseContentOrder,
  postIncreaseContentOrder,
} from '../../../../../services/courses/contentService';
import Edit from '../BREAD/Edit';
import EditQuestion from '../BREAD/EditQuestion';

const Content = ({ data, refreshData, contentCount }) => {
  return (
    <div className='border p-8 rounded-2xl relative'>
      <div className='flex flex-row gap-2 absolute right-5 top-5'>
        {data?.question_content ? (
          <EditQuestion refreshData={refreshData} data={data} />
        ) : (
          <Edit refreshData={refreshData} data={data} />
        )}
        <TrashIcon
          className='text-red-500 h-9 cursor-pointer hover:bg-error bg-red-100 hover:text-white rounded-lg p-2'
          onClick={() => {
            let is_confimed = window.confirm(
              `Are you sure you want to delete content?`,
            );
            if (!is_confimed) {
              return;
            }
            deleteContent(data.uuid).then(() => {
              refreshData();
            });
          }}
        />
      </div>

      <div className='flex flex-row gap-4'>
        <div className='flex flex-col gap-2'>
          <ChevronUpIcon
            className={`h-5 cursor-pointer hover:opacity-40 ${
              data.order === 1 && 'invisible'
            }`}
            onClick={() => {
              postIncreaseContentOrder(data.uuid).then(() => {
                refreshData();
              });
            }}
          />
          <ChevronDownIcon
            className={`h-5 cursor-pointer hover:opacity-40 ${
              data.order === contentCount && 'invisible'
            }`}
            onClick={() => {
              postDecreaseContentOrder(data.uuid).then(() => {
                refreshData();
              });
            }}
          />
        </div>
        {data?.text_content && <TextContent data={data.text_content} />}
        {data?.video_content && <VideoContent data={data.video_content} />}
        {data?.project_content && (
          <ProjectContent data={data.project_content} />
        )}
        {data?.question_content && (
          <QuestionContent data={data.question_content} />
        )}
        {data?.file_content && <FileContent data={data.file_content} />}
      </div>
    </div>
  );
};
export default Content;

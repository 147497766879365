import axios from 'axios';

export const getLocalUniversies = ({
  firstSubject,
  secondSubject,
  score,
  isGrant,
}) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          'https://edu-test-iam-service.azurewebsites.net/api/recommendation-system/',
          {
            params: {
              firstSubjectId: firstSubject,
              secondSubjectId: secondSubject,
              point: score,
              isGrant,
            },
          },
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getLocalFaculty = (
  universityId,
  { firstSubject, secondSubject, score, isGrant },
) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          `https://edu-test-iam-service.azurewebsites.net/api/recommendation-system/${universityId}/`,
          {
            params: {
              firstSubjectId: firstSubject,
              secondSubjectId: secondSubject,
              point: score,
              isGrant,
            },
          },
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
export const getLocalSpeciality = (
  universityId,
  facultyId,
  { firstSubject, secondSubject, score, isGrant },
) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(
          `https://edu-test-iam-service.azurewebsites.net/api/recommendation-system/${universityId}/${facultyId}/`,
          {
            params: {
              firstSubjectId: firstSubject,
              secondSubjectId: secondSubject,
              point: score,
              isGrant,
            },
          },
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

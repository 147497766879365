import React, { useCallback, useEffect, useRef, useState } from 'react';
import Result from '../../../Tests/Result/Result';
import { formatDate } from '../../../../utils/helpers/helpers';
import { getResults } from '../../../../services/tests/resultService';

export default function TestResults({ studentUuid }) {
  const ref = useRef();
  const [allResults, setAllResults] = useState([]);
  const [results, setResults] = useState([]);
  const [test, setTest] = useState();

  const getTestsData = useCallback(() => {
    getResults({ user: studentUuid }).then((res) => {
      let allResults = {};
      setResults(
        res.results?.reduce((acc, d) => {
          if (!allResults[d.test.uuid]) {
            allResults[d.test.uuid] = 1;
            return acc.concat(d);
          }
          allResults[d.test.uuid] += 1;
          return acc;
        }, []),
      );
      setAllResults(allResults);
      if (res.count) {
        setTest(res.results[0].test.uuid);
      }
    });
  }, [studentUuid]);

  useEffect(() => {
    getTestsData();
  }, [getTestsData]);

  return (
    <div className='flex flex-col p-6' ref={ref}>
      <div className='flex flex-col gap-12 top-0 sticky bg-white z-10'>
        <div className='flex flex-row gap-2'>
          {results?.length ? (
            results?.map((r, i) => {
              let selected = test === r.test.uuid;
              return (
                <div
                  className='flex flex-col border rounded-lg mt-2 max-w-fit min-w-[12rem]'
                  key={r.uuid}
                >
                  <div
                    className={`relative p-4 pb-2 ${
                      selected ? 'bg-blue-200' : 'bg-slate-100 cursor-pointer'
                    }`}
                    onClick={() => {
                      if (selected) return;
                      setTest(r.test.uuid);
                    }}
                  >
                    {`${i + 1}. ${r.test.name}`}
                  </div>
                  <div className='flex flex-col gap-1 text-sm border-t p-4 pt-2'>
                    <>
                      <span>Result: {r.result.result}</span>
                      <span>Last attempt: {formatDate(r.created)}</span>
                      <span>Attempts: {allResults[r.test.uuid]}</span>
                    </>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='w-full flex flex-row justify-center text-gray-400 text-lg'>
              No content
            </div>
          )}
        </div>
        <div className='w-full px-8 border' />
      </div>

      {!!test && <Result test={test} userUUID={studentUuid} toScroll={ref} />}
    </div>
  );
}

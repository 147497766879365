import React from 'react';
import { useTranslation } from 'react-i18next';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru/index.js';
import kk from 'date-fns/locale/kk/index.js';
import enUS from 'date-fns/locale/en-US/index.js';
import Select from '../forms/Select/Select';
import FlagEN from '../Icons/FlagEN';
import FlagRU from '../Icons/FlagRU';

export const systemLanguageOptions = [
  // {
  //   key: 'kk',
  //   label: '🇰🇿 Қазақша',
  //   value: 'kk',
  //   flag: 'kz',
  // },
  {
    key: 'en',
    label: 'Eng',
    value: 'en-US',
    flag: 'gb',
    Icon: FlagEN,
  },
  {
    key: 'ru',
    label: 'Руc',
    value: 'ru',
    flag: 'ru',
    Icon: FlagRU,
  },
];

export default function LanguageDropdown({ reloadOnChange = true }) {
  const [, i18n] = useTranslation();
  registerLocale('ru', ru);
  registerLocale('kk', kk);
  registerLocale('en', enUS);

  function handleLanguageChange(event) {
    i18n.changeLanguage(event.value);
    setDefaultLocale(event.value);
    if (reloadOnChange) {
      window.location.reload();
    }
  }

  return (
    <div style={{ fontFamily: 'Segoe UI Emoji' }}>
      <Select
        className='z-40 w-32'
        style
        options={systemLanguageOptions}
        onChange={handleLanguageChange}
        value={i18n.language}
      />
    </div>
  );
}

import React from 'react';
import Plyr from 'plyr-react';
import './plyr.css';

const VideoContent = ({ data, refreshData }) => {
  const videoSrc = {
    type: 'video',
    sources: [
      {
        src: data.url,
        provider: 'youtube',
      },
    ],
  };

  const options = {
    autoplay: false,
    playsinline: true,
  };

  return (
    <div className='px-4 sm:px-8 md:px-12 lg:px-[8rem]'>
      <Plyr source={videoSrc} options={options} />
    </div>
  );
};
export default VideoContent;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import Description from './Description';
// import Roadmap from './OldRoadmap/Roadmap';
import MyRoadmap from './MyRoadmap/MyRoadmap';
import { UserContext } from '../../utils/contexts/UserContext';
import { getRoadmaps } from '../../services/roadmapTask/roadmapService';
import MyRoadmaps from './MyRoadmaps/MyRoadmaps';
import { Button } from '../../components/forms/Buttons/Button';
import Roadmaps from './Roadmaps/Roadmaps';
import { useLocation } from 'react-router-dom';

export default function RoadmapMain({ studentUuid }) {
  const { user } = useContext(UserContext);
  const { state } = useLocation();

  const isTeacher = user?.roles?.every((r) => r.name !== 'STUDENT');
  // const [tab, setTab] = useState(state?.tab === 'roadmap' ? 0 : 1);
  const [tab, setTab] = useState(0);
  const [roadmap, setRoadmap] = useState();

  const getRoadmapData = useCallback(() => {
    getRoadmaps({
      student: studentUuid ?? user?.uuid,
    })
      .then((res) => {
        setRoadmap(res?.results[0]);
      })
      .catch(() => {});
    //   .finally(() => setLoading(false));
  }, [studentUuid, user?.uuid]);

  useEffect(() => {
    getRoadmapData();
  }, [getRoadmapData]);

  useEffect(() => {
    // if (isTeacher || studentUuid || roadmap) {
    if (state?.tab && window.location.pathname === '/roadmap') {
      setTab(state?.tab);
    }
  }, [state?.tab]);

  return (
    <div className='flex flex-col gap-4 p-4 h-full'>
      <Description />
      {isTeacher && !studentUuid && (
        <div className='flex flex-row gap-2'>
          <Button
            color={tab === 0 && 'primary'}
            text='Roadmaps'
            onClick={() => setTab(0)}
          />
          <Button
            color={tab === 1 && 'primary'}
            text='My Roadmaps'
            onClick={() => setTab(1)}
          />
        </div>
      )}
      <div className='flex lg:flex-row flex-col h-full'>
        {/* <div
          className='flex flex-row rounded-2xl border overflow-hidden min-h-[3rem]
                        lg:flex-col lg:w-1/5 lg:h-full lg:py-4'
        >
          {(isTeacher || studentUuid || roadmap) && (
            <div
              className={`flex flex-row border-l-4 items-center gap-1 px-4 p-2 lg:h-auto h-12 lg:text-lg text-sm hover:bg-slate-100 ${
                tab === 0
                  ? 'bg-slate-100 border-current text-primary'
                  : 'border-transparent cursor-pointer'
              }`}
              onClick={() => setTab(0)}
            >
              <MyRoadmapIcon className='fill-current lg:h-6 h-5' />
              {userProfile
                ? `${userProfile.first_name}'s Roadmap`
                : 'My Roadmap'}
            </div>
          )}
          <div
            className={`flex flex-row lg:border-l-4 border-l-[6px] items-center gap-1 px-4 p-2 lg:h-auto h-12 lg:text-lg text-sm hover:bg-slate-100 ${
              tab === 1
                ? 'bg-slate-100 border-current text-primary'
                : 'border-transparent cursor-pointer'
            }`}
            onClick={() => setTab(1)}
          >
            <MyTasksIcon className='fill-current lg:h-6 h-5' />
            {userProfile ? `${userProfile.first_name}'s Tasks` : 'My Tasks'}
          </div>
        </div> */}
        <div className='overflow-scroll w-full'>
          {tab === 0 &&
            (isTeacher && !studentUuid ? (
              <Roadmaps />
            ) : (
              <MyRoadmap roadmap={roadmap} setRoadmap={setRoadmap} />
            ))}
          {/* {tab === 1 && <Roadmap studentUuid={studentUuid} />} */}
          {tab === 1 && isTeacher && <MyRoadmaps />}
        </div>
      </div>
    </div>
  );
}

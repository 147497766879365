import React, { useState } from 'react';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../../../components/forms/Inputs/Input';
import { toast } from 'react-toastify';
import { Button } from '../../../../../components/forms/Buttons/Button';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import Select from '../../../../../components/forms/Select/Select';
import {
  postQuestion,
  postScaleContent,
} from '../../../../../services/tests/questionService';
import TestScale from '../../../../../components/Scales/TestScale/TestScale';

const Add = ({
  questionId,
  className,
  test,
  getData,
  questionTypes,
  typeIndex,
}) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    formState: { errors },
    control,
    clearErrors,
    handleSubmit,
    reset,
    register,
    watch,
  } = useForm();

  const onClose = () => {
    setOpen(false);
    clearErrors();
    reset({ text: '' });
  };

  const onSubmit = (data) => {
    postQuestion({ text: data.text, type: data.type, test: test.uuid })
      ?.then((res) => {
        toast.success('success created Question');
        onClose();
        if (data.content_type === 0) {
          postScaleContent({
            question: res.uuid,
            min: -3,
            max: 3,
            minValue: data.minValue,
            maxValue: data.maxValue,
          })
            ?.then(() => {
              toast.success('success created Scale content');
            })
            ?.catch((e) => {
              toast.error('error Scale content');
              return;
            });
        }
      })

      ?.catch((e) => {
        toast.error('error Question');
        return;
      })

      ?.finally(() => getData());
  };

  return (
    <>
      <Button
        text={'add'}
        Icon={PlusSmallIcon}
        className={className}
        onClick={() => setOpen(true)}
      />
      <CustomDialog
        headerText={`# ${questionId}`}
        isOpen={open}
        closeModal={() => onClose()}
        ConfirmType='success'
        confirmText={t('buttons.create')}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-2 w-full items-start p-4'
        >
          <div className='flex flex-col items-start'>
            <label className='block text-sm font-medium text-gray-700 text-left'>
              {'type'}
              <span className={`text-error`}>{'*'}</span>
            </label>
            <Controller
              control={control}
              rules={{
                required: 'type field is required',
              }}
              name='type'
              render={({ field }) => (
                <Select
                  value={field.value}
                  onChange={(v) => {
                    field.onChange(v?.value);
                  }}
                  options={questionTypes?.map((q, i) => ({
                    label: q,
                    value: i + typeIndex,
                  }))}
                  className='w-80'
                />
              )}
            />
            {errors?.type?.message && (
              <span className='mt-1 text-left text-error'>
                {errors?.type?.message}
              </span>
            )}
          </div>
          <div className='flex flex-col items-start'>
            <label className='block text-sm font-medium text-gray-700 text-left'>
              {'content type'}
              <span className={`text-error`}>{'*'}</span>
            </label>
            <Controller
              control={control}
              rules={{
                required: 'content type field is required',
              }}
              name='content_type'
              render={({ field }) => (
                <Select
                  value={field.value}
                  onChange={(v) => {
                    field.onChange(v?.value);
                  }}
                  options={[{ label: 'Scale', value: 0 }]}
                  className='w-80'
                />
              )}
            />
            {errors?.type?.message && (
              <span className='mt-1 text-left text-error'>
                {errors?.type?.message}
              </span>
            )}
          </div>
          <div className='w-full'>
            <Input
              errorMsg={errors?.text?.message}
              {...register('text', {
                required: 'text field is required',
              })}
              label={'text'}
            />
          </div>
          {watch('content_type') === 0 && (
            <>
              <Input
                label={'Min value description'}
                {...register('minValue', {
                  required: 'min value description required',
                  value: test?.type ? 'Disagree' : '',
                })}
                errorMsg={errors?.minValue?.message}
                disabled={test?.type}
              />
              <Input
                label={'Max value description'}
                {...register('maxValue', {
                  required: 'max value description required',
                  value: test?.type ? 'Agree' : '',
                })}
                disabled={test?.type}
                errorMsg={errors?.maxValue?.message}
              />
              <TestScale
                min={-3}
                max={3}
                minValue={test?.type ? 'Disagree' : watch('minValue')}
                maxValue={test?.type ? 'Agree' : watch('maxValue')}
                disabled
                startSize={10}
                className='text-xs'
              />
            </>
          )}
          <div className='flex flex-row justify-end gap-2 w-full'>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              type='button'
              onClick={() => onClose()}
            />
            <Button color='success' text={t('buttons.create')} type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Add;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDate } from './FormatDate';
import { Button } from '../../components/forms/Buttons/Button';
import { toast } from 'react-toastify';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { UserContext } from '../../utils/contexts/UserContext';
import { EditModalComponent } from './EditModalComponent';
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { deletePost, getPost } from '../../services/Feeds/FeedService';
import RichTextEditor from '../../components/forms/Inputs/RichTextEditor';

const PostsPage = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [pinLoading, setPinLoading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { uuid } = useParams();

  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const getPostData = useCallback(() => {
    setLoading(true);
    getPost(uuid)
      .then((res) => {
        setPost(res);
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => setLoading(false));
  }, [uuid]);

  useEffect(() => {
    getPostData();
  }, [getPostData, user.uuid, uuid]);

  const handleDeleteConfirmation = () => {
    toast.dark(
      <>
        <div>Вы точно хотите удалить пост?</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            autoFocus
            text='Да'
            color='error-solid'
            onClick={handleDelete}
          />
          <Button text='Нет' className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  function handleDelete() {
    deletePost(uuid)
      .then((res) => {
        console.log('deleted', res);
        navigate('/feed');
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  function handleEditPost() {
    setIsEditModalOpen(true);
  }

  const updatePost = (updatedPost) => {
    setPost((prevPosts) => {
      if (prevPosts && prevPosts instanceof Array) {
        return prevPosts.map((post) => {
          if (post.uuid === updatedPost.uuid) {
            return updatedPost;
          } else {
            return post;
          }
        });
      } else {
        return updatedPost;
      }
    });
  };

  // function handlePinToggle() {
  //   setPinLoading(true);
  //   pinPost(post.uuid)
  //     .then(() => {
  //       getPostData();
  //     })
  //     .catch((error) => {
  //       console.log('error', error);
  //     })
  //     .finally(() => setPinLoading(false));
  // }

  if (loading) {
    return <SimpleLoader className={'w-10 flex justify-center items-center'} />;
  }

  return (
    post && (
      <div className='flex flex-row justify-center p-4 md:p-0'>
        <div className='flex flex-col max-w-3xl justify-start items-start border mt-2 border-gray-200 p-4 rounded-sm'>
          <div className='flex flex-row w-full justify-between items-center'>
            <div className='flex flex-row w-full justify-start items-center gap-2'>
              <img
                src={
                  post.author_info.profile_picture ||
                  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                }
                alt='profileImage'
                className='w-10 h-10 rounded-sm'
              />
              <span>
                {post.author_info.first_name} {post.author_info.last_name}
              </span>
              <span>|</span>
              <span className='text-xs italic'>{formatDate(post.created)}</span>
            </div>
            <div className='flex flex-row justify-end items-center w-full gap-1'>
              {/* <Button
                loading={pinLoading}
                text={post.is_pinned ? 'Unpin post' : 'Pin post'}
                onClick={() => handlePinToggle()}
                disabled={pinLoading}
              /> */}

              {post.author_info.uuid === user.uuid && (
                <>
                  <PencilSquareIcon
                    onClick={handleEditPost}
                    className='h-8 text-blue-500 hover:bg-blue-200 p-1 rounded-full cursor-pointer'
                  />
                  <TrashIcon
                    onClick={handleDeleteConfirmation}
                    className='h-8 text-red-500 hover:bg-red-200 p-1 rounded-full cursor-pointer'
                  />
                </>
              )}
            </div>
          </div>
          <p className='text-2xl font-medium mt-4 mx-4'>{post.title}</p>
          <div className='mt-4 mx-4'>
            {post.files && post.files.length > 0 ? (
              <img
                src={post.files[0].upload}
                alt='contentImage'
                className='border rounded-md max-h-96'
              />
            ) : (
              ''
            )}
          </div>

          <RichTextEditor readOnly value={post.content} />

          <EditModalComponent
            updatePost={updatePost}
            post={post}
            isOpen={isEditModalOpen}
            onRequestClose={() => setIsEditModalOpen(false)}
            user={user}
          />
        </div>
      </div>
    )
  );
};

export default PostsPage;

import React from 'react';

const ProjectContent = ({ data }) => {
  return (
    <p className='flex flex-col'>
      <span>{'Project Content'}</span>
      <span className='text-sm'>
        <span>Project:</span>{' '}
        <a
          className='text-primary hover:underline'
          href={`/projects/${data.project?.uuid}`}
          target='_blank'
          rel='noreferrer'
        >
          {`${data.project?.name}`}
        </a>
      </span>
    </p>
  );
};
export default ProjectContent;

import React from 'react';

export default function FlagRU({ className }) {
  return (
    // <svg
    //   xmlns='http://www.w3.org/2000/svg'
    //   viewBox='0 0 320 320'
    //   width='24'
    //   height='24'
    //   strokeWidth={1.5}
    //   // stroke='currentColor'
    //   className={`${className ?? ' w-6 h-6 text-primary'}`}
    // >

    <svg
      width='34'
      height='24'
      viewBox='0 0 34 24'
      className={`${className ?? ' w-6 h-6 text-primary'}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7501_646)'>
        <path
          d='M33.9167 15.9385C34.0109 16.0491 33.9926 16.1929 33.9926 16.3289C33.9926 18.7621 33.9926 21.1953 33.9926 23.6284C33.9926 23.916 33.9795 24.1272 33.6314 23.979C33.5313 23.9576 33.4286 23.9554 33.3278 23.9724H0.675667C0.460131 23.9724 0.243588 23.9945 0.0280518 24.0067C0.0209685 23.7457 0.0078125 23.4847 0.0078125 23.2236C0.0078125 20.8192 0.0115213 18.4148 0.018942 16.0104H33.1629C33.3389 16.0104 33.516 16.0037 33.6931 15.9993C33.7822 16.0435 33.8388 15.9407 33.9167 15.9385Z'
          fill='#D42B1E'
        />
        <path
          d='M33.9169 15.9382C33.839 15.9382 33.7823 16.0433 33.6933 15.9957C33.5506 15.8586 33.3786 15.9282 33.2217 15.9282C22.1549 15.9238 11.0873 15.9238 0.0191174 15.9282C0.0150698 13.3587 0.00967572 10.7898 0.00292969 8.22169C0.00292969 8.00049 0.0656666 7.92086 0.260964 7.94851C0.336451 7.95349 0.412143 7.95349 0.48763 7.94851H33.4322C33.4952 7.95347 33.5584 7.95347 33.6214 7.94851C33.9129 7.90538 34.0019 8.02261 33.9999 8.35662C33.9857 10.7599 33.9938 13.1633 33.9898 15.5666C33.9888 15.6871 34.0566 15.8398 33.9169 15.9382Z'
          fill='#0039A5'
        />
        <path
          d='M33.9169 7.9753C33.839 7.9753 33.7823 8.08037 33.6933 8.03281C33.5506 7.89567 33.3786 7.96535 33.2217 7.96535C22.1549 7.96092 11.0873 7.96092 0.0191174 7.96535C0.0150698 5.39576 0.00967572 2.82691 0.00292969 0.258799C0.00292969 0.037601 0.0656666 -0.0420304 0.260964 -0.0143807C0.336451 -0.00940356 0.412143 -0.00940356 0.48763 -0.0143807H33.4322C33.4952 -0.00941586 33.5584 -0.00941586 33.6214 -0.0143807C33.9129 -0.0575143 34.0019 0.059721 33.9999 0.39373C33.9857 2.79705 33.9938 5.20037 33.9898 7.60369C33.9888 7.72424 34.0566 7.87686 33.9169 7.9753Z'
          fill='white'
        />
        <path
          d='M0.0195312 15.9264C11.0864 15.9264 22.1539 15.9264 33.2222 15.9264C33.379 15.9264 33.551 15.8567 33.6937 15.9939C33.5166 15.9939 33.3395 16.0049 33.1635 16.0049H0.0195312V15.9264Z'
          fill='#87597F'
        />
      </g>
      <defs>
        <clipPath id='clip0_7501_646'>
          <rect width='34' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

import React, { useState } from 'react';
import Modal from 'react-modal';
import 'react-quill/dist/quill.snow.css';
import { Button } from '../../components/forms/Buttons/Button';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CustomQuill from './CustomQuill';
import { UploadFile } from '../../components/Icons/UploadFileIcon';
import { patchPost } from '../../services/Feeds/FeedService';

Modal.setAppElement('#root');
const customStyle = {
  content: {
    width: '50%',
    height: '80%',
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #ccc',
    background: '#fff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

export const EditModalComponent = ({
  isOpen,
  onRequestClose,
  user,
  post,
  updatePost,
}) => {
  const [title, setTitle] = useState(post.title);
  const [value, setValue] = useState(post.content);
  const [selectedFile, setSelectedFile] = useState(post.files.uploads);

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleQuillChange = (content, delta, source, editor) => {
    setValue(content);

    const contents = editor.getContents();

    if (contents && contents.ops) {
      const formats = contents.ops.reduce((acc, op) => {
        if (op.attributes) {
          Object.keys(op.attributes).forEach((key) => {
            if (!acc[key]) {
              acc[key] = op.attributes[key];
            }
          });
        }
        return acc;
      }, {});
      // setFormattingOptions(formats);
    }
  };

  const onSubmit = () => {
    if (!value) {
      toast.error('required fields empty');
      return;
    }

    const newData = {
      title: title,
      content: value,
      files: selectedFile,
    };

    patchPost(post.uuid, newData)
      .then((res) => {
        toast.success('Post updated successfully');
        navigate(`/feed/${post.uuid}`);
        onRequestClose();
        updatePost(res);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Example Modal'
      style={customStyle}
    >
      <div className='flex flex-col overflow-x-hidden items-start justify-start'>
        <div className='flex flex-row justify-start items-center cursor-pointer w-auto h-auto p-2 ml-3  '>
          <img
            alt='img'
            className='w-14 h-14 rounded-3xl'
            src={`${user.profile_picture}`}
          />
          <h3 className='pl-2'>
            {user.first_name} {user.last_name}{' '}
          </h3>
        </div>
        <div className='flex flex-col w-full justify-start mt-4 mb-6'>
          <input
            name='title'
            className=' w-full h-8 border border-gray-300 px-2 outline-none'
            placeholder='Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className='overflow-x-hidden h-96 w-full'>
          <CustomQuill value={value} onChange={handleQuillChange} />
        </div>
      </div>
      <div className='flex items-center justify-end'>
        {selectedFile ? (
          <label className='flex justify-start items-start'>
            <UploadFile className='flex flex-col justify-start' />{' '}
            <span className='pl-2 text-xs italic'>{selectedFile.name}</span>{' '}
            <input onChange={handleFileChange} className='hidden' type='file' />
          </label>
        ) : (
          <label className=''>
            <UploadFile className='flex flex-col justify-start' />
            <input onChange={handleFileChange} className='hidden' type='file' />
          </label>
        )}
        <Button className={'ml-auto mr-4 mb-4'} onClick={onSubmit}>
          Create
        </Button>
      </div>
    </Modal>
  );
};

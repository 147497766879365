import React from 'react';
import { useNavigate } from 'react-router-dom';
import mountainImg from '../../../assets/images/tests/header-mountains.svg';
import interaction1Img from '../../../assets/images/tests/interaction-1.svg';
import interaction2Img from '../../../assets/images/tests/interaction-2.svg';
import vectorImg from '../../../assets/images/tests/Vector1.svg';
import brainImg from '../../../assets/images/tests/mis 1.svg';
import poster1Img from '../../../assets/images/tests/1669702782744 1.svg';
import poster2Img from '../../../assets/images/tests/1669702782744 2.svg';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const MBTI_UUID = '1f538f13-782d-46f2-9431-e979dd62d00d';
const MIS_UUID = '4ea7d806-b604-46a6-bb2c-736606fc304f';

const Description = ({ test }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [t] = useTranslation();

  return (
    <div className='flex flex-col gap-4'>
      {test === MBTI_UUID ? (
        <div className='flex flex-col md:gap-4 gap-8'>
          <div className='flex flex-col'>
            <div className='bg-[#4298B4] flex flex-col justify-start items-start gap-2 p-8 pt-12'>
              <div className='text-white flex flex-col justify-start items-centstarter gap-2'>
                <p className=' text-3xl font-semibold leading-[1.1] md:leading-normal'>
                  {t('personality_skills_mbti.banner_title')}
                </p>
                <p className=' text-xl md:w-3/4 lg:w-2/3 leading-[1.1] md:leading-normal'>
                  {t('personality_skills_mbti.banner_description')}
                </p>
              </div>
              <div>
                <button
                  className='bg-[#813BE2] text-white rounded-lg px-4 py-3 font-semibold text-xl
                    hover:bg-white hover:text-[#813BE2]'
                  onClick={() =>
                    navigate({
                      pathname: `/tests/${MBTI_UUID}`,
                    })
                  }
                >
                  <div className='flex flex-row items-center gap-1'>
                    <p>{t('personality_skills_mbti.banner_button')}</p>
                    <ChevronRightIcon className='h-5' />
                  </div>
                </button>
              </div>
            </div>
            <div>
              <img src={mountainImg} alt='img' className='w-full -mt-1' />
            </div>
          </div>
          <div className='flex md:flex-row flex-col justify-between items-center'>
            <div className='flex flex-col md:w-1/2 justify-center lg:px-12 md:px-4 gap-2'>
              <div className='font-bold text-2xl'>
                {t('personality_skills_mbti.first_title')}
              </div>
              <div className='text-xl leading-relaxed'>
                {t('personality_skills_mbti.first_description')}
              </div>
            </div>
            <div className='md:w-1/2'>
              <img src={interaction1Img} alt='img' className='w-full' />
            </div>
          </div>
          <div className='flex md:flex-row flex-col-reverse justify-between items-center'>
            <div className='md:w-1/2'>
              <img src={interaction2Img} alt='img' className='w-full' />
            </div>
            <div className='flex flex-col md:w-1/2 justify-center lg:px-12 md:px-4 gap-2'>
              <div className='font-bold text-2xl'>
                {t('personality_skills_mbti.second_title')}
              </div>
              <div className='text-xl leading-relaxed'>
                {t('personality_skills_mbti.second_description')}
              </div>
            </div>
          </div>
        </div>
      ) : test === MIS_UUID ? (
        <div className='flex flex-col gap-8'>
          <div
            className='flex flex-row px-8 pt-8 pb-20'
            style={{
              backgroundImage: `url(${vectorImg})`,
              backgroundPosition: 'bottom',
              backgroundSize: 'cover',
            }}
          >
            <div className='flex flex-col justify-center items-center gap-2 p-4 md:w-3/4'>
              <div className='flex flex-col justify-center gap-4'>
                <p className='text-3xl font-semibold'>
                  {t('personality_skills_mis.banner_title')}
                </p>
                <p className='text-xl md:w-3/4 lg:w-2/3'>
                  {t('personality_skills_mis.banner_description')}
                </p>

                <div>
                  <button
                    className='bg-[#813BE2] text-white rounded-lg px-4 py-3 font-semibold text-xl
                hover:bg-white hover:text-[#813BE2]'
                    onClick={() =>
                      navigate({
                        pathname: `/tests/${MIS_UUID}`,
                      })
                    }
                  >
                    <div className='flex flex-row items-center gap-1'>
                      <p>{t('personality_skills_mis.banner_button')}</p>
                      <ChevronRightIcon className='h-5' />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            {!isMobile && (
              <div className='w-1/4 flex flex-row justify-center items-center'>
                <img src={brainImg} alt='img' className='h-2/3' />
              </div>
            )}
          </div>
          <div className='flex md:flex-row flex-col justify-between items-center'>
            <div className='flex flex-col md:w-1/2 justify-center lg:px-12 md:px-4 gap-2'>
              <div className='font-bold text-2xl'>
                {t('personality_skills_mis.first_title')}
              </div>
              <div className='text-xl leading-relaxed'>
                {t('personality_skills_mis.first_description')}
              </div>
            </div>
            <div className='md:w-1/2'>
              <img src={poster1Img} alt='img' className='w-full' />
            </div>
          </div>
          <div className='flex md:flex-row flex-col-reverse justify-between items-center'>
            <div className='md:w-1/2'>
              <img src={poster2Img} alt='img' className='w-full' />
            </div>
            <div className='flex flex-col md:w-1/2 justify-center lg:px-12 md:px-4 gap-2'>
              <div className='font-bold text-2xl'>
                {t('personality_skills_mis.second_title')}
              </div>
              <div className='text-xl leading-relaxed whitespace-pre-line'>
                {t('personality_skills_mis.second_description')}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Description;

import bilAPI from '../api';

export const getStudyPlans = ({ page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/studyplans/idp_sets/`, {
          params: {
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getStudyPlan = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/studyplans/idp_sets/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postStudyPlans = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/studyplans/idp_sets/${uuid}/start/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postStudyPlan = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/studyplans/idp_sets/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchStudyPlan = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/studyplans/idp_sets/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteStudyPlan = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/studyplans/idp_sets/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getLastStudyPlan = (account) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/studyplans/idp_sets/get_last/`, {
          params: {
            account,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getStartedStudyPlan = (account) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/studyplans/idp_sets/get_started/`, {
          params: {
            account,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import { Tab } from '@headlessui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getDiscussions } from '../../../services/discussions/discussionService';
import {
  getProjectSubmission,
  getProjectSubmissions,
} from '../../../services/projects/projectSubmissionService';
import { UserContext } from '../../../utils/contexts/UserContext';
import Description from '../../Projects/Project/Description';
import Discussion from '../../Projects/Project/Discussion';
import Submission from '../../Projects/Project/Submission';

const SUBMISSIONS_INDEX = 2;

const SubmissionMain = () => {
  const [t] = useTranslation();
  const { uuid } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { user } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState(SUBMISSIONS_INDEX);
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const [submission, setSubmission] = useState();
  const [submissions, setSubmissions] = useState([]);
  const [discussions, setDiscussions] = useState([]);

  const getSubmissionData = () => {
    setLoadingSubmission(true);
    getProjectSubmission(uuid)
      .then((res) => {
        console.log('res', res);
        setSubmission(res);
      })
      .catch((err) => {})
      .finally(() => setLoadingSubmission(false));
  };

  const getProjectSubmissionsData = () => {
    getProjectSubmissions({
      project_assignment: submission?.project_assignment?.uuid,
    })
      .then((res) => {
        setSubmissions(res?.results);
      })
      .catch(() => {});
  };

  const getDiscussionsData = () => {
    getDiscussions({ uuid: submission?.project_assignment?.project?.uuid })
      .then((res) => {
        console.log('discussions', res?.results);
        setDiscussions(res?.results);
      })
      .catch(() => {});
  };

  useEffect(() => {
    // console.log('user', user);
    getSubmissionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (submission) {
      getProjectSubmissionsData();
      getDiscussionsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission]);

  return (
    <div className='w-full rounded-xl shadow-md p-4'>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className='text-xl'>
          <Tab
            className='px-4 ui-selected:underline ui-selected:underline-offset-8 
              decoration-primary ui-not-selected:hover:underline ui-selected:outline-none
              ui-not-selected:hover:underline-offset-8 ui-not-selected:hover:decoration-default'
          >
            {t('projects.description')}
          </Tab>
          <Tab
            className='px-4 ui-selected:underline ui-selected:underline-offset-8 
              decoration-primary ui-not-selected:hover:underline  ui-selected:outline-none
              ui-not-selected:hover:underline-offset-8 ui-not-selected:hover:decoration-default'
          >
            {`${t('projects.discussion')} (${discussions?.length})`}
          </Tab>

          <Tab
            className='px-4 ui-selected:underline ui-selected:underline-offset-8 
              decoration-primary ui-not-selected:hover:underline  ui-selected:outline-none
              ui-not-selected:hover:underline-offset-8 ui-not-selected:hover:decoration-default'
          >
            {t('projects.submission')}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <Description
              setSelectedIndex={setSelectedIndex}
              submissionsIndex={SUBMISSIONS_INDEX}
              submission={submission}
              projectAssignment={submission?.project_assignment}
              loadingData={loadingSubmission}
              refreshData={getSubmissionData}
            />
          </Tab.Panel>
          <Tab.Panel>
            <Discussion
              discussions={discussions}
              refreshData={getDiscussionsData}
              projectAssignment={submission?.project_assignment}
            />
          </Tab.Panel>

          <Tab.Panel>
            <Submission
              projectAssignment={submission?.project_assignment}
              submissions={submissions}
              refreshData={getProjectSubmissionsData}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default SubmissionMain;

import { Tab } from '@headlessui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getDiscussions } from '../../../services/discussions/discussionService';
import { getProjectAssignments } from '../../../services/projects/projectAssignmentService';
import { getProject } from '../../../services/projects/projectService';
import { getProjectSubmissions } from '../../../services/projects/projectSubmissionService';
import { UserContext } from '../../../utils/contexts/UserContext';
import Description from './Description';
import Discussion from './Discussion';
import Submission from './Submission';
import ChatAI from './ChatAI';

const SUBMISSIONS_INDEX = 2;

const Project = () => {
  const [t] = useTranslation();
  const { uuid } = useParams();
  const { user } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loadingProjectAssignment, setLoadingProjectAssignment] =
    useState(false);
  const [projectAssignment, setProjectAssignment] = useState();
  const [submissions, setSubmissions] = useState([]);
  const [discussions, setDiscussions] = useState([]);
  const [project, setProject] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const handleChange = (value) => {
    setIsVisible(true);
  };
  const getProjectAssignmentData = () => {
    setLoadingProjectAssignment(true);
    getProjectAssignments({ student: user?.uuid, project: uuid })
      .then((res) => {
        if (res?.results?.length) {
          setProjectAssignment(res?.results[0]);
          console.log('res?.results[0]', res?.results[0]);
        } else {
          getProjectData();
        }
      })
      .catch((err) => {})
      .finally(() => setLoadingProjectAssignment(false));
  };

  const getProjectSubmissionsData = () => {
    getProjectSubmissions({ project_assignment: projectAssignment?.uuid })
      .then((res) => {
        setSubmissions(res?.results);
      })
      .catch(() => {});
  };

  const getDiscussionsData = () => {
    getDiscussions({
      uuid: project ? project?.uuid : projectAssignment?.project?.uuid,
    })
      .then((res) => {
        console.log('discussions', res?.results);
        setDiscussions(res?.results);
      })
      .catch(() => {});
  };

  const getProjectData = () => {
    getProject(uuid)
      .then((res) => {
        setProject(res);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (projectAssignment) {
      getProjectSubmissionsData();
      getDiscussionsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAssignment]);

  useEffect(() => {
    if (project) {
      getDiscussionsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    // console.log('user', user);
    if (user?.roles?.some((r) => r?.name === 'STUDENT'))
      getProjectAssignmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <div className='w-full min-h-[90vh] rounded-xl shadow-md p-4'>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className='lg:text-xl md:text-xl flex flex-nowrap'>
            <Tab
              className='md:px-4 lg:px-4 px-2 ui-selected:underline ui-selected:underline-offset-8 
              decoration-primary ui-not-selected:hover:underline ui-selected:outline-none
              ui-not-selected:hover:underline-offset-8 ui-not-selected:hover:decoration-default'
            >
              {t('projects.description')}
            </Tab>
            <Tab
              className='md:px-4 lg:px-4 px-2 flex-shrink-0 ui-selected:underline ui-selected:underline-offset-8 
              decoration-primary ui-not-selected:hover:underline  ui-selected:outline-none
              ui-not-selected:hover:underline-offset-8 ui-not-selected:hover:decoration-default'
            >
              {`${t('projects.discussion')} (${discussions?.length})`}
            </Tab>
            {(user?.roles?.some((r) => r?.name === 'PROJECTREVIEWER') ||
              (user?.roles?.some((r) => r?.name === 'STUDENT') &&
                (projectAssignment?.is_in_progress ||
                  projectAssignment?.project?.status === 'accepted'))) && (
              <Tab
                className='md:px-4 lg:px-4 px-2 ui-selected:underline ui-selected:underline-offset-8 
              decoration-primary ui-not-selected:hover:underline  ui-selected:outline-none
              ui-not-selected:hover:underline-offset-8 ui-not-selected:hover:decoration-default'
              >
                {t('projects.submission')}
              </Tab>
            )}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <Description
                setSelectedIndex={setSelectedIndex}
                submissionsIndex={SUBMISSIONS_INDEX}
                projectAssignment={projectAssignment}
                loadingData={loadingProjectAssignment}
                refreshData={getProjectAssignmentData}
                submissions={submissions}
                onChange={handleChange}
              />
            </Tab.Panel>
            <Tab.Panel>
              <Discussion
                discussions={discussions}
                refreshData={getDiscussionsData}
                projectAssignment={projectAssignment}
                project={project}
              />
            </Tab.Panel>
            {(user?.roles?.some((r) => r?.name === 'PROJECTREVIEWER') ||
              (user?.roles?.some((r) => r?.name === 'STUDENT') &&
                (projectAssignment?.is_in_progress ||
                  projectAssignment?.project?.status === 'accepted'))) && (
              <Tab.Panel>
                <Submission
                  projectAssignment={projectAssignment}
                  submissions={submissions}
                  refreshData={getProjectSubmissionsData}
                />
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className={isVisible ? 'w-full rounded-xl shadow-md p-4' : 'hidden'}>
        <ChatAI isVisible={isVisible} />
      </div>
    </div>
  );
};

export default Project;

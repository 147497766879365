import { createContext, useEffect, useState } from 'react';
import { getSkillPoint } from '../../services/projects/skillsService';

export const PreferredPairContext = createContext(null);

export const MyPreferredPairProvider = ({ children }) => {
  const [pair, setPair] = useState(null);
  const [pairs, setPairs] = useState([]);

  useEffect(() => {
    getSkillPoint({ uuid: null, level: 2 }).then((res) => {
      setPairs(res?.results);
      const preferredPair = res?.results?.find((p) => p?.is_prefered === true);
      if (preferredPair?.uuid) {
        setPair(preferredPair);
      }
    });

    return () => {};
  }, []);

  const updatePair = (newPairUuid) => {
    console.log('update', newPairUuid);
    const newPair = pairs.find((p) => p?.uuid === newPairUuid);
    setPair(newPair);
  };

  return (
    <PreferredPairContext.Provider value={{ pair, updatePair, setPair }}>
      {children}
    </PreferredPairContext.Provider>
  );
};

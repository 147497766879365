import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { components } from 'react-select';

const Select = ({
  options,
  className,
  placeholder,
  onChange,
  value,
  label,
  required,
  isDisabled,
  isSearchable,
  isMulti,
  loading,
  closeMenuOnSelect,
  errorMsg,
  customStyles,
  isClearable,
  creatable,
  textStyles,
  Icon,
}) => {
  const [t] = useTranslation();

  const animatedComponents = makeAnimated();

  const customStylesMain = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      borderWidth: '1px',
      borderRadius: '0.375rem',
      opacity: state.isDisabled ? 0.5 : 1,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: '1rem',
      fontSize: '14px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      paddingRight: '0.5rem',
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#374151',
      };
    },
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: '#E9ECF5',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      display: state.selectProps.menuIsOpen ? 'none' : 'block',
    }),
  };

  const getValue = (value) => {
    if (isMulti) {
      return options?.filter((o) => value?.includes(o?.value));
    }
    return options?.find((o) => o?.value === value);
  };

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          <div className='flex flex-row gap-2 items-center -ml-2'>
            {!!Icon && <Icon className='h-4 text-primary' />}
            {children}
          </div>
        </components.ValueContainer>
      )
    );
  };
  const SingleValue = ({ children, ...props }) => {
    const Value = props?.selectProps?.value;
    return (
      <components.SingleValue {...props}>
        <div className='flex flex-row gap-2 items-center'>
          {!!Value?.Icon && <Value.Icon className='h-4 text-primary' />}
          {children}
        </div>
      </components.SingleValue>
    );
  };

  function IconOption(props) {
    const {
      data: { label, Icon },
      isSelected,
    } = props;
    return (
      <components.Option {...props}>
        <div className='flex items-center gap-2'>
          {Icon && (
            <Icon
              className={`h-4 ${isSelected ? 'text-white' : 'text-primary'}`}
            />
          )}
          <span>{label}</span>
        </div>
      </components.Option>
    );
  }

  return (
    <div className={className}>
      <label className={`block font-medium text-gray-700 ${textStyles}`}>
        {label}
        <span className={`text-error`}>{required && ' *'}</span>
        <ReactSelect
          className='relative'
          styles={customStyles ? customStyles : customStylesMain}
          options={options}
          isSearchable={isSearchable}
          placeholder={placeholder ?? 'Select'}
          onChange={onChange}
          value={getValue(value)}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isLoading={loading}
          closeMenuOnSelect={closeMenuOnSelect}
          isClearable={isClearable}
          creatable={creatable}
          components={
            isMulti
              ? animatedComponents
              : Icon
              ? { ValueContainer, SingleValue, Option: IconOption }
              : { SingleValue, Option: IconOption }
          }
          // components={{ ValueContainer }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#2C59F8',
              primary75: '#fb923c',
              primary50: '#cbd5e1',
              primary25: '#e2e8f0',
              neutral0: '#f7f7fa',
              neutral5: '',
              neutral10: '',
              neutral20: '',
              neutral30: '',
              neutral40: '',
              neutral50: '',
              neutral60: '',
              neutral70: '',
              neutral80: '',
              neutral90: '',
            },
          })}
        />
      </label>

      {errorMsg && (
        <span className='mt-1 text-xs font-semibold text-error'>
          {errorMsg}
        </span>
      )}
    </div>
  );
};

export default Select;

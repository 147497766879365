import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import avatarPlaceholder from '../../../assets/images/avatar_placeholder.jpg';
import { Button } from '../../../components/forms/Buttons/Button';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { postDiscussion } from '../../../services/discussions/discussionService';

const Discussion = ({
  discussions,
  refreshData,
  projectAssignment,
  project,
}) => {
  const [t] = useTranslation();

  const [comment, setComment] = useState();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  return (
    <div className='md:py-8 lg:py-8 py-4 md:px-4 lg:px-4 flex flex-col gap-8'>
      <div className='flex flex-col'>
        {discussions.map((d) => (
          <div
            key={Math.random()}
            className='flex flex-row gap-4 items-center md:p-4 lg:p-4 p-2 w-full'
          >
            <div className='shrink-0'>
              <img
                src={d?.user?.profile_picture || avatarPlaceholder}
                alt='avatar'
                className='md:w-20 md:h-20 lg:w-20 lg:h-20 w-16 h-16 
                  rounded-full object-cover'
              />
            </div>
            <div className='flex flex-col'>
              <div className='text-gray-500'>
                {moment(d?.updated).fromNow()}
              </div>
              <div className='font-medium'>
                <span className='text-primary'>{`${d?.user?.first_name} ${d?.user?.last_name}`}</span>{' '}
                {t('projects.commented')}:
              </div>
              <div className='text-gray-700 mt-1'>
                {d?.text === 'undefined' ? t('projects.no_comment') : d?.text}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='flex flex-col gap-2'>
        <div>
          <TextArea
            // label={t('accounting.comment')}
            rows={8}
            className='border rounded-lg shadow-md'
            placeholder={t('projects.type_comment')}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className='flex flex-row justify-end'>
          <Button
            color={'primary'}
            text={t('projects.submit')}
            loading={loadingSubmit}
            onClick={() => {
              if (!comment) {
                toast.error(t('projects.empty_comment'));
                return;
              }

              const body = new FormData();
              body.append('text', comment);
              body.append(
                'object_id',
                project ? project?.uuid : projectAssignment?.project?.uuid,
              );

              setLoadingSubmit(true);
              postDiscussion(body)
                .then((res) => {
                  setComment('');
                  toast.success(t('projects.submitted_successfully'));
                  refreshData();
                })
                .catch(() => {})
                .finally(() => setLoadingSubmit(false));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Discussion;

import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getResults = ({ test, user }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tests/results/`, {
          params: { test, user },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getResultDescriptions = ({ result, test }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tests/result_descriptions/`, {
          params: { result, test },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getResultDescription = ({ uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tests/result_descriptions/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postResultDescription = ({ test, result, description }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tests/result_descriptions/`, {
          test,
          result,
          description,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchResultDescription = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/tests/result_descriptions/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteResultDescription = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .delete(`/tests/result_descriptions/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postResultDescriptionProffesionRelated = ({
  profession,
  result_description,
  order,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/tests/result_descriptions_profession_related/`, {
          profession,
          result_description,
          order,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchResultDescriptionProffesionRelated = ({
  uuid,
  profession,
  result_description,
  order,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/tests/result_descriptions_profession_related/${uuid}/`, {
          result_description,
          profession,
          order,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteResultDescriptionProffesionRelated = ({ uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/tests/result_descriptions_profession_related/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postResultDescriptionFile = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/tests/result_description_files/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchResultDescriptionFile = ({ uuid, body }) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/tests/result_description_files/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteResultDescriptionFile = ({ uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .delete(`/tests/result_description_files/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const exportResult = ({ uuid, body, lang }) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/tests/export_result/${uuid}/`, body, {
          responseType: 'blob',
          headers: { 'Accept-Language': lang },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

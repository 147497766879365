import bilAPI from '../api';

export const postUserProgress = ({ lesson, status, user }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/user_progress/`, {
          lesson,
          status,
          user,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

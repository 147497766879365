import React, { useContext, useEffect, useState } from 'react';
import Foreign from './Tabs/Foreign/Foreign';
import Local from './Tabs/Local/Local';
import { getAccountDatum } from '../../services/accountData/accountDataService';
import { UserContext } from '../../utils/contexts/UserContext';
import SpecialLocal from './Tabs/SpecialLocal/SpecialLocal';

const Universities = () => {
  const { user } = useContext(UserContext);
  const [tab, setTab] = useState('foreign');
  const [UNT, setUNT] = useState();

  useEffect(() => {
    getAccountDatum(user.uuid)?.then((res) => {
      setUNT(res?.data?.exam_ratings?.UNT);
    });
  }, [user.uuid]);

  return tab === 'foreign' ? (
    <Foreign setTab={setTab} />
  ) : tab == 'local' ? (
    <Local setTab={setTab} UNT={UNT} />
  ) : (
    <SpecialLocal setTab={setTab} />
  );
};

export default Universities;

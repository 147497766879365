import React, { useState } from 'react';

import CreatableSelect from 'react-select/creatable';

const createOption = (label, value) => ({
  label,
  value,
});

const CustomCreatableSelect = ({
  className,
  isClearable,
  defaultOptions,
  create,
  onChange,
  value,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);

  const getValue = (value) => {
    return options?.find((o) => o?.value === value);
  };

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    create(inputValue)?.then((res) => {
      setTimeout(() => {
        const newOption = createOption(inputValue, res.uuid);
        setIsLoading(false);
        setOptions((prev) => [...prev, newOption]);
        onChange(newOption);
      }, 1000);
    });
  };

  return (
    <CreatableSelect
      className={className}
      isClearable={isClearable}
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={onChange}
      onCreateOption={handleCreate}
      options={options}
      value={getValue(value)}
    />
  );
};

export default CustomCreatableSelect;

import bilAPI from '../api';
import contentAPI from '../contentApi';

export const postContent = ({ lesson }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/contents/`, {
          lesson,
          order: 0, // order sets on backend, but need provide some value
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postIncreaseContentOrder = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/contents/${uuid}/increase_order/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postDecreaseContentOrder = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/contents/${uuid}/decrease_order/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/contents/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postVideoContent = ({ content, url }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/video_contents/`, {
          content,
          url,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchVideoContent = ({ uuid, url }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/video_contents/${uuid}/`, {
          url,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteVideoContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/video_contents/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postTextContent = ({ content, text }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/text_contents/`, {
          content,
          text,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchTextContent = ({ uuid, text }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/text_contents/${uuid}/`, {
          text,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteTextContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/text_contents/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postProjectContent = ({ content, project }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/project_contents/`, {
          content,
          project,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchProjectContent = ({ uuid, project }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/project_contents/${uuid}/`, {
          project,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteProjectContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/project_contents/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postQuestionContent = ({ content, text, answer }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/question_contents/`, {
          content,
          text,
          answer,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchQuestionContent = ({ uuid, text, answer }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/question_contents/${uuid}/`, {
          text,
          answer,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteQuestionContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/question_contents/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postFileContent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/courses/file_contents/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchFileContent = ({ uuid, file }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/file_contents/${uuid}/`, {
          file,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteFileContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/file_contents/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

import bilAPI from '../api';

export const getOrganizations = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/organizations/my_organizations_list/`, {
          params: { page_size: 80 },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getClasses = ({ organization }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/organizations/group_list/`, {
          params: { page_size: 80, organization },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

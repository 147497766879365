import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import RichTextEditor from '../../../components/forms/Inputs/RichTextEditor';
import {
  getResultDescription,
  patchResultDescription,
} from '../../../services/tests/resultService';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/Input';
import { useParams } from 'react-router-dom';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import { toast } from 'react-toastify';

const ResultDescriptionEdit = () => {
  const [t] = useTranslation();
  const { descriptionUuid } = useParams();
  const [description, setDescription] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    const body = {
      description: data.description,
    };
    // setIsLoading(true);
    patchResultDescription(description.uuid, body)
      .then((res) => {
        setDescription(res);
      })
      .catch(() => {})
      .finally(() => toast.success('Saved'));
  };

  useEffect(() => {
    setIsLoading(true);
    getResultDescription({ uuid: descriptionUuid })
      .then((res) => {
        setDescription(res);
      })
      .finally(() => setIsLoading(false));
  }, [descriptionUuid]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col gap-4 pt-4'
      >
        {isLoading ? (
          <SimpleLoader className='h-10' />
        ) : (
          <>
            <div className='text-center w-full text-xl'>
              <Input
                className='flex flex-col items-start'
                errorMsg={errors?.result?.message}
                value={description?.result?.result}
                disabled
                label='Result'
              />
            </div>

            <Controller
              control={control}
              name='description'
              defaultValue={description?.description}
              required='Description is required field'
              render={({ field }) => (
                <>
                  <RichTextEditor
                    errorMsg={errors?.description?.message}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                  <span>
                    To write the formula please use{' '}
                    <a
                      className='text-primary hover:underline'
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.overleaf.com/learn/latex/Integrals%2C_sums_and_limits'
                    >
                      LaTeX
                    </a>
                  </span>
                </>
              )}
            />
            <div className='flex flex-row justify-end gap-2'>
              <Button color='success' text={t('Edit')} type='submit' />
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default ResultDescriptionEdit;

import React from 'react';

function findMedian(start, end) {
  // CHATGPT
  // Generate an array of numbers within the specified range
  const numbers = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index,
  );

  // Sort the array
  numbers.sort((a, b) => a - b);

  // Calculate the median
  const middleIndex = Math.floor(numbers.length / 2);
  if (numbers.length % 2 === 0) {
    // If the array has an even length, take the average of the two middle elements
    return (numbers[middleIndex - 1] + numbers[middleIndex]) / 2;
  } else {
    // If the array has an odd length, return the middle element
    return numbers[middleIndex];
  }
}

const TestScale = ({
  className,
  min,
  max,
  minValue,
  maxValue,
  current,
  setCurrent,
  disabled,
  startSize = 6,
}) => {
  const median = findMedian(min, max);

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <div className='flex flex-row gap-2 items-center justify-between'>
        <span className='w-2/5'>{minValue}</span>
        <span className='w-2/5 text-end'>{maxValue}</span>
      </div>
      <div className='flex flex-row gap-2 items-center justify-center'>
        <div className='flex flex-row-reverse gap-2 items-center'>
          {Array.from(
            { length: median - min },
            (_, index) => median - index - 1,
          ).map((item, i) => (
            <div
              key={item}
              className={`border border-purple-300 rounded-full items-center cursor-pointer ${
                current === item ? 'bg-purple-300' : 'hover:bg-purple-100'
              }`}
              style={{
                width: `${(startSize + 1 + i) / 4}rem`,
                height: `${(startSize + 1 + i) / 4}rem`,
              }}
              onClick={() => {
                if (disabled) {
                  return;
                }
                setCurrent(item);
              }}
            />
          ))}
        </div>

        <div
          className={`border rounded-full cursor-pointer ${
            current === median ? 'bg-slate-300' : 'hover:bg-slate-100'
          }`}
          style={{
            width: `${startSize / 4}rem`,
            height: `${startSize / 4}rem`,
          }}
          onClick={() => {
            if (disabled) {
              return;
            }
            setCurrent(median);
          }}
        />
        {Array.from(
          { length: max - median },
          (_, index) => median + index + 1,
        ).map((item, i) => (
          <div
            key={item}
            className={`border border-green-300 rounded-full items-center cursor-pointer ${
              current === item ? 'bg-green-300' : 'hover:bg-green-100'
            }`}
            style={{
              width: `${(startSize + 1 + i) / 4}rem`,
              height: `${(startSize + 1 + i) / 4}rem`,
            }}
            onClick={() => {
              if (disabled) {
                return;
              }
              setCurrent(item);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default TestScale;

import React, { useState } from 'react';
import { postTest } from '../../../../services/tests/testService';
import { Button } from '../../../../components/forms/Buttons/Button';
import Input from '../../../../components/forms/Inputs/Input';
import CustomDialog from '../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../../../components/forms/Select/Select';

const CreateTestButton = ({ refreshData }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const TYPES = ['MBTI', 'Multiple intelligences test'];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    postTest(data)
      .then(() => refreshData())
      .catch(() => {})
      .finally(() => setOpen(false));
  };

  return (
    <>
      <Button text='Create new Test' onClick={() => setOpen(true)} />
      <CustomDialog isOpen={open} closeModal={() => setOpen(false)}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-4 pt-4'
        >
          <Input
            errorMsg={errors.name?.message}
            required
            label='title'
            {...register('name', {
              required: 'name is required field',
            })}
          />
          <Controller
            control={control}
            rules={{
              required: 'type field is required',
            }}
            name='type'
            render={({ field }) => (
              <Select
                errorMsg={errors.type?.message}
                value={field.value}
                onChange={(v) => {
                  field.onChange(v?.value);
                }}
                options={TYPES?.map((q, i) => ({
                  label: q,
                  value: i,
                }))}
                className='w-80'
              />
            )}
          />
          <div className='flex flex-row justify-end gap-2'>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              onClick={() => setOpen(false)}
              type='button'
            />
            <Button color='success' text={t('buttons.create')} type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default CreateTestButton;

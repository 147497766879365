import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getFiles = ({ uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/core/files/${uuid ? uuid + '/' : ''}`, {
          params: { test },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postFile = ({ body }) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/core/files/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchFile = ({ uuid, upload }) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/core/files/${uuid}/`, {
          upload,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteFile = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/core/files/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import ContentCreater from './ContentCreater/ContentCreater';
import Student from './Student/Student';
import { getCourses } from '../../../services/courses/courseService';
import Description from '../Description';

const Courses = () => {
  const { user } = useContext(UserContext);

  const [courses, setCourses] = useState([]);

  const getCoursesData = useCallback(() => {
    getCourses({}).then((res) => {
      setCourses(res.results);
    });
  }, []);

  useEffect(() => {
    getCoursesData();
  }, [getCoursesData]);

  return (
    <div className='flex flex-col gap-4 p-4'>
      <Description />
      {user?.roles?.some((r) => ['ADMIN']?.includes(r?.name)) ? (
        <ContentCreater data={courses} refreshData={getCoursesData} />
      ) : (
        <Student data={courses} refreshData={getCoursesData} />
      )}
    </div>
  );
};

export default Courses;

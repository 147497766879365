import { createContext, useState } from 'react';

export const SidebarContext = createContext(null);

export const SidebarProvider = ({ children }) => {
  const [id, setId] = useState('');

  return (
    <SidebarContext.Provider
      value={{
        id,
        setId,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

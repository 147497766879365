import bilAPI from '../api';

export const createVideoConference = ({
  name,
  welcome_text,
  banner_text,
  guest_policy,
  start_time,
  logout_url,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/meeting/meeting/`, {
          name,
          welcome_text,
          banner_text,
          guest_policy,
          start_time,
          logout_url,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getMyVideoConferences = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/meeting/my_meeting/`, {
          params: {},
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getConferenceInfo = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/meeting/meeting/${uuid}/`, {
          params: {},
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getConferenceRunningStatus = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/meeting/meeting/${uuid}/is_running/`, {
          params: {},
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const startVideoConference = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/meeting/meeting/${uuid}/start_meeting/`, {
          params: {},
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const joinVideoConference = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/meeting/meeting/${uuid}/get_join_url/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const addVideoConferenceToCalendar = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/meeting/meeting/${uuid}/add_to_calendar/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

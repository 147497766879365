import React from 'react';
import { deleteCourse } from '../../../../../services/courses/courseService';
import { TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

const Delete = ({ course, refreshData }) => {
  return (
    <TrashIcon
      onClick={() => {
        if (
          !window.confirm(
            `Are you sure you want to delete course: ${course.name}?`,
          )
        )
          return;
        deleteCourse(course.uuid)
          ?.then(() => {
            toast.success(`Course "${course.name}" deleted`);

            refreshData();
          })
          .catch(() => {
            toast.error(`Error on deleting course "${course.name}"`);
          });
      }}
      className='w-8 h-8 p-1 text-red-400 border rounded-full bg-white hover:bg-slate-200 cursor-pointer'
    />
  );
};

export default Delete;

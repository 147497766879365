import React from 'react';
import StudentProfile from './StudentProfile/StudentProfile';
import { useLocation, useParams } from 'react-router-dom';

export default function Profile() {
  const { uuid } = useParams();
  let { state } = useLocation();
  // if (user?.roles?.some((r) => r?.name === 'STUDENT')) {
  return <StudentProfile uuid={uuid} tab={state?.tab ? state?.tab : 0} />;
  // }

  // if (user?.roles?.some((r) => r?.name === 'PROJECTREVIEWER')) {
  //   return <TeacherSubmissions />;
  // }

  // return <SimpleLoader />;
}

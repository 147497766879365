import React, { useEffect } from 'react';
import SSOBilOrgKz from './SSOBilOrgKz';
import { useMediaQuery } from 'react-responsive';

const DOMAIN = 'https://bil.org.kz';
const PATH = 'ssop';
const w = 500;
const h = 600;
const left = (window.innerWidth - 500) / 2;
const top = (window.innerHeight - 600) / 2;

export default function LoginFromBilorg() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  useEffect(() => {
    window.open(
      `${DOMAIN}/${PATH}`,
      'bilOrgKzTab',
      !isMobile &&
        `width=${w},height=${h},top=${top},left=${left},resizable=no`,
    );
    return () => {};
  }, []);

  return (
    <div>
      redirecting...
      <SSOBilOrgKz className='hidden' />
    </div>
  );
}

import React from 'react';

const Input = ({
  className,
  label,
  placeholder,
  required,
  type,
  onChange,
  defaultValue,
  value,
  readOnly,
  textCenter,
  bgWhite,
  onClick,
}) => {
  return (
    <label className={'block ' + className}>
      <span className='flex flex-row gap-1'>
        {label}
        <span className={`text-error`}>{required && '*'}</span>
      </span>
      <input
        type={type}
        className={`px-3 py-2 ${
          bgWhite ? 'bg-white' : 'bg-sheet-gray'
        } shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-lg focus:ring-1 ${
          textCenter && 'text-center'
        }`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        readOnly={readOnly}
        onClick={onClick}
      />
    </label>
  );
};

export default Input;

import React from 'react';
import RichTextEditor from '../../../../../components/forms/Inputs/RichTextEditor';

const QuestionContent = ({ data }) => {
  return (
    <p className='flex flex-col'>
      <span>{'Question Content'}</span>
      <span className='text-sm'>
        Question: <RichTextEditor readOnly value={data.text} />
      </span>
      <span className='text-sm'>
        Answer:{' '}
        {data?.correct_answer?.map((ans, i) => (
          <>
            {!!i && ', '}
            <span>{ans?.answer?.answer}</span>
          </>
        ))}
      </span>
    </p>
  );
};
export default QuestionContent;

export const getTranlated = (field, object) => {
  const enField = `${field}EN`;

  if (!object) return '';

  const isEnExists = enField in object && object[enField];
  if (localStorage.getItem('i18nextLng') === 'en-US' && isEnExists)
    return object[enField];

  return object[field];
};

import bilAPI from '../api';

export const getProfessions = ({ uuid, page, page_size }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/core/profession/${uuid ? uuid + '/' : ''}`, {
          params: { page, page_size },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postProfession = ({ name, code }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/core/profession/`, {
          name,
          code,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchProfession = ({ uuid, name, code }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/core/profession/${uuid}/`, {
          name,
          code,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteProfession = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/core/profession/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bgImage from '../../../../assets/images/bg.jpg';
import avatarPlaceholder from '../../../../assets/images/avatar_placeholder.jpg';
import { useTranslation } from 'react-i18next';
import {
  CheckIcon,
  ChevronLeftIcon,
  DocumentTextIcon,
  PlayIcon,
} from '@heroicons/react/24/outline';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import { Button } from '../../../../components/forms/Buttons/Button';
import CircleProgressBar from '../../../../components/charts/CircleProgressBar';
import { getLessons } from '../../../../services/courses/lessonService';
import { startCourse } from '../../../../services/courses/courseService';

const Student = ({ data, course, refreshCourse }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [lessons, setLessons] = useState([]);
  const [section, setSection] = useState();
  const [loading, setLoading] = useState(false);

  const getLessonsData = useCallback(() => {
    setLoading(true);
    getLessons({ section: section?.uuid })
      .then((res) => {
        setLessons(res.results);
      })
      .catch(() => {})
      ?.finally(() => setLoading(false));
  }, [section?.uuid]);

  useEffect(() => {
    if (section) {
      getLessonsData();
    }
  }, [getLessonsData, section]);
  return (
    <div>
      <div className='flex flex-col'>
        <div
          className='h-48 flex flex-row'
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
          }}
        >
          <div className='w-1/3 px-4 py-8'>
            <Button
              text={t('buttons.back')}
              color='secondary-solid'
              Icon={ChevronLeftIcon}
              onClick={() => navigate('/courses')}
            />
          </div>
          <div className='text-white w-2/3 flex flex-col h-full justify-end gap-8 px-8 py-8'>
            <div className='text-2xl lg:text-4xl font-medium pl-4'>
              {section?.name}
            </div>
            <div className='flex flex-row items-center gap-4'>
              <div className='w-1/2 relative'>
                <div className='absolute h-3 bg-secondary w-full rounded-xl -top-1' />
                {course.status && (
                  <>
                    <div
                      className='absolute h-3 bg-primary rounded-xl -top-1'
                      style={{
                        width: `${course.course_progress * 100}%`,
                      }}
                    />
                  </>
                )}
              </div>
              <div>
                {course.status ? (
                  `${(course.course_progress * 100).toFixed()}%`
                ) : (
                  <Button
                    text={t('projects.start')}
                    color='success'
                    Icon={PlayIcon}
                    loading={loading}
                    onClick={() => {
                      setLoading(true);
                      startCourse({ uuid: course.uuid })
                        .then(() => {
                          refreshCourse();
                        })
                        .catch(() => {})
                        .finally(() => setLoading(false));
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='flex lg:flex-row md:flex-row flex-col'>
          <div className='lg:w-1/3 md:w-1/3 px-4'>
            <div
              className='rounded-xl border shadow-lg py-4 px-6 flex flex-col divide-y-2
                w-full bg-white lg:-mt-12 md:-mt-12 -mt-4 font-medium lg:text-lg'
            >
              <div
                className={`py-2 flex flex-row items-center gap-2 cursor-pointer ${
                  !section ? 'font-bold pl-2' : ''
                }`}
                onClick={() => setSection()}
              >
                <DocumentTextIcon className='h-4' />
                {t('studyplans.overview')}
              </div>
              {data?.map((s, index) => (
                <div
                  key={index}
                  className={`py-3 flex flex-row items-center gap-2 cursor-pointer leading-tight ${
                    section?.uuid === s.uuid ? 'font-bold pl-2' : ''
                  }`}
                  onClick={() => setSection(s)}
                >
                  <CircleProgressBar
                    progress={(s.section_status * 100).toFixed()}
                  />

                  {s?.name}
                </div>
              ))}
            </div>
          </div>
          <div className='lg:w-2/3 md:w-2/3 py-6 px-4'>
            {!section ? (
              <div className='flex flex-col gap-4'>
                <div className='flex flex-row items-center gap-2'>
                  <div className='shrink-0 gap-2'>
                    {course?.authors?.map((author, i) => {
                      return (
                        <img
                          src={author?.profile_picture || avatarPlaceholder}
                          alt='avatar'
                          className='md:w-20 md:h-20 lg:w-20 lg:h-20 w-12 h-12 
                  rounded-full object-cover'
                        />
                      );
                    })}
                  </div>
                  <div className='flex flex-col'>
                    <div>
                      {course?.authors?.map((author, i) => {
                        return (
                          <span key={author.uuid}>
                            {(!!i ? ', ' : '') +
                              author.author.first_name +
                              ' ' +
                              author.author?.last_name}
                          </span>
                        );
                      })}
                    </div>
                    <div className='text-primary'>{t('studyplans.author')}</div>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='font-medium text-lg'>
                    {t('projects.description')}
                  </div>
                  <div className='text-gray-600'>{course?.description}</div>
                </div>
              </div>
            ) : (
              <div className='flex flex-col'>
                <div className='text-lg font-medium'>{section.name}</div>
                <div className='text-gray-700'>{section?.description}</div>

                <div className='rounded-2xl border mt-8'>
                  <div className='bg-gray-200 rounded-t-2xl py-2 px-4'>
                    {t('Lessons')}
                  </div>
                  {loading ? (
                    <div className='pt-12'>
                      <SimpleLoader className={'h-12'} />
                    </div>
                  ) : (
                    <table className='table-auto w-full'>
                      <tbody>
                        {lessons?.map((l) => (
                          <tr
                            className='border-b cursor-pointer hover:bg-gray-100'
                            key={l?.uuid}
                            onClick={() =>
                              navigate(
                                `/courses/6f711a52-9ae1-427a-9be4-fe0ba6ad55f6/lesson/${l?.uuid}`,
                              )
                            }
                          >
                            <td className='pl-2 pr-1 py-3'>
                              {l?.status === 0 ? (
                                <div className='text-xs whitespace-nowrap flex flex-row gap-1 items-center'>
                                  <div
                                    className='h-4 w-4 rounded-full bg-secondary 
                            flex flex-row justify-center items-center shrink-0'
                                  >
                                    <div className='h-[0.6rem] w-[0.6rem] rounded-full bg-white'></div>
                                  </div>
                                  <span className='whitespace-nowrap hidden lg:block'>
                                    {t('projects.not_started')}
                                  </span>
                                </div>
                              ) : (
                                l?.status === 1 && (
                                  <div className='text-xs whitespace-nowrap text-success flex flex-row gap-1 items-center'>
                                    <CheckIcon className='h-4' />
                                    <span className='whitespace-nowrap hidden lg:block'>
                                      Completed
                                    </span>
                                  </div>
                                )
                              )}
                            </td>
                            <td className='pl-8 px-1 lg:text-base text-sm text-start w-full'>
                              {l?.name}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Student;

import bilAPI from '../api';

export const getOrgRoadmapStats = ({ page_size, page }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/org_rm_statistics/`, { params: { page_size, page } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getGroupRoadmapStats = ({ page_size, page, organization }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/group_rm_statistics/`, {
          params: { page_size, page, organization },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import React from 'react';
import Plyr from 'plyr-react';
import './plyr.css';
import { extractVideoID } from '../../../../../utils/helpers/helpers';

const VideoContent = ({ data, justPlayer, fullWidth }) => {
  const videoSrc = {
    type: 'video',
    sources: [
      {
        src: extractVideoID(data?.url),
        provider: 'youtube',
      },
    ],
  };

  const options = {
    autoplay: false,
    playsinline: true,
  };

  return (
    <p className='flex flex-col w-full'>
      {!justPlayer && <span>{'Video Content'}</span>}
      {!justPlayer && (
        <span className='text-sm'>
          <span>Video:</span>{' '}
          <a
            className='text-primary hover:underline'
            href={data.url}
            target='_blank'
            rel='noreferrer'
          >
            {`${data.url}`}
          </a>
        </span>
      )}
      <div className={`${fullWidth ? 'w-full' : 'w-1/2'}`}>
        <Plyr source={videoSrc} options={options} />
      </div>
    </p>
  );
};
export default VideoContent;

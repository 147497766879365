import contentAPI from '../contentApi';
import bilAPI from '../api';

export const postInstruction = ({ type, title, description, link, image }) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .post(`/instructions/instructions/`, {
          type,
          title,
          description,
          link,
          image,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error:', error);
    }
  });
};

export const getInstructions = ({
  type,
  image,
  description,
  title,
  link,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/instructions/instructions/`, {
          params: { type, image, description, title, link, page, page_size },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchInstruction = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/instructions/instructions/${uuid}/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getInstruction = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/instructions/instructions/${uuid}/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const deleteInstruction = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/instructions/instructions/${uuid}/`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

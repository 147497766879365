import { ArrowUpTrayIcon, DocumentIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Upload = ({
  className,
  uploadText = 'Upload file',
  file,
  setFile,
  sizeLimit = 4194304,
  size,
  accept,
}) => {
  const [t] = useTranslation();
  const inputRef = React.useRef(null);
  const [isOversized, setIsOversized] = useState(false);
  const [imagePreview, setImagePreview] = useState();

  return (
    <div
      className={`p-2 flex flex-col ${
        file ? '' : 'border-dashed'
      } border-4 gap-2 justify-center items-center rounded-xl ${
        isOversized ? 'border-error' : ''
      }  bg-opacity-10  cursor-pointer ${isOversized && 'border border-error'}
      ${size === 'sm' ? 'h-32' : 'h-48'}
      ${className}`}
      onClick={() => inputRef.current.click()}
    >
      <div>
        {imagePreview ? (
          <div>
            <img
              src={imagePreview}
              alt='Uploaded Preview'
              className={`w-auto ${
                size === 'sm' ? 'h-28' : 'h-44'
              } object-contain rounded-lg transition-opacity duration-300 hover:opacity-50 opacity-100`}
            />
          </div>
        ) : (
          <div className='flex flex-col justify-center items-center'>
            {file ? (
              <DocumentIcon className='w-1/5 text-gray-300 max-h-20 min-h-10' />
            ) : (
              <ArrowUpTrayIcon className='w-1/5 text-gray-300 max-h-20 min-h-10' />
            )}
            <p
              className={`truncate px-10 max-w-sm ${
                file?.name ? '' : 'text-gray-400'
              }`}
            >
              {isOversized
                ? t('component.file_too_large')
                : file
                ? file?.name
                  ? file?.name
                  : file?.split('/')?.length
                  ? file?.split('/')[file?.split('/')?.length - 1]
                  : file
                : uploadText}
            </p>
          </div>
        )}
      </div>
      <input
        ref={inputRef}
        type='file'
        accept={accept}
        style={{ display: 'none' }}
        onChange={(e) => {
          const f = e?.target?.files?.[0];
          if (!f) {
            setFile();
            return;
          }
          if (f?.size < sizeLimit) {
            setIsOversized(false);
            setFile(f);

            if (f?.type?.startsWith('image/')) {
              const reader = new FileReader();
              reader.onloadend = () => {
                setImagePreview(reader?.result);
              };
              reader.readAsDataURL(f);
            }
          } else {
            setIsOversized(true);
          }
        }}
      />
    </div>
  );
};

export default Upload;

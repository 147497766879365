import bilAPI from "../api";


export const getProjectsStudyPlan = (page_size) => {
    return new Promise((resolve, reject) => {
        try {
            bilAPI
            .get('studyplans/idp_sets/',{
                params: {
                    page_size: page_size,
                },
            })
            .then((res)=>{
                resolve(res.data);
            })
            .catch((err)=>{
                reject('axios error', err);
            });
        } catch(error){
            reject('system error', error);
        }
    });
}    
import bilAPI from '../api';

export const getSpecialLocalUniversities = ({ isLocal }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/universities/university_landing/`, {
          params: {
            is_local: isLocal,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getSpecialLocalUniversityInfo = (key) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/universities/university_info/${key}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import React, { useState } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextArea from '../../../../../components/forms/Inputs/TextArea';
import { patchCourse } from '../../../../../services/courses/courseService';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

const Edit = ({ refreshData, course }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const body = { ...data };
    body.uuid = course.uuid;
    patchCourse(body)
      .then(() => refreshData())
      .catch(() => {})
      .finally(() => setOpen(false));
  };

  return (
    <>
      <PencilSquareIcon
        className='w-8 h-8 p-1 text-primary border rounded-full bg-white hover:bg-slate-200 cursor-pointer'
        onClick={() => setOpen(true)}
      />
      <CustomDialog isOpen={open} closeModal={() => setOpen(false)}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-4 pt-4'
        >
          <div className='w-full'>
            <Input
              errorMsg={errors.name}
              required
              label='title'
              {...register('name', {
                required: 'title is required field',
                value: course.name,
              })}
            />
            <TextArea
              errorMsg={errors.name}
              required
              rows={7}
              label='description'
              {...register('description', {
                required: 'description is required field',
                value: course.description,
              })}
            />
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              onClick={() => setOpen(false)}
              type='button'
            />
            <Button color='success' text={'edit'} type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Edit;

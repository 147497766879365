import React from 'react';
import { t } from 'i18next';

export default function LeaderboardTable({
  data,
  type,
  showStatsForSchool,
  showStatsForGrade,
  setGrade,
}) {
  return (
    <div className='flex flex-col gap-4 mt-2'>
      <div className='overflow-x-auto'>
        <table className='table-auto w-full'>
          <thead>
            <tr className='sticky top-0 bg-gray-200 border-b-2'>
              <th className='pb-2 px-2'>#</th>
              <th className='text-left'>
                {type === 'schools'
                  ? t('stats.projects.school')
                  : t('stats.projects.grade')}
              </th>
              <th className='px-1'>{t('stats.projects.total_projects')}</th>
              <th className='px-1'>{t('stats.projects.language_projects')}</th>
              <th className='px-1'>{t('stats.projects.science_projects')}</th>
              <th className='px-1'>{t('stats.projects.it_projects')}</th>
              <th className='px-1'>
                {t('stats.projects.social_science_projects')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, index) => (
              <tr
                key={index}
                className='border-b-2 last:border-b-0 cursor-pointer hover:bg-gray-200 even:bg-gray-100'
                onClick={() => {
                  if (type === 'schools') {
                    showStatsForSchool(d?.uuid, d?.name);
                  }
                  if (type === 'grades') {
                    showStatsForGrade(d?.uuid);
                    setGrade(d);
                  }
                }}
              >
                <td className='py-1 px-2 text-center'>{index + 1}</td>
                <td>{d?.name}</td>
                <td className='text-center'>{d?.projects}</td>
                <td className='text-center'>{d?.language}</td>
                <td className='text-center'>{d?.science}</td>
                <td className='text-center'>{d?.it}</td>
                <td className='text-center'>{d?.socialscience}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

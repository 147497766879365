import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { postResultDescription } from '../../../services/tests/resultService';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/Input';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import RichTextEditor from '../../../components/forms/Inputs/RichTextEditor';

const Add = ({ refreshData, test }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (data) => {
    const body = {
      result: { result: data.result },
      description: data.description,
      test,
    };
    postResultDescription(body)
      .then(() => refreshData())
      .catch(() => {})
      .finally(() => setOpen(false));
  };

  return (
    <>
      <Button text='Create new description' onClick={() => setOpen(true)} />
      <CustomDialog isOpen={open} closeModal={() => onClose(false)}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-4 pt-4'
        >
          <div className='text-center w-full text-xl'>
            <Input
              className='flex flex-col items-start'
              errorMsg={errors?.result?.message}
              required
              label='Result'
              {...register('result', {
                required: 'Result is required field',
              })}
            />
          </div>

          <Controller
            control={control}
            name='description'
            required='Description is required field'
            render={({ field }) => (
              <>
                <RichTextEditor
                  errorMsg={errors?.description?.message}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
                <span>
                  To write the formula please use{' '}
                  <a
                    className='text-primary hover:underline'
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.overleaf.com/learn/latex/Integrals%2C_sums_and_limits'
                  >
                    LaTeX
                  </a>
                </span>
              </>
            )}
          />
          <div className='flex flex-row justify-end gap-2'>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              onClick={() => onClose(false)}
              type='button'
            />
            <Button color='success' text={t('buttons.create')} type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Add;

import React, { useState } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import InputWithIcon from '../../../../../components/forms/Inputs/InputWithIcon';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { patchQuestionContent } from '../../../../../services/courses/contentService';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import RichTextEditor from '../../../../../components/forms/Inputs/RichTextEditor';
import {
  deleteAnswerOption,
  patchAnswerOption,
  postAnswerOption,
} from '../../../../../services/courses/answerOptionService';

const EditQuestion = ({ refreshData, data }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [answer, setAnswer] = useState('');
  const { handleSubmit, reset, control } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
    setAnswer('');
  };

  const onSubmit = (d) => {
    let body = { ...d };
    body.uuid = data.question_content.uuid;
    if (d.file_content) {
      body = new FormData();
      Object.keys(d).forEach((k) => {
        body.append(k, d[k]);
      });
      body.append('uuid', data.question_content.uuid);
    }
    patchQuestionContent(body)?.then(() => {
      refreshData();
      onClose();
    });
  };

  return (
    <>
      <PencilSquareIcon
        className='text-primary h-9 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-2'
        onClick={() => setOpen(true)}
      />
      <CustomDialog isOpen={open} closeModal={() => onClose()}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-4 pt-4'
        >
          <div className='flex flex-col w-full gap-2'>
            <Controller
              control={control}
              name='text'
              defaultValue={data.question_content.text}
              render={({ field }) => (
                <>
                  <RichTextEditor
                    value={field.value}
                    onChange={(e) => {
                      console.log(e);
                      field.onChange(e);
                    }}
                  />
                  <span>
                    To write the formula please use{' '}
                    <a
                      className='text-primary hover:underline'
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.overleaf.com/learn/latex/Integrals%2C_sums_and_limits'
                    >
                      LaTeX
                    </a>
                  </span>
                </>
              )}
            />
            {data.question_content.answers.map((answer) => {
              return (
                <div
                  onClick={() => {
                    patchAnswerOption({
                      uuid: answer.uuid,
                      is_correct: !answer.is_correct,
                    }).then(() => {
                      refreshData(false);
                    });
                  }}
                  className={`border cursor-pointer relative p-4 rounded-lg min-h-[3.5rem] ${
                    answer.is_correct && 'bg-green-200'
                  }`}
                >
                  {answer.answer?.answer}
                  <TrashIcon
                    className='absolute top-2 right-5 text-red-500 h-9 cursor-pointer hover:bg-error bg-red-100 hover:text-white rounded-lg p-2'
                    onClick={() => {
                      let is_confimed = window.confirm(
                        `Are you sure you want to delete answer option?`,
                      );
                      if (!is_confimed) {
                        return;
                      }
                      deleteAnswerOption(answer.uuid).then(() => {
                        refreshData(false);
                      });
                    }}
                  />
                </div>
              );
            })}
            <InputWithIcon
              Icon={PlusIcon}
              onChange={(e) => {
                setAnswer(e.target.value);
              }}
              value={answer}
              onIconClick={() => {
                if (!answer) {
                  return;
                }
                postAnswerOption({
                  question: data.question_content.uuid,
                  answer: { answer },
                }).then(() => {
                  refreshData(false);
                });
              }}
            />
          </div>
          <div className='flex flex-row justify-end gap-2 '>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              onClick={() => onClose()}
              type='button'
            />
            <Button color='success' text={'Edit'} type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default EditQuestion;

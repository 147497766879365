import React, { useState, useEffect, useContext } from 'react';
import { HandThumbUpIcon as HandThumbUpIconOutline } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../FormatDate';
import { HandThumbUpIcon as HandThumbUpIconSolid } from '@heroicons/react/24/solid';
import { likePost } from '../../../services/Feeds/FeedService';
import { UserContext } from '../../../utils/contexts/UserContext';
import RichTextEditor from '../../../components/forms/Inputs/RichTextEditor';

function PostCard({ post }) {
  const imageURL = post?.files?.[0]?.upload;

  const [isLiked, setIsLiked] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const liked = localStorage.getItem(`liked_${user.uuid}_${post.uuid}`);
    setIsLiked(liked === 'true');
  }, [post.uuid, user.uuid]);

  const truncateHTML = (html, maxLength) => {
    const truncatedText = html.replace(/<\/?[a-z][^>]*>/gi, '');
    const words = truncatedText.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
    return truncatedText;
  };

  const handleLike = () => {
    likePost(post.uuid)
      .then(() => {
        setIsLiked(true);
        localStorage.setItem(`liked_${user.uuid}_${post.uuid}`, 'true');
      })
      .catch((error) => {
        console.log('Error liking post:', error);
      });
  };

  const handleUnlike = () => {
    likePost(post.uuid)
      .then(() => {
        setIsLiked(false);
        localStorage.setItem(`liked_${user.uuid}_${post.uuid}`, 'false');
      })
      .catch((error) => {
        console.error('Error unliking post:', error);
      });
  };

  return (
    <div className='flex flex-col w-full h-auto border border-gray-200 rounded-md shadow-lg'>
      <div className='flex flex-col'>
        <div className='flex flex-row justify-between items-center py-4 px-6'>
          <div className='flex flex-row items-center'>
            <img
              src={
                post.author_info.profile_picture ||
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
              }
              alt='Author Avatar'
              className='w-10 h-10 rounded-full'
            />
            <div className='ml-2'>
              <p className='font-semibold'>
                {post.author_info.first_name} {post.author_info.last_name}
              </p>
              <p className='text-sm text-gray-500'>{post.author_info.role}</p>
            </div>
          </div>
          <p className='italic text-xs text-gray-500'>
            {formatDate(post.created)}
          </p>
        </div>
        <div
          onClick={() => navigate(`/feed/${post?.uuid}`)}
          className='cursor-pointer'
        >
          {post.title && (
            <h1 className='text-2xl px-6 font-bold text-blue-500 hover:text-blue-400'>
              {post.title}
            </h1>
          )}
          <div className='px-2'>
            <RichTextEditor
              readOnly={true}
              value={truncateHTML(post.content, 40)}
            />
          </div>
          {/* <button className='mt-4 text-blue-500 hover:text-blue-400 transition duration-300 ease-in-out'>
            Read more &rarr;
          </button> */}
        </div>
        <div className='px-6'>
          <img
            src={imageURL}
            alt='Post Content'
            className='max-h-96 w-full object-contain'
          />
        </div>
        <div className='flex flex-row justify-end mt-3 mb-2 md:gap-4 lg:gap-4 gap-2 px-6'>
          {isLiked ? (
            <HandThumbUpIconSolid
              onClick={handleUnlike}
              className='w-6 text-blue-500'
            />
          ) : (
            <HandThumbUpIconOutline
              onClick={handleLike}
              className='w-6 text-gray-500'
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PostCard;

import React, { useEffect, useState } from 'react';
import { Button } from '../../components/forms/Buttons/Button';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { getInstruction } from '../../services/instructions/instructionService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import VideoContent from '../Courses/Lesson/ContentCreater/Contents/VideoContent';
import { extractVideoID } from '../../utils/helpers/helpers';

const Instruction = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (uuid) {
      setLoading(true);
      getInstruction(uuid)
        .then((res) => {
          console.log('res', res);
          setData(res);
        })
        .finally(() => setLoading(false));
    }
  }, [uuid]);

  return (
    <div>
      <div className='flex flex-col gap-4 p-4'>
        <div className='flex flex-row items-center justify-between'>
          {/* Back Button */}
          <div className='w-auto'>
            <Button
              text={'Back'}
              color='secondary-solid'
              Icon={ChevronLeftIcon}
              onClick={() => navigate(-1)}
            />
          </div>

          {/* Title (centered) */}
          <div className='flex-grow text-center text-xl font-semibold'>
            {data?.title}
          </div>

          {/* This empty div can take space to keep title centered */}
          <div className='w-auto' />
        </div>

        {loading ? (
          <div className='flex flex-col pt-8 items-center'>
            <SimpleLoader className={'w-12'} />
          </div>
        ) : data ? (
          <div className='flex flex-col items-center'>
            {data?.type === 1 && (
              <div className='md:w-2/3 w-full'>
                <VideoContent data={{ url: data?.link }} justPlayer fullWidth />
              </div>
            )}
          </div>
        ) : (
          <div className='text-gray-500 text-lg flex flex-col items-center pt-8'>
            No data available
          </div>
        )}
      </div>
    </div>
  );
};

export default Instruction;

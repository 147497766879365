import bilAPI from '../api';

export const postAnswerOption = ({ question, answer }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/answer_options/`, {
          question,
          answer,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchAnswerOption = ({ uuid, answer, is_correct }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/answer_options/${uuid}/`, {
          answer,
          is_correct,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteAnswerOption = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/answer_options/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

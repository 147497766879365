import React, { useEffect, useState } from 'react';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import moment from 'moment';
import { Button } from '../../components/forms/Buttons/Button';
import { startVideoConference } from '../../services/videoConference/videoConferenceService';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

export default function VideoConferenceInfoModal({
  openModal,
  setOpenModal,
  selectedVideoConference,
  setSelectedVideoConference,
}) {
  const [loading, setLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    setCurrentPath(window.location.href);

    return () => {
      setSelectedVideoConference(null);
    };
  }, [setSelectedVideoConference]);

  return (
    <CustomDialog isOpen={openModal} closeModal={() => setOpenModal(false)}>
      <div>
        <div className='px-4 sm:px-0'>
          <h3 className='text-base font-semibold leading-7 text-gray-900'>
            Video conference information
          </h3>
        </div>
        <div className='mt-6 border-t px-2 border-gray-100'>
          <dl className='divide-y divide-gray-100'>
            <div className='p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Name
              </dt>
              <dd className='font-semibold mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {selectedVideoConference?.name}
              </dd>
            </div>
            <div className='p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Welcome text
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {selectedVideoConference?.welcome_text}
              </dd>
            </div>
            <div className='p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Banner text
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {selectedVideoConference?.banner_text}
              </dd>
            </div>
            <div className='p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Start date
              </dt>
              <dd className='font-semibold mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {moment(selectedVideoConference?.start_time).format(
                  'DD MMMM | HH:mm',
                )}
              </dd>
            </div>
            <div className='p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Guest policy
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {selectedVideoConference?.guest_policy}
              </dd>
            </div>
            <div className='p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Share Link:
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                <div className='flex flex-row gap-2'>
                  <input
                    type='text'
                    disabled
                    value={`${currentPath.substring(
                      0,
                      currentPath.length - 17,
                    )}/join-video-conference/${selectedVideoConference?.uuid}`}
                    className='block bg-green-100 text-green-600 font-semibold w-full max-w-[20rem] rounded-md border-green-400 px-3 py-2  border-2 shadow-sm sm:text-sm'
                  />
                  <Button
                    Icon={ClipboardDocumentListIcon}
                    className='min-w-[4rem]'
                    color='primary'
                    type='button'
                    onClick={() => {
                      var copyText = `${currentPath.substring(
                        0,
                        currentPath.length - 17,
                      )}/join-video-conference/${
                        selectedVideoConference?.uuid
                      }`;

                      navigator.clipboard.writeText(copyText);

                      toast.success('Share link successfully copied!');
                    }}
                  />
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className='flex flex-row mt-2 justify-end'>
        <Button
          disabled={new Date(selectedVideoConference?.start_time) < new Date()}
          text='Start meeting'
          color='primary'
          type='button'
          loading={loading}
          onClick={() => {
            if (selectedVideoConference?.uuid) {
              setLoading(true);
              startVideoConference(selectedVideoConference.uuid)
                .then((res) => {
                  window.open(res.moder_link, '_blank').focus();
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          }}
        />
      </div>
    </CustomDialog>
  );
}

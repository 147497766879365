/* eslint-disable no-unused-vars */
import {
  ArrowSmallUpIcon,
  ArrowUpCircleIcon,
  ChevronLeftIcon,
  DocumentArrowUpIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  PlayIcon,
  ShareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/forms/Buttons/Button';
import {
  getProject,
  getProjectAdvice,
} from '../../../services/projects/projectService';
import {
  patchProjectAssignment,
  postProjectAssignment,
} from '../../../services/projects/projectAssignmentService';
import { UserContext } from '../../../utils/contexts/UserContext';
import { toast } from 'react-toastify';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import {
  postProjectDislike,
  postProjectLike,
} from '../../../services/projects/projectLikeService';
import { ScrollContext } from '../../../utils/contexts/ScrollContext';
import { useMediaQuery } from 'react-responsive';

const HASHTAGS_EXAMPLE = ['physics', 'magnetic', 'laboratory', 'application'];

const Description = ({
  setSelectedIndex,
  submissionsIndex,
  projectAssignment,
  submission,
  loadingData,
  refreshData,
  submissions,
  onChange,
}) => {
  const [t] = useTranslation();

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { uuid } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [project, setProject] = useState();
  const [loadingProject, setLoadingProject] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [hashtags, setHashtags] = useState(HASHTAGS_EXAMPLE);
  const [open, setOpen] = useState(false);

  const { scrollRef } = useContext(ScrollContext);
  const [test, setTest] = useState();
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  const listenToScroll = useCallback(
    (a) => {
      const { scrollTop } = scrollRef?.current;
      let H = 200;
      if (scrollTop > H) {
        setIsScrollVisible(true);
      } else {
        setIsScrollVisible(false);
      }
    },
    [scrollRef],
  );

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', listenToScroll);
    return () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollRef?.current?.removeEventListener('scroll', listenToScroll);
  }, [listenToScroll, scrollRef]);

  const getProjectData = () => {
    if (submission) {
      if (project) {
        refreshData();
      } else {
        setProject(submission?.project_assignment?.project);
      }
    } else {
      setLoadingProject(true);
      getProject(uuid)
        .then((res) => {
          setProject(res);
        })
        .catch(() => {})
        .finally(() => setLoadingProject(false));
    }
  };
  const setAdviceData = () => {
    setIsVisible(true);
    onChange(true);
  };

  useEffect(() => {
    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  return (
    <div className='px-4 py-4 flex flex-col gap-4 mb-8'>
      {isScrollVisible && (
        <ArrowUpCircleIcon
          className={`h-10 bottom-10 left-10 absolute z-20 text-primary cursor-pointer animate-[bounce_1s_ease-in-out_2.5] `}
          onClick={() => {
            scrollRef?.current?.scrollTo({
              top: 0,
              behavior: 'smooth',
              block: 'start',
            });
          }}
        />
      )}

      <CustomDialog isOpen={open} closeModal={() => setOpen(false)}>
        <div className='flex flex-col gap-4 pt-4'>
          <div className='text-center w-full text-xl'>
            {t('projects.discard_confirmation')}
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              onClick={() => setOpen(false)}
            />
            <Button
              color='error-solid'
              text={t('projects.discard')}
              onClick={() => {
                const body = new FormData();
                body.append('is_in_progress', false);
                patchProjectAssignment(projectAssignment?.uuid, body)
                  .then(() => refreshData())
                  .catch(() => {})
                  .finally(() => setOpen(false));
              }}
            />
          </div>
        </div>
      </CustomDialog>
      <div className='flex flex-row justify-between gap-8 items-center'>
        <Button
          text={!isMobile && t('buttons.back')}
          color='secondary'
          Icon={ChevronLeftIcon}
          onClick={() => navigate(-1)}
        />
        {user?.roles?.some((r) => r?.name === 'STUDENT') && (
          <div className='flex flex-row gap-4 items-center'>
            {projectAssignment?.is_in_progress ? (
              <div className='flex flex-row gap-2'>
                <Button
                  text={!isMobile && t('buttons.discard')}
                  color='error-solid'
                  Icon={XMarkIcon}
                  loading={loadingData}
                  onClick={() => {
                    setOpen(true);
                  }}
                  disabled={submissions?.some((s) => s?.evaluation === 1)}
                />
                <Button
                  text={!isMobile && t('buttons.submit')}
                  color='success'
                  Icon={DocumentArrowUpIcon}
                  onClick={() => {
                    setSelectedIndex(submissionsIndex);
                  }}
                  disabled={submissions?.some((s) => s?.evaluation === 1)}
                />
              </div>
            ) : (
              <Button
                text={t('projects.start')}
                color='success'
                Icon={PlayIcon}
                loading={loadingData}
                disabled={project?.status === 'accepted'}
                onClick={() => {
                  if (projectAssignment) {
                    const body = new FormData();
                    body.append('is_in_progress', true);
                    patchProjectAssignment(projectAssignment?.uuid, body)
                      .then(() => refreshData())
                      .catch(() => {});
                  } else {
                    const body = new FormData();
                    body.append('is_in_progress', true);
                    body.append('student', user?.uuid);
                    body.append('project', uuid);
                    postProjectAssignment(body)
                      .then(() => refreshData())
                      .catch(() => {});
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
      <div
        className='relative flex flex-col gap-4 md:h-32 lg:h-32 rounded-xl'
        style={{
          backgroundImage: `url(${project?.img})`,
          backgroundPosition: 'center',
        }}
      >
        <div className='bg-black absolute w-full h-full opacity-30 rounded-xl'></div>

        <div
          className='text-xl text-white w-full text-center z-10 h-full
          flex flex-col justify-center md:font-semibold lg:font-semibold font-medium 
          md:tracking-wide lg:tracking-wide my-1 p-1'
        >
          {project?.name}
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between gap-2'>
        <div className='flex flex-row md:gap-8 lg:gap-8 gap-6 items-center'>
          <div
            className={`${
              project?.level === 'Beginner'
                ? 'text-primary'
                : project?.level === 'Easy'
                ? 'text-success'
                : project?.level === 'Medium'
                ? 'text-warning'
                : project?.level === 'Hard'
                ? 'text-error'
                : 'text-secondary'
            }`}
          >
            {project?.level}
          </div>
          <div className='flex flex-row md:gap-4 lg:gap-4 gap-2'>
            <div className='flex flex-row md:gap-2 lg:gap-2 gap-1 items-center text-secondary'>
              <HandThumbUpIcon
                className={`h-6 cursor-pointer ${
                  project?.is_liked === true
                    ? 'text-success'
                    : 'hover:text-black'
                }`}
                onClick={() => {
                  postProjectLike(project?.uuid)
                    .then(() => {
                      getProjectData();
                    })
                    .catch(() => {});
                }}
              />
              <div>{project?.count_likes}</div>
            </div>
            <div className='flex flex-row gap-2 items-center text-secondary'>
              <HandThumbDownIcon
                className={`h-6 cursor-pointer ${
                  project?.is_liked === false
                    ? 'text-error'
                    : 'hover:text-black'
                }`}
                onClick={() => {
                  postProjectDislike(project?.uuid)
                    .then(() => {
                      getProjectData();
                    })
                    .catch(() => {});
                }}
              />
              <div>{project?.count_dislikes}</div>
            </div>
          </div>
          <div>
            <ShareIcon
              className='h-6 cursor-pointer text-secondary hover:text-black'
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast.success(t('projects.copied_to_clipboard'), {
                  position: 'top-center',
                  hideProgressBar: true,
                });
              }}
            />
          </div>
        </div>
        <Button
          color='gpt'
          // loading={loadingData}
          disabled={isVisible}
          onClick={() => setAdviceData()}
        >
          Ask AI
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='#cf4dfb'
            viewBox='0 0 24 24'
            strokeWidth='1'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z'
            />
          </svg>
        </Button>
      </div>
      <div className='flex md:flex-col lg:flex-col flex-col-reverse gap-4 -mt-4'>
        <div className='flex flex-row flex-wrap gap-2 pt-4'>
          {project?.tags?.map((ht) => (
            <div
              key={Math.random()}
              className='py-1 px-4 rounded-lg bg-gradient-to-r 
            from-error to-violet-700 text-white whitespace-nowrap'
            >{`#${ht}`}</div>
          ))}
        </div>
        <div className='flex flex-col gap-2 grow'>
          <div
            className='whitespace-pre-line text-sm md:text-base lg:text-base'
            dangerouslySetInnerHTML={{ __html: project?.content }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Description;

import React, { useContext, useState } from 'react';
import { UserContext } from '../../utils/contexts/UserContext';
import CardBase from './cards/CardBase';

import universityImg from '../../assets/images/university_card.png';
import personalityImg from '../../assets/images/personality_card.png';
import coursesImg from '../../assets/images/courses_card.png';
import projectsImg from '../../assets/images/projects_card.png';
import profileProjectsImg from '../../assets/images/profile_projects_card.png';
import projectSetsImg from '../../assets/images/project_sets_card.png';
import roadmapSmallImg from '../../assets/images/roadmap_small_card.png';
import profileImg from '../../assets/images/profile_card.png';
import meetingImg from '../../assets/images/meeting_card.png';
import tutorials from '../../assets/images/tutorials_card.png';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { user } = useContext(UserContext);
  const [t] = useTranslation();

  const [cardsGroups] = useState([
    {
      header: t('home.first_row.title'),
      description: t('home.first_row.description'),
      cards: [
        {
          img: tutorials,
          title: t('home.first_row.instructions_card_title'),
          text: t('home.first_row.instructions_card_description'),
          link: '/instructions',
        },
        {
          img: roadmapSmallImg,
          title: t('home.first_row.roadmap_card_title'),
          text: t('home.first_row.roadmap_card_description'),
          link: '/roadmap',
        },
        {
          img: meetingImg,
          title: t('home.first_row.meetings_card_title'),
          text: t('home.first_row.meetings_card_description'),
          link: '/video-conference',
        },
      ],
    },
    {
      header: t('home.second_row.title'),
      description: t('home.second_row.description'),
      cards: [
        {
          img: universityImg,
          title: t('home.second_row.universities_card_title'),
          text: t('home.second_row.universities_card_description'),
          link: '/universities',
        },
        {
          img: personalityImg,
          title: t('home.second_row.personality_&_skills_card_title'),
          text: t('home.second_row.personality_&_skills_card_description'),
          link: '/tests',
        },
        {
          img: coursesImg,
          title: t('home.second_row.courses_card_title'),
          text: t('home.second_row.courses_card_description'),
          link: '/courses',
        },
      ],
    },
    {
      header: t('home.third_row.title'),
      description: t('home.third_row.description'),
      cards: [
        {
          img: projectsImg,
          title: t('home.third_row.projects_card_title'),
          text: t('home.third_row.projects_card_description'),
          link: '/projects',
        },
        {
          img: profileProjectsImg,
          title: t('home.third_row.project_submission_card_title'),
          text: t('home.third_row.project_submission_card_description'),
          link: `/profile/${user.uuid}`,
          state: { tab: 'projects' },
        },
        {
          img: projectSetsImg,
          title: t('home.third_row.project_sets_card_title'),
          text: t('home.third_row.project_sets_card_description'),
          link: '/study-plans',
        },
      ],
    },
  ]);

  return (
    <div className='flex flex-col py-4 lg:px-28 md:px-14 px-4  gap-2'>
      {cardsGroups.map((cardGroup, index) => (
        <React.Fragment key={index}>
          {!!index && <div className='md:mt-16 mt-8' />}
          <span className='font-black text-4xl text-blue-600'>
            {cardGroup?.header}
          </span>
          <span className='text-lg'>{cardGroup?.description}</span>

          <div className='grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-4'>
            {cardGroup?.cards?.map((card, i) => {
              return (
                <CardBase
                  key={i}
                  title={card.title}
                  img={card.img}
                  text={card.text}
                  link={card.link}
                  state={card?.state}
                  cols={card?.cols}
                />
              );
            })}
            {cardGroup?.custom_cards && (
              <cardGroup.custom_cards></cardGroup.custom_cards>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

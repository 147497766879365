import React from 'react';
import AcademicRankCard from '../../../../components/cards/AcademicRankCard';
import SoftSkillsCard from '../../../../components/cards/SoftSkillsCard';

export default function Progress({ uuid }) {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4 pt-0'>
      {/* <StudentProjectStats uuid={uuid} /> */}
      <AcademicRankCard studentUuid={uuid} isInProfile={true} />
      <SoftSkillsCard studentUuid={uuid} />
    </div>
  );
}

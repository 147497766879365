import React, { useEffect, useState } from 'react';
import { postResultDescriptionProffesionRelated } from '../../../../services/tests/resultService';
import { Button } from '../../../../components/forms/Buttons/Button';
import CustomDialog from '../../../../components/modals/CustomDialog/CustomDialog';
import { postProfession } from '../../../../services/professions/professions';
import CustomCreatableSelect from '../../../../components/forms/Select/CustomCreatableSelect';
import ProfessionCard from './ProfessionCard';

const AddProfession = ({
  result_description,
  professions,
  refreshData,
  refreshProfessions,
}) => {
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(1);
  const [profession, setProfession] = useState();

  useEffect(() => {
    setOrder(result_description?.professions_related?.length + 1);
  }, [result_description?.professions_related?.length]);

  return (
    <>
      <Button text='Add professions' onClick={() => setOpen(true)} />
      <CustomDialog isOpen={open} closeModal={() => setOpen(false)}>
        <div className='flex flex-col gap-2 w-full'>
          {result_description?.professions_related?.map((prof_ref) => (
            <ProfessionCard
              key={prof_ref?.uuid}
              prof_ref={prof_ref}
              professions={professions}
              refreshData={refreshData}
            />
          ))}
          <div className='w-full flex flex-row gap-2 items-center border p-4 rounded-lg'>
            {order}
            {'.'}
            <CustomCreatableSelect
              className='w-full'
              create={(label) => {
                return new Promise((resolve, reject) => {
                  postProfession({ name: label })
                    .then((res) => {
                      resolve(res);
                      refreshProfessions();
                    })
                    .catch((err) => {
                      reject(err);
                    });
                });
              }}
              // refreshData={refreshProfessions}
              defaultOptions={professions.map((prof) => ({
                value: prof?.uuid,
                label: prof.name,
              }))}
              onChange={(v) => setProfession(v?.value)}
            />
            <Button
              text='add'
              onClick={() => {
                setProfession();

                postResultDescriptionProffesionRelated({
                  profession,
                  result_description: result_description.uuid,
                  order,
                })?.then(() => {
                  refreshData();
                });
              }}
            />
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default AddProfession;

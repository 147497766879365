import { PlusSmallIcon } from '@heroicons/react/24/outline';
import Input from '../../../../../components/forms/Inputs/Input';
import TextArea from '../../../../../components/forms/Inputs/TextArea';
import SidebarModal from '../../../../../components/modals/SidebarModal/SidebarModal';
import { Button } from '../../../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SidebarContext } from '../../../../../utils/contexts/SidebarContext';
import DatePicker from '../../../../../components/forms/DatePicker/DatePicker';
import { format } from 'date-fns';

const Edit = ({ data, setData, task, index }) => {
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onClose = () => {
    reset();
    setId('');
  };

  const reset = useCallback(() => {
    setTitle(task?.name);
    setDescription(task?.description);
    setStartDate(new Date(task?.start_date));
  }, [task]);

  useEffect(() => {
    if (id !== uuid) {
      reset();
    }
  }, [id, uuid]);

  return (
    <>
      <Button
        color='primary'
        text='Edit'
        className='max-h-6'
        onClick={() => {
          setId(uuid);
        }}
      />

      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_task')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            required
          />
          <DatePicker
            date={startDate}
            setDate={(d) => {
              // if (endDate < d && endDate) {
              //   toast.error(t('roadmap.reversed_date_error'));
              //   return;
              // }
              setStartDate(d);
            }}
            label={t('roadmap.start_date')}
            required
          />
          <TextArea
            label={t('Description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {/* <Input
              label={t('Period')}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              value={period}
            /> */}

          <div className='flex flex-row justify-center mt-4'>
            <Button
              text={t('Save')}
              loading={loadingSubmit}
              onClick={() => {
                const newArray = data?.body?.map((item, i) => {
                  if (i === index) {
                    let subtasks = item.subtasks;

                    if (format(startDate, 'yyyy-MM-dd') !== item.start_date) {
                      subtasks = subtasks?.map((sub) => {
                        return {
                          ...sub,
                          start_date: format(
                            new Date(
                              new Date(sub.start_date).getTime() +
                                startDate?.getTime() -
                                new Date(item.start_date).getTime(),
                            ),
                            'yyyy-MM-dd',
                          ),
                        };
                      });
                    }
                    return {
                      ...item,
                      name: title,
                      description,
                      subtasks,

                      start_date: format(startDate, 'yyyy-MM-dd'),
                    };
                  } else {
                    return item;
                  }
                });

                const newRoadmap = { ...data, body: newArray };
                setData(newRoadmap);
                onClose();
              }}
            />
          </div>
        </div>
      </SidebarModal>
    </>
  );
};

export default Edit;

import { useEffect, useState } from 'react';
import { getGroupRoadmapStats } from '../../../../services/roadmapTask/roadmapStatsService';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import { Button } from '../../../../components/forms/Buttons/Button';

const GroupRoadmapStats = ({ onSelect, org, goBack }) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  // const colors = [
  //   'from-red-500 to-red-200',
  //   'from-violet-600 to-violet-200',
  //   'from-green-400 to-blue-400',
  // ];

  useEffect(() => {
    if (org) {
      setLoading(true);
      getGroupRoadmapStats({ page_size: 100, organization: org?.uuid })
        ?.then((res) => {
          setGroups(res?.results);
        })
        ?.finally(() => setLoading(false));
    }
  }, [org]);

  return (
    <>
      <p className='font-bold text-lg'>{org?.name}</p>
      <Button onClick={() => goBack()} text='Back' />
      <div className='flex flex-row flex-wrap py-4 gap-4'>
        {loading ? (
          <SimpleLoader className='h-8' />
        ) : (
          groups?.map((group, i) => {
            return (
              <div
                onClick={() => onSelect({ name: group.name, uuid: group.uuid })}
                key={group.uuid}
                className={`relative w-80 h-48 rounded-xl border-4 cursor-pointer
        flex flex-col justify-between text-lgfont-bold bg-gradient-to-tr group from-green-400 to-blue-400`}
              >
                <div className='flex flex-col h-full justify-between p-4 '>
                  <div className='flex flex-col'>
                    <span className='text-white text-6xl font-black'>
                      {group.name}
                    </span>
                  </div>
                </div>
                <div className='bg-white flex flex-row justify-between p-1 px-2'>
                  <div className='flex flex-row gap-4'>
                    <div className='flex flex-col items-center -space-y-2'>
                      <span className='text-lg font-semibold'>
                        {group.total_students}
                      </span>
                      <span className='text-gray-500 text-sm'>Students</span>
                    </div>
                    <div className='flex flex-col items-center -space-y-2'>
                      <span className='text-lg font-semibold'>
                        {group.has_roadmap}
                      </span>
                      <span className='text-gray-500 text-sm'>
                        With Roadmap
                      </span>
                    </div>
                    <div className='flex flex-col items-center -space-y-2'>
                      <span className='text-lg font-semibold'>
                        {group.doing_roadmap}
                      </span>
                      <span className='text-gray-500 text-sm'>In Progress</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default GroupRoadmapStats;

import bilAPI from '../api';

export const postProjectLike = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/projects/project/${uuid}/like_project/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postProjectDislike = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/projects/project/${uuid}/dislike_project/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

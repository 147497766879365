import React, { useState } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import InputWithIcon from '../../../../../components/forms/Inputs/InputWithIcon';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  MagnifyingGlassIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import {
  patchFileContent,
  patchProjectContent,
  patchTextContent,
  patchVideoContent,
} from '../../../../../services/courses/contentService';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import RichTextEditor from '../../../../../components/forms/Inputs/RichTextEditor';
import Upload from '../../../../../components/upload/Upload';
import { getProjects } from '../../../../../services/projects/projectService';
import { toast } from 'react-toastify';

const Edit = ({ refreshData, data }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [projectCode, setProjectCode] = useState('');
  const [project, setProject] = useState(data.project_content?.project || null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
    setProjectCode('');
    setProject();
  };

  const patch = (body) => {
    if (data.text_content) {
      body.uuid = data.text_content.uuid;
      return patchTextContent(body);
    } else if (data.project_content) {
      body.uuid = data.project_content.uuid;
      return patchProjectContent(body);
    } else if (data.video_content) {
      body.uuid = data.video_content.uuid;
      return patchVideoContent(body);
    } else if (data.file_content) {
      body.append('uuid', data.file_content.uuid);
      return patchFileContent(data.file_content.uuid, body);
    }
  };

  const onSubmit = (data) => {
    let body = { ...data };
    if (data.file_content) {
      body = new FormData();
      Object.keys(data).forEach((k) => {
        body.append(k, data[k]);
      });
    }
    patch(body)?.then(() => {
      refreshData();
      onClose();
    });
  };

  return (
    <>
      <PencilSquareIcon
        className='text-primary h-9 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-2'
        onClick={() => setOpen(true)}
      />
      <CustomDialog isOpen={open} closeModal={() => onClose()}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-4 pt-4'
        >
          <div className='flex flex-col w-full gap-2'>
            {data.text_content && (
              <Controller
                control={control}
                name='text'
                defaultValue={data.text_content?.text}
                render={({ field }) => (
                  <>
                    <RichTextEditor
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                    <span>
                      To write the formula please use{' '}
                      <a
                        className='text-primary hover:underline'
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.overleaf.com/learn/latex/Integrals%2C_sums_and_limits'
                      >
                        LaTeX
                      </a>
                    </span>
                  </>
                )}
              />
            )}

            {data.video_content && (
              <Input
                errorMsg={errors.name}
                required
                label='URL'
                {...register('url', {
                  required: 'URL is required field',
                  value: data.video_content.url,
                })}
              />
            )}
            {data.file_content && (
              <Controller
                control={control}
                name='file'
                render={({ field }) => (
                  <>
                    <Upload
                      file={field.value}
                      setFile={(e) => {
                        field.onChange(e);
                      }}
                    />
                  </>
                )}
              />
            )}
            {data.project_content && (
              <Controller
                control={control}
                name='project'
                render={({ field }) => (
                  <>
                    <InputWithIcon
                      label='Project code'
                      value={projectCode}
                      Icon={MagnifyingGlassIcon}
                      onChange={(e) => setProjectCode(e.target.value)}
                      onIconClick={() =>
                        getProjects({ code: projectCode }).then((res) => {
                          if (!res.count) {
                            toast(
                              `There is no Project with code "${projectCode}"`,
                            );
                            return;
                          }
                          field.onChange(res.results[0].uuid);
                          setProject(res.results[0]);
                        })
                      }
                    />
                    {project?.name}
                  </>
                )}
              />
            )}
          </div>
          <div className='flex flex-row justify-end gap-2 '>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              onClick={() => onClose()}
              type='button'
            />
            <Button color='success' text={'Edit'} type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Edit;

import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/Input';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  addVideoConferenceToCalendar,
  getConferenceRunningStatus,
  joinVideoConference,
} from '../../../services/videoConference/videoConferenceService';
import { UserContext } from '../../../utils/contexts/UserContext';
import { toast } from 'react-toastify';

export default function JoinVideoConference() {
  const { uuid } = useParams();
  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    // reset,
  } = useForm();

  const [canJoin, setCanJoin] = useState(false);

  useEffect(() => {
    if (user?.uuid) {
      setValue('fullname', `${user?.first_name} ${user?.last_name}`);
    }

    return () => {};
  }, [user, setValue]);

  useEffect(() => {
    if (uuid) {
      const fetchData = async () => {
        try {
          getConferenceRunningStatus(uuid).then((res) => {
            setCanJoin(res?.is_running);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Fetch data initially
      fetchData();

      // Set up interval to fetch data every 20 seconds
      const intervalId = setInterval(fetchData, 20000);

      // Clean up function to clear interval when component unmounts
      return () => clearInterval(intervalId);
    }
  }, [uuid]);

  const onSubmit = (data) => {
    const body = { ...data };
    joinVideoConference(uuid, body)
      .then((res) => window.open(res.link, '_blank').focus())
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <div className='flex flex-row justify-center w-full h-full items-center'>
      <div className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 md:min-w-[32rem] bg-white p-8 rounded border-2'>
        <h1 className='text-2xl font-semibold mb-4 text-center'>
          Enter Your Full Name
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <Input
              errorMsg={errors.name}
              required
              label='Full name'
              {...register('fullname', {
                required: 'title is required field',
              })}
            />
          </div>
          {!canJoin && (
            <div className='flex flex-row justify-end italic text-sm mb-1'>
              Please wait conference to start
            </div>
          )}
          <div className='flex flex-row justify-between'>
            {user?.uuid && (
              <Button
                text='Add to clendar'
                type='button'
                color='primary'
                onClick={() => {
                  addVideoConferenceToCalendar(uuid).then(() => {
                    toast.success('Conference added to your calendar');
                  });
                }}
              />
            )}
            <Button
              disabled={!canJoin}
              text='Join Conference'
              type='submit'
              color='success'
            />
          </div>
        </form>
      </div>
    </div>
  );
}

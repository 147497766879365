import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/contexts/UserContext';
import { Tab } from '@headlessui/react';
import Title from './Title';
import { classNames } from '../../utils/helpers/helpers';
import {
  getOrganizationsStats,
  getOverallStatusStats,
} from '../../services/stats/statsService';
import ProjectStats from './ProjectStats/ProjectStats';
import GeneralStats from './GeneralStats/GeneralStats';
import { t } from 'i18next';

export default function Stats() {
  const { user } = useContext(UserContext);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAdmin, setIsAdmin] = useState(null);

  // data by status in Projects tab
  // server already returns filtered data
  const [overallByStatus, setOverallByStatus] = useState([]);
  const [organizationsByStatus, setOrganizationsByStatus] = useState([]);

  useEffect(() => {
    getOrganizationsStats({ page_size: 80 })
      .then((res) => {
        setOrganizationsByStatus(res?.results);

        // if have many organizations => admin
        if (res?.results?.length > 1) {
          setIsAdmin(true);

          getOverallStatusStats({})
            .then((res) => {
              setOverallByStatus(res?.result);
            })
            .catch(() => {});
        }

        //if vp
        if (res?.results?.length === 1) {
          setIsAdmin(false);
        }
      })
      .catch(() => {})
      .finally(() => {
        // setLoading(false);
        // setSelectedSchool('all');
      });

    return () => {};
  }, []);

  return (
    <div className='flex flex-col gap-4 p-4'>
      <Title />
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className='flex flex-row items-center md:gap-4 gap-2'>
          <div className='font-medium'>{t('stats.select_by')}</div>
          <Tab
            key={0}
            className={({ selected }) =>
              classNames(
                'rounded-3xl md:px-5 lg:px-5 px-4 py-2 font-medium leading-5',
                '',
                selected
                  ? 'bg-blue-600 text-white hover:bg-blue-900'
                  : 'text-blue-600 bg-blue-600 bg-opacity-20 hover:bg-opacity-100 hover:text-white',
              )
            }
          >
            {t('stats.general')}
          </Tab>
          <Tab
            key={1}
            className={({ selected }) =>
              classNames(
                'rounded-3xl md:px-5 lg:px-5 px-4 py-2 font-medium leading-5',
                '',
                selected
                  ? 'bg-blue-600 text-white hover:bg-blue-900'
                  : 'text-blue-600 bg-blue-600 bg-opacity-20 hover:bg-opacity-100 hover:text-white',
              )
            }
          >
            {t('stats.projects.projects')}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel unmount={false}>
            <GeneralStats isAdmin={isAdmin} user={user} />
          </Tab.Panel>
          <Tab.Panel unmount={false}>
            <ProjectStats
              overall={overallByStatus}
              data={organizationsByStatus}
              isAdmin={isAdmin}
              user={user}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

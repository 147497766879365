import { toast } from 'react-toastify';
import { Button } from '../../../../../components/forms/Buttons/Button';

export default function Delete({ name, setData }) {
  const handleDeleteConfirmation = (deleteFunc) => {
    toast.dark(
      <>
        <div>Are you sure you want to delete the task? </div>
        <span className='text-xs'>Deleted tasks can’t be recovered</span>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text='Yes'
            color='error-solid'
            onClick={() => deleteFunc()}
            autoFocus
          />
          <Button text='No' className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };
  return (
    <Button
      color='error-solid'
      text='Delete'
      className='max-h-6'
      onClick={() => {
        handleDeleteConfirmation(() =>
          setData((prev) => {
            const newArray = prev?.body?.filter(function (obj) {
              return obj.name !== name;
            });
            return { ...prev, body: newArray };
          }),
        );
      }}
    />
  );
}

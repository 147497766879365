import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getStudyPlan,
  postStudyPlans,
} from '../../services/studyPlans/studyPlanService';
import bgImage from '../../assets/images/bg.jpg';
import { Button } from '../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import {
  CheckIcon,
  ChevronLeftIcon,
  ClockIcon,
  DocumentTextIcon,
  PlayIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import avatarPlaceholder from '../../assets/images/avatar_placeholder.jpg';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { UserContext } from '../../utils/contexts/UserContext';
import RichTextEditor from '../../components/forms/Inputs/RichTextEditor';

const StudyPlan = () => {
  const [t] = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [studyPlan, setStudyPlan] = useState();
  const [sectionIndex, setSectionIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [loadingStudyPlans, setLoadingStudyPlans] = useState(false);

  const getStudyPlanData = () => {
    setLoadingStudyPlans(true);
    getStudyPlan(uuid)
      .then((res) => {
        // console.log('res', res);
        setStudyPlan(res);
      })
      .catch(() => {})
      .finally(() => setLoadingStudyPlans(false));
  };

  console.log('studyPlan', studyPlan);


  useEffect(() => {
    if (uuid) {
      getStudyPlanData();
    }
    // eslint-disable-next-line
  }, [uuid]);
  return (
    <div>
      {loadingStudyPlans ? (
        <div className='pt-12'>
          <SimpleLoader className={'h-12'} />
        </div>
      ) : (
        <div className='flex flex-col'>
          <div
            className='h-48 flex flex-row'
            style={{
              backgroundImage: `url(${studyPlan?.background ?? bgImage})`,
              backgroundPosition: 'center',
            }}
          >
            <div className='w-1/3 px-4 py-8'>
              <Button
                text={t('buttons.back')}
                color='secondary-solid'
                Icon={ChevronLeftIcon}
                onClick={() => navigate(-1)}
              />
            </div>
            <div className='text-white w-2/3 flex flex-col h-full justify-end gap-8 px-8 py-8'>
              <div className='text-2xl lg:text-4xl font-medium pl-4'>
                {studyPlan?.title}
              </div>
              {user?.roles?.some((role) => role?.name === 'STUDENT') ? (
                studyPlan?.status === 'not-started' ? (
                  <div className='flex flex-row justify-end'>
                    <div>
                      <Button
                        text={t('projects.start')}
                        color='success'
                        Icon={PlayIcon}
                        loading={loading}
                        onClick={() => {
                          setLoading(true);
                          postStudyPlans(uuid)
                            .then(() => {
                              getStudyPlanData();
                            })
                            .catch(() => {})
                            .finally(() => setLoading(false));
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='flex flex-row items-center gap-4'>
                    <div className='w-1/2 relative'>
                      <div className='absolute h-3 bg-secondary w-full rounded-xl -top-1'></div>
                      <div
                        className='absolute h-3 bg-primary rounded-xl -top-1'
                        style={{
                          width: `${
                            (studyPlan?.accepted_projects_count /
                              studyPlan?.projects_count) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <div className=''>{`${studyPlan?.accepted_projects_count}/${studyPlan?.projects_count}`}</div>
                  </div>
                )
              ) : (
                <div className='h-6'></div>
              )}
            </div>
          </div>

          <div className='flex lg:flex-row md:flex-row flex-col'>
            <div className='lg:w-1/3 md:w-1/3 px-4'>
              <div
                className='rounded-xl border shadow-lg py-4 px-6 flex flex-col divide-y-2
                w-full bg-white lg:-mt-12 md:-mt-12 -mt-4 font-medium lg:text-lg'
              >
                <div
                  className={`py-2 flex flex-row items-center gap-2 cursor-pointer ${
                    sectionIndex === -1 ? 'font-bold pl-2' : ''
                  }`}
                  onClick={() => setSectionIndex(-1)}
                >
                  <DocumentTextIcon className='h-4' />
                  {t('studyplans.overview')}
                </div>
                {studyPlan?.sections?.map((s, index) => (
                  <div
                    key={index}
                    className={`py-3 flex flex-row items-center gap-2 cursor-pointer leading-tight ${
                      sectionIndex === index ? 'font-bold pl-2' : ''
                    }`}
                    onClick={() => setSectionIndex(index)}
                  >
                    {s?.status === 'in-progress' ? (
                      <PlayIcon className='h-4 text-success' />
                    ) : s?.status === 'waiting' ? (
                      <ClockIcon className='h-4 text-error' />
                    ) : s?.status === 'checking' ? (
                      <ClockIcon className='h-4 text-primary' />
                    ) : s?.status === 'declined' ? (
                      <XMarkIcon className='h-4 text-error' />
                    ) : s?.status === 'accepted' ? (
                      <CheckIcon className='h-4 text-success' />
                    ) : (
                      <div
                        className='h-4 w-4 rounded-full bg-secondary shrink-0
                              flex flex-row justify-center items-center'
                      >
                        <div className='h-[0.6rem] w-[0.6rem] rounded-full bg-white'></div>
                      </div>
                    )}
                    {s?.title}
                  </div>
                ))}
              </div>
            </div>
            <div className='lg:w-2/3 md:w-2/3 py-6 px-4'>
              {sectionIndex === -1 ? (
                <div className='flex flex-col gap-4'>
                  <div className='flex flex-row items-center'>
                    <div className='shrink-0'>
                      <img
                        src={
                          studyPlan?.author?.profile_picture ||
                          avatarPlaceholder
                        }
                        alt='avatar'
                        className='md:w-20 md:h-20 lg:w-20 lg:h-20 w-12 h-12 
                  rounded-full object-cover'
                      />
                    </div>
                    <div className='flex flex-col'>
                      <div>{`${studyPlan?.author?.first_name} ${studyPlan?.author?.last_name}`}</div>
                      <div className='text-primary'>
                        {t('studyplans.author')}
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col'>
                    <div className='font-medium text-lg'>
                      {t('projects.description')}
                    </div>
                    <div className='text-gray-600'>
                      <RichTextEditor value={studyPlan?.description} readOnly />
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col'>
                  <div className='text-lg font-medium'>
                    {studyPlan?.sections[sectionIndex]?.title}
                  </div>
                  <div className='text-gray-700'>
                    <RichTextEditor
                      value={studyPlan?.sections[sectionIndex]?.description}
                      readOnly
                    />
                  </div>

                  <div className='rounded-2xl border mt-8'>
                    <div className='bg-gray-200 rounded-t-2xl py-2 px-4'>
                      {t('studyplans.projects')}
                    </div>
                    <table className='table-auto w-full'>
                      <tbody>
                        {studyPlan?.sections[sectionIndex]?.projects?.map(
                          (p) => (
                            <tr
                              className='border-b cursor-pointer hover:bg-gray-100'
                              key={p?.uuid}
                              onClick={() =>
                                navigate(`${'/projects/' + p?.uuid}`)
                              }
                            >
                              <td className='pl-2 pr-1 py-3'>
                                {p?.status === 'in-progress' ? (
                                  <div className='text-xs whitespace-nowrap text-success flex flex-row gap-1 items-center'>
                                    <PlayIcon className='h-4' />
                                    <span className='whitespace-nowrap hidden lg:block'>
                                      {t('projects.in_progress')}
                                    </span>
                                  </div>
                                ) : p?.status === 'waiting' ? (
                                  <div className='text-xs whitespace-nowrap text-error flex flex-row gap-1 items-center'>
                                    <ClockIcon className='h-4' />
                                    <span className='whitespace-nowrap hidden lg:block'>
                                      {t('projects.waiting')}
                                    </span>
                                  </div>
                                ) : p?.status === 'checking' ? (
                                  <div className='text-xs whitespace-nowrap text-primary flex flex-row gap-1 items-center'>
                                    <ClockIcon className='h-4' />
                                    <span className='whitespace-nowrap hidden lg:block'>
                                      {t('projects.checking')}
                                    </span>
                                  </div>
                                ) : p?.status === 'declined' ? (
                                  <div className='text-xs whitespace-nowrap text-error flex flex-row gap-1 items-center'>
                                    <XMarkIcon className='h-4' />
                                    <span className='whitespace-nowrap hidden lg:block'>
                                      {t('projects.declined')}
                                    </span>
                                  </div>
                                ) : p?.status === 'accepted' ? (
                                  <div className='text-xs whitespace-nowrap text-success flex flex-row gap-1 items-center'>
                                    <CheckIcon className='h-4' />
                                    <span className='whitespace-nowrap hidden lg:block'>
                                      {t('projects.accepted')}
                                    </span>
                                  </div>
                                ) : (
                                  <div className='text-xs whitespace-nowrap flex flex-row gap-1 items-center'>
                                    <div
                                      className='h-4 w-4 rounded-full bg-secondary 
                              flex flex-row justify-center items-center shrink-0'
                                    >
                                      <div className='h-[0.6rem] w-[0.6rem] rounded-full bg-white'></div>
                                    </div>
                                    <span className='whitespace-nowrap hidden lg:block'>
                                      {t('projects.not_started')}
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td className='px-1 lg:text-base text-sm'>
                                {p?.name}
                              </td>
                              <td className='px-1 font-light text-sm'>
                                {p?.skill?.name}
                              </td>
                              <td
                                className={`text-sm pl-1 pr-2 ${
                                  p?.level === 'Beginner'
                                    ? 'text-primary'
                                    : p?.level === 'Easy'
                                    ? 'text-success'
                                    : p?.level === 'Medium'
                                    ? 'text-warning'
                                    : p?.level === 'Hard'
                                    ? 'text-error'
                                    : 'text-secondary'
                                }`}
                              >
                                {p?.level}
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudyPlan;

import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { replaceHtmlEntities } from '../../../../utils/helpers/helpers';
import { getTranlated } from '../../helper';
import { t } from 'i18next';

const Programs = ({ info, facultiesOpen, setFacultiesOpen }) => {
  return (
    <div className='flex flex-col gap-6 border rounded-xl p-4'>
      <div className='flex flex-row gap-1'>
        <p className='text-primary'>
          {t('universities.university_page.language_of_instruction')}
        </p>
        <p className=''>{getTranlated('studyLanguage', info)?.toLowerCase()}</p>
      </div>
      <div className='flex flex-col gap-4'>
        {info?.eduDegrees?.map((d, index) => (
          <div key={index} className='flex flex-col gap-2'>
            <div className='text-lg text-primary'>
              {getTranlated('degree', d)}
            </div>
            <div>
              {replaceHtmlEntities(
                getTranlated('description', d)?.replace(/(<([^>]+)>)/gi, ''),
              )}
            </div>
            <div className='flex flex-col divide-y'>
              {d?.faculties?.map((f, iindex) => (
                <div key={iindex} className='flex flex-col py-1'>
                  <div
                    className='flex flex-row items-center gap-2 cursor-pointer text-lg'
                    onClick={() => {
                      const temp = [...facultiesOpen];
                      temp[index][iindex] = !temp[index][iindex];
                      setFacultiesOpen(temp);
                    }}
                  >
                    {facultiesOpen[index][iindex] ? (
                      <ChevronDownIcon className='h-4' />
                    ) : (
                      <ChevronRightIcon className='h-4' />
                    )}
                    <div>{getTranlated('name', f)}</div>
                  </div>
                  {facultiesOpen[index][iindex] && (
                    <div className='flex flex-row flex-wrap gap-y-2 gap-x-12 px-8 py-2'>
                      {f?.specialities?.map((s) => (
                        <div key={s?.id}>{getTranlated('name', s)}</div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;

import React, { useState } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextArea from '../../../../../components/forms/Inputs/TextArea';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { patchSection } from '../../../../../services/courses/sectionService';

const Edit = ({ refreshData, section }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (data) => {
    const body = { ...data };
    body.uuid = section.uuid;
    patchSection(body)
      .then(() => refreshData())
      .catch(() => {})
      .finally(() => onClose());
  };

  return (
    <>
      <PencilSquareIcon
        className='text-primary h-9 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-2'
        onClick={() => setOpen(true)}
      />
      <CustomDialog isOpen={open} closeModal={() => onClose()}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-4 pt-4'
        >
          <div className='w-full'>
            <Input
              errorMsg={errors.name}
              required
              label='title'
              {...register('name', {
                required: 'title is required field',
                value: section.name,
              })}
            />
            <TextArea
              errorMsg={errors.name}
              required
              rows={7}
              label='description'
              {...register('description', {
                required: 'description is required field',
                value: section.description,
              })}
            />
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <Button
              color='secondary'
              text={t('buttons.cancel')}
              onClick={() => setOpen(false)}
              type='button'
            />
            <Button color='success' text={t('buttons.create')} type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Edit;

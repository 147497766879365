import React from 'react';

const CircleProgressBar = ({ progress }) => {
  const size = 44; // размер SVG элемента
  const strokeWidth = 4; // толщина линии прогресса
  const radius = (size - strokeWidth) / 2; // радиус круга с учетом толщины линии
  const circumference = 2 * Math.PI * radius; // длина окружности
  const offset = circumference - (progress / 100) * circumference; // смещение для анимации прогресса

  return (
    <div className='relative w-11 h-11'>
      {/* Использование Tailwind для задания размера и позиционирования */}
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill='transparent'
          stroke='#D1D5DB' /* серый цвет из палитры Tailwind */
          strokeWidth={strokeWidth}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill='transparent'
          stroke='#10B981' /* зеленый цвет из палитры Tailwind */
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs'>
        {/* Использование Tailwind для позиционирования и размера шрифта */}
        {progress}%
      </div>
    </div>
  );
};

export default CircleProgressBar;

import React from 'react';
import TextContent from './TextContent';
import VideoContent from './VideoContent';
import ProjectContent from './ProjectContent';
import QuestionContent from './QuestionContent';
import FileContent from './FileContent';

const Content = ({ data, refreshData }) => {
  return (
    <div className='flex flex-col p-4 border rounded-lg'>
      {/* {`${data.order}.`} */}
      {data?.text_content && <TextContent data={data.text_content} />}
      {data?.video_content && <VideoContent data={data.video_content} />}
      {data?.project_content && <ProjectContent data={data.project_content} />}
      {data?.question_content && (
        <QuestionContent
          data={data.question_content}
          refreshData={refreshData}
        />
      )}
      {data?.file_content && <FileContent data={data.file_content} />}
    </div>
  );
};
export default Content;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { signInWithSSO, signUpWithSSO } from '../../services/sso/SSOService';
import TokenService from '../../services/token.service';
import useAuth from '../../utils/hooks/useAuth';
import BIFIcon from '../../components/Icons/BIFIcon';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

const SSOBilOrgKz = ({ className }) => {
  const [t] = useTranslation();

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { setUserContext } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const DOMAIN = 'https://bil.org.kz';
  const PATH = 'ssop';
  const w = 500;
  const h = 600;
  const left = (window.innerWidth - 500) / 2;
  const top = (window.innerHeight - 600) / 2;
  const messageListenerExecuted = useRef(false);

  const logError = useCallback((error) => {
    toast.error('Something went wrong');
    // console.log(error.response);
    messageListenerExecuted.current = false;
  }, []);

  const signOnWithSSOData = useCallback(
    ({ accessToken }) => {
      setIsLoading(true);
      const body = {
        auth_provider: DOMAIN,
        auth_provider_token: accessToken,
      };
      signUpWithSSO(body)
        .then((res) => {
          TokenService.updateLocalAccessToken(res.access_token);
          TokenService.updateLocalRefreshToken(res.refresh_token);
          setUserContext(res.access_token);
        })
        .catch((error) => {
          logError(error);
        })
        .finally(() => setIsLoading(false));
    },
    [logError, setUserContext],
  );

  const signInWithSSOData = useCallback(
    ({ accessToken }) => {
      setIsLoading(true);
      const body = {
        auth_provider: DOMAIN,
        auth_provider_token: accessToken,
      };
      signInWithSSO(body)
        .then((res) => {
          TokenService.updateLocalAccessToken(res.access_token);
          TokenService.updateLocalRefreshToken(res.refresh_token);
          setUserContext(res.access_token);
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            signOnWithSSOData({ accessToken });
          } else {
            logError(error);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [logError, setUserContext, signOnWithSSOData],
  );

  const messageListener = useCallback(
    (event) => {
      if (event.origin !== DOMAIN) return;
      let accessToken = event.data.accessToken;
      if (!accessToken) return;
      if (messageListenerExecuted.current) return;
      window.removeEventListener('message', messageListener);
      messageListenerExecuted.current = true;
      signInWithSSOData({ accessToken });
    },
    [messageListenerExecuted, signInWithSSOData],
  );

  useEffect(() => {
    if (!messageListenerExecuted.current) {
      window.addEventListener('message', messageListener);
    }
  }, [messageListener, messageListenerExecuted]);

  return (
    <>
      <Button
        loading={isLoading}
        Icon={() => <BIFIcon className='h-7' />}
        color='clear'
        type='button'
        text={t('login.continue_with', { service: 'bil.org.kz' })}
        className={`gap-2 ${className}`}
        onClick={() => {
          if (isLoading) {
            return;
          }
          window.open(
            `${DOMAIN}/${PATH}`,
            'bilOrgKzTab',
            !isMobile &&
              `width=${w},height=${h},top=${top},left=${left},resizable=no`,
          );
        }}
      />
    </>
  );
};

export default SSOBilOrgKz;

import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import {
  deleteResultDescription,
  patchResultDescription,
} from '../../../services/tests/resultService';
import AddProfession from './Professions/AddProfession';
import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import Edit from './Edit';
import Files from './Files/Files';

const ResultDesciptionCard = ({
  description,
  refreshData,
  professions,
  refreshProfessions,
}) => {
  const imageRef = useRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    if (!window.confirm('Are you sure you want to update the image?')) {
      return;
    }

    if (e.target.files[0].type.substring(0, 5) !== 'image') {
      toast.error(
        'Жүктелген файл сурет болуы керек! / Загружаемый вами файл должен быть изображением!',
        'error',
      );
      return;
    }
    if (e.target.files[0].size > 1048576) {
      toast.error(
        'Жүктелген файл 1 мегабайттан аспау керек! / Загружаемый вами файл не должен превышать 1 МБ',
        'error',
      );
      return;
    }
    let file = e.target.files[0];
    const body = new FormData();
    body.append('image', file);

    patchResultDescription(description.uuid, body)
      ?.then(() => {
        toast.success('Image success updated');
        refreshData();
      })
      ?.catch(() => toast.error('Image update error'));
  };
  return (
    <div className='flex flex-row border rounded-lg p-4 justify-between items-center relative min-h-[12rem]'>
      <div className='w-1/3'>
        {description.image ? (
          <img
            className='w-fit max-h-[200px]  cursor-pointer'
            src={description.image}
            alt='MBTI personality'
            onClick={() => imageRef.current.click()}
          />
        ) : (
          <PhotoIcon
            className='h-16 text-blue-500 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-3'
            onClick={() => imageRef.current.click()}
          />
        )}
        <input hidden type='file' ref={imageRef} onChange={handleImageChange} />
      </div>

      <span className='font-semibold text-lg text-center'>
        {description.result?.result}
      </span>
      <AddProfession
        result_description={description}
        professions={professions}
        refreshData={refreshData}
        refreshProfessions={refreshProfessions}
      />
      <div className='absolute top-5 right-5 flex flex-row gap-2'>
        <Files description={description} refreshData={refreshData} />
        <Edit description={description} refreshData={refreshData} />
        <TrashIcon
          className='text-red-500 h-9 cursor-pointer hover:bg-error bg-red-100 hover:text-white rounded-lg p-2'
          onClick={() => {
            if (!window.confirm('Are you sure you want to delete?')) return;
            deleteResultDescription(description?.uuid)
              ?.then(() => {
                toast.success(
                  `Description "${description?.result?.result}" deleted`,
                );
                refreshData();
              })
              .catch(() => {
                toast.error(
                  `Error on deleting test "${description?.result?.result}"`,
                );
              });
          }}
        />
      </div>
    </div>
  );
};
export default ResultDesciptionCard;

import bilAPI from '../api';

export const postDraftRoadmap = ({ student_uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/roadmap/draft_roadmaps/`, { student_uuid })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getRoadmapDirections = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/directions/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postRoadmapSurvey = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/roadmap/survey/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const postRoadmapUpdate = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/roadmap/roadmap_update/`, body)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getRoadmapUpdate = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/roadmap/roadmap_update/`, { params })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

import React, { useCallback, useEffect, useState } from 'react';
import MobileSidebarModal from '../../../components/modals/MobileSidebarModal/MobileSidebarModal';
import EmptyModal from '../../../components/modals/EmptyModal/EmptyModal';
import { useMediaQuery } from 'react-responsive';
import Input from '../../../components/forms/Inputs/InputBil';
import TextArea from '../../../components/forms/Inputs/TextArea';
import Select from '../../../components/forms/Select/Select';
import { Button } from '../../../components/forms/Buttons/Button';
import { patchInstruction } from '../../../services/instructions/instructionService';
import { toast } from 'react-toastify';
import Upload from '../../../components/upload/Upload';

const Edit = ({ datum, setId, uuid, setDatum, refreshData }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState();
  const [link, setLink] = useState('');
  const [image, setImage] = useState();

  useEffect(() => {
    if (datum) {
      setTitle(datum.title);
      setDescription(datum.description);
      setLink(datum.link);
      setImage(datum.image);
      console.log(datum);
      setType(datum.type);
      setOpen(true);
    } else setOpen(false);
  }, [datum]);

  return (
    <div>
      {isMobile ? (
        <MobileSidebarModal
          headerText={'Edit'}
          uuid={uuid}
          onClose={() => setId('')}
        >
          <div className='flex flex-col gap-2 w-96 -mt-2'>
            <div>
              <Input
                label={'Title'}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                value={title}
                required
              />
            </div>
            <div className='flex flex-col'>
              <p>
                Type <span className={`text-error`}>*</span>
              </p>
              <Select
                options={[
                  { value: 0, label: 'Documentation link' },
                  { value: 1, label: 'Video instruction' },
                ]}
                placeholder={'Select a type'}
                value={type}
                onChange={(e) => setType(e?.value)}
              />
            </div>
            <div>
              <TextArea
                label={'Description'}
                rows={6}
                //   className='border rounded-lg shadow-md'
                placeholder={'Enter text...'}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <Input
                label={'Link'}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
                value={link}
                required
              />
            </div>
            {type === 1 && (
              <div className='flex flex-col gap-1'>
                <p>Image</p>
                <Upload
                  file={image}
                  setFile={setImage}
                  accept={'image/*'}
                  size='sm'
                />
              </div>
            )}
            <div className='flex flex-row justify-end mt-2'>
              <Button
                text='Save'
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  const body = new FormData();
                  body.append('title', title);
                  body.append('type', type);
                  body.append('description', description);
                  body.append('link', link);
                  if (image instanceof File) {
                    body.append('image', image);
                  }

                  patchInstruction(datum?.uuid, body)
                    .then((res) => {
                      refreshData();
                      toast.success('Changes has been saved');
                      setId('');
                    })
                    .catch((e) => toast.error('Something went wrong'))
                    .finally(() => setLoading(false));
                }}
              />
            </div>
          </div>
        </MobileSidebarModal>
      ) : (
        <EmptyModal
          open={open}
          setOpen={setOpen}
          onClose={() => setDatum(null)}
          headerText={'Edit'}
        >
          <div className='flex flex-col gap-2 w-96 -mt-2'>
            <div>
              <Input
                label={'Title'}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                value={title}
                required
              />
            </div>
            <div className='flex flex-col'>
              <p>
                Type <span className={`text-error`}>*</span>
              </p>
              <Select
                options={[
                  { value: 0, label: 'Documentation link' },
                  { value: 1, label: 'Video instruction' },
                ]}
                placeholder={'Select a type'}
                value={type}
                onChange={(e) => setType(e?.value)}
              />
            </div>
            <div>
              <TextArea
                label={'Description'}
                rows={6}
                //   className='border rounded-lg shadow-md'
                placeholder={'Enter text...'}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <Input
                label={'Link'}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
                value={link}
                required
              />
            </div>
            {type === 0 && (
              <div className='flex flex-col gap-1'>
                <p>Image</p>
                <Upload
                  file={image}
                  setFile={setImage}
                  accept={'image/*'}
                  size='sm'
                />
              </div>
            )}
            <div className='flex flex-row justify-end mt-2'>
              <Button
                text='Save'
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  const body = new FormData();
                  body.append('title', title);
                  body.append('type', type);
                  body.append('description', description);
                  body.append('link', link);
                  if (image instanceof File) {
                    body.append('image', image);
                  }

                  patchInstruction(datum?.uuid, body)
                    .then((res) => {
                      refreshData();
                      toast.success('Changes has been saved');
                      setDatum(null);
                    })
                    .catch((e) => toast.error('Something went wrong'))
                    .finally(() => setLoading(false));
                }}
              />
            </div>
          </div>
        </EmptyModal>
      )}
    </div>
  );
};

export default Edit;

import React from 'react';

export default function FlagEN({ className }) {
  return (
    <svg
      width='34'
      height='24'
      viewBox='0 0 34 24'
      className={`${className ?? ' w-6 h-6 text-primary'}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7501_653)'>
        <path
          d='M33.9926 9.7501V14.2501C29.1523 14.2501 24.3094 14.2479 19.4638 14.2435C19.1982 14.2435 19.1149 14.297 19.1158 14.5998C19.1282 17.5998 19.1238 20.5932 19.1229 23.5904C19.1229 23.7301 19.1096 23.8717 19.1034 24.0104H14.8959C14.8888 23.8707 14.8764 23.7292 14.8764 23.5904C14.8764 20.6054 14.8693 17.6195 14.8835 14.6345C14.8835 14.3026 14.795 14.2435 14.5019 14.2435C9.66929 14.2529 4.83728 14.2551 0.00585938 14.2501V9.7501C4.84614 9.7501 9.68907 9.75229 14.5347 9.75666C14.8003 9.75666 14.8888 9.70416 14.8826 9.40135C14.8702 6.40135 14.8747 3.40697 14.8764 0.409785C14.8764 0.270098 14.8879 0.128535 14.895 -0.00927734H19.1043C19.1105 0.13041 19.1229 0.271973 19.1229 0.409785C19.1229 3.40979 19.1282 6.40416 19.1158 9.40135C19.1158 9.70416 19.1973 9.7576 19.4638 9.75666C24.307 9.74854 29.1499 9.74635 33.9926 9.7501Z'
          fill='#D70027'
        />
        <path
          d='M19.1048 -0.00863361H13.2673H3.61622H0.0435651C0.0321438 -0.0145961 0.0203059 -0.0196115 0.00815169 -0.023634C0.00815169 -1.90801 0.0134635 -3.79238 0.00195312 -5.67676C0.00195312 -5.9327 0.044449 -6.00488 0.302105 -6.00488C6.92502 -5.99551 13.5473 -5.99301 20.1691 -5.99738C24.6912 -5.99738 29.2127 -5.99988 33.7337 -6.00488C33.9701 -6.00488 33.9993 -5.94301 33.9993 -5.71238C33.9887 -3.79238 33.9931 -1.86863 33.9931 0.0466785L33.862 0.0513649C33.828 0.0224297 33.7889 0.00104294 33.7469 -0.0115204C33.7049 -0.0240837 33.661 -0.0275662 33.6177 -0.02176L32.2417 -0.00863361L20.7313 -0.0142584L19.1048 -0.00863361Z'
          fill='#FEFEFE'
        />
        <path
          d='M14.8963 24.0094H20.7329H30.384H33.9575C33.9682 24.0158 33.9794 24.0208 33.9912 24.0244C33.9912 25.9229 33.9858 27.8203 33.9982 29.7178C33.9982 29.9588 33.9531 30.0057 33.7264 30.0057C23.105 29.9982 12.4838 29.996 1.86293 29.9991C1.30954 29.9991 0.75616 29.9907 0.20366 30.0038C0.0372014 30.0038 -0.000870453 29.9635 1.4964e-05 29.7882C0.0070983 27.8438 0.00444097 25.8994 0.00444097 23.9541H0.136367C0.667617 24.1069 1.21569 24 1.7558 24.0132L13.2662 24.0188L14.8963 24.0094Z'
          fill='#FEFEFE'
        />
        <path
          d='M33.9589 23.9999H30.3854C30.3312 23.943 30.2719 23.8918 30.2083 23.8471C27.1206 22.0271 24.0322 20.2083 20.9433 18.3908C20.7769 18.2971 20.6945 18.2699 20.6954 18.5268C20.7007 20.2949 20.6954 22.063 20.7007 23.8312C20.7059 23.8893 20.7163 23.9467 20.7317 24.0027H19.1025C19.1087 23.8621 19.122 23.7215 19.122 23.5827C19.122 20.5827 19.1273 17.5883 19.1149 14.5921C19.1149 14.2893 19.1973 14.2358 19.4629 14.2358C24.3032 14.2452 29.1461 14.2474 33.9917 14.2424V16.1408C33.8598 16.1465 33.7261 16.1568 33.5959 16.1568H26.2904L26.2673 16.2346L33.9917 20.7787V21.7621C30.9016 19.9496 27.8115 18.133 24.7214 16.3124C24.5403 16.2021 24.3344 16.146 24.1255 16.1502C23.1082 16.1577 22.089 16.1502 21.0726 16.1558C20.984 16.1558 20.884 16.1221 20.7786 16.2055C20.8822 16.2702 20.9655 16.3255 21.0513 16.3762C24.1409 18.1949 27.2307 20.0137 30.3208 21.8324C31.5364 22.5562 32.7468 23.2827 33.9589 23.9999Z'
          fill='#EEEEEE'
        />
        <path
          d='M13.2667 -0.00927734H14.895C14.8879 0.13041 14.8764 0.271973 14.8764 0.409785C14.8764 3.40979 14.8702 6.40416 14.8826 9.40135C14.8826 9.70416 14.8012 9.7576 14.5347 9.75666C9.69438 9.74791 4.85145 9.74572 0.00585938 9.7501V7.85353C0.137786 7.84603 0.271482 7.83197 0.401638 7.83197H7.7143L7.72846 7.75135L0.00585938 3.21572V2.23135C0.0651823 2.26322 0.125391 2.29135 0.182943 2.3251C3.21579 4.10822 6.24775 5.89291 9.27883 7.67916C9.4603 7.78816 9.66611 7.84353 9.87472 7.83947C10.9036 7.83104 11.9324 7.83947 12.9613 7.83291C13.0365 7.83291 13.1322 7.88447 13.2083 7.76729L12.9754 7.62947C10.0406 5.9026 7.10572 4.1751 4.17086 2.44697C2.79197 1.63447 1.41544 0.817597 0.0412728 -0.00365257H3.61393C3.66887 0.0518145 3.72811 0.102305 3.79102 0.147286C6.87817 1.96604 9.96502 3.78447 13.0516 5.6026C13.2774 5.73572 13.3013 5.65604 13.3013 5.43197C13.296 3.69947 13.3013 1.96322 13.3013 0.234473C13.2951 0.152473 13.2836 0.071026 13.2667 -0.00927734Z'
          fill='#EEEEEE'
        />
        <path
          d='M1.75721 24.0049C1.21711 23.9918 0.672577 24.0987 0.137786 23.9458C0.226327 23.883 0.314871 23.8137 0.411382 23.7583C4.57933 21.3058 8.74728 18.8552 12.9152 16.4065C13.0126 16.3493 13.1357 16.3127 13.2074 16.1683C12.0165 16.1683 10.8566 16.1683 9.69763 16.1683C9.56537 16.1783 9.4381 16.2259 9.3293 16.3062L6.69518 17.854L0.00585938 21.7755V19.6615C0.0581787 19.6495 0.109205 19.6319 0.158148 19.609C2.02874 18.5115 3.89845 17.4127 5.76727 16.3127C5.81596 16.2846 5.90362 16.2743 5.86643 16.1665C5.40336 16.1665 4.9394 16.1665 4.47633 16.1665L0.00585938 16.1505V14.2502C4.83728 14.2502 9.66929 14.248 14.5019 14.2437C14.7914 14.2437 14.8853 14.3027 14.8835 14.6346C14.8693 17.6196 14.8747 20.6055 14.8764 23.5905C14.8764 23.7302 14.8888 23.8718 14.8959 24.0105H13.2667C13.2826 23.9075 13.2933 23.8036 13.2986 23.6993C13.2986 21.6174 13.2986 19.5352 13.2986 17.4527C13.2986 17.3777 13.3278 17.2915 13.2428 17.2127C13.1906 17.2408 13.1322 17.2699 13.0764 17.3065C9.38242 19.479 5.68905 21.6518 1.99627 23.8249C1.91331 23.8799 1.83348 23.94 1.75721 24.0049Z'
          fill='#EEEEEE'
        />
        <path
          d='M32.2414 0.000229771L33.6174 -0.0128966C33.6606 -0.0187028 33.7046 -0.0152203 33.7466 -0.002657C33.7886 0.00990632 33.8277 0.0312959 33.8617 0.0602311C33.7325 0.146481 33.6058 0.239294 33.473 0.318044C29.3612 2.73679 25.2493 5.15585 21.1374 7.57523C21.0382 7.63335 20.9125 7.66054 20.8505 7.78898C20.9559 7.87242 21.0692 7.83211 21.1746 7.83211C22.0255 7.83211 22.8781 7.81242 23.729 7.84336C24.2124 7.85831 24.6897 7.72587 25.1041 7.46179C28.0596 5.70585 31.0284 3.97429 33.9928 2.23429V4.34367C33.9305 4.36176 33.8698 4.38528 33.8113 4.41398C31.9566 5.50398 30.1028 6.59523 28.25 7.68773C28.1986 7.71773 28.1136 7.72523 28.1278 7.84054H29.5143L33.9928 7.85742V9.75023C29.1525 9.75023 24.3096 9.75242 19.464 9.75679C19.1983 9.75679 19.1151 9.7043 19.116 9.40148C19.1284 6.40148 19.124 3.4071 19.1231 0.409917C19.1231 0.270229 19.1107 0.128667 19.1045 -0.0091458H20.7328C20.7164 0.0938568 20.7058 0.197785 20.7009 0.302105C20.7009 2.38398 20.7009 4.46648 20.7009 6.5496C20.7009 6.62367 20.6708 6.71086 20.7567 6.78867C20.8098 6.76054 20.8682 6.73242 20.924 6.70054C24.618 4.52867 28.3113 2.35585 32.0041 0.182105C32.0867 0.126708 32.1659 0.0659676 32.2414 0.000229771Z'
          fill='#EEEDEE'
        />
        <path
          d='M0.0412728 0.000196457C1.41721 0.812696 2.79374 1.62832 4.17086 2.44707C7.10513 4.17645 10.04 5.90395 12.9754 7.62957L13.2083 7.76738C13.1322 7.88457 13.0365 7.83207 12.9613 7.83301C11.9324 7.83301 10.9036 7.83301 9.87472 7.83957C9.66611 7.84363 9.4603 7.78826 9.27883 7.67926C6.24893 5.89051 3.21697 4.10582 0.182943 2.3252C0.125391 2.29051 0.0651823 2.26238 0.00585938 2.23145V-0.0185547C0.0181213 -0.0133219 0.029962 -0.00705046 0.0412728 0.000196457Z'
          fill='#D7032A'
        />
        <path
          d='M33.9597 23.9999C32.7476 23.279 31.5372 22.5524 30.3224 21.8361C27.2341 20.0155 24.1443 18.1968 21.053 16.3799C20.9644 16.3293 20.8839 16.274 20.7803 16.2093C20.8856 16.1258 20.9848 16.1596 21.0742 16.1596C22.0916 16.1596 23.1107 16.1596 24.1271 16.154C24.336 16.1498 24.542 16.2058 24.723 16.3161C27.8114 18.1355 30.9015 19.9521 33.9933 21.7658V24.0158C33.9816 24.0119 33.9703 24.0066 33.9597 23.9999Z'
          fill='#D7032A'
        />
        <path
          d='M33.9928 2.23051C31.0285 3.97051 28.0605 5.69926 25.1041 7.45801C24.6898 7.72208 24.2125 7.85453 23.7291 7.83957C22.8782 7.80863 22.0255 7.83207 21.1747 7.82832C21.0693 7.82832 20.956 7.86863 20.8506 7.7852C20.9126 7.65676 21.0383 7.62957 21.1375 7.57145C25.2482 5.15082 29.36 2.73176 33.4731 0.31426C33.6059 0.23551 33.7325 0.142697 33.8618 0.056447L33.9928 0.0517578V2.23051Z'
          fill='#D70329'
        />
        <path
          d='M0.00585938 21.7698L6.69518 17.8482L9.3293 16.3004C9.4381 16.2202 9.56537 16.1726 9.69763 16.1626C10.8566 16.1626 12.0165 16.1626 13.2074 16.1626C13.1357 16.3107 13.0126 16.3435 12.9152 16.4007C8.74728 18.8532 4.57933 21.3048 0.411382 23.7554C0.316642 23.8117 0.228984 23.881 0.137786 23.9429H0.00585938V21.7698Z'
          fill='#D70329'
        />
        <path
          d='M0.00585938 3.21484L7.72846 7.75047L7.7143 7.83109H0.401638C0.269711 7.83109 0.136016 7.84516 0.00585938 7.85266V3.21484Z'
          fill='#0454B4'
        />
        <path
          d='M33.9929 20.7862L26.2686 16.2422L26.2916 16.1644H33.5971C33.7291 16.1644 33.8628 16.1541 33.9929 16.1484V20.7862Z'
          fill='#0454B4'
        />
        <path
          d='M33.9925 7.85359L29.5141 7.83672H28.1275C28.1134 7.72141 28.1984 7.7139 28.2497 7.6839C30.1026 6.59078 31.9564 5.49953 33.811 4.41016C33.8696 4.38146 33.9303 4.35794 33.9925 4.33984V7.85359Z'
          fill='#0555B4'
        />
        <path
          d='M0.00585938 16.1475L4.47633 16.1634C4.9394 16.1634 5.40336 16.1634 5.86643 16.1634C5.90362 16.2712 5.81596 16.2815 5.76727 16.3096C3.89786 17.4115 2.02815 18.5109 0.158148 19.6078C0.109205 19.6307 0.0581787 19.6483 0.00585938 19.6603V16.1475Z'
          fill='#0555B4'
        />
        <path
          d='M32.2418 -0.000155449C32.1664 0.0675099 32.0872 0.130132 32.0045 0.187345C28.3123 2.36172 24.6189 4.53453 20.9244 6.70578C20.8686 6.73766 20.8101 6.76578 20.757 6.79391C20.6685 6.71609 20.7012 6.62891 20.7012 6.55485C20.7012 4.47172 20.7012 2.38922 20.7012 0.307344C20.7061 0.203025 20.7168 0.0990964 20.7331 -0.00390625L32.2418 -0.000155449Z'
          fill='#0253B4'
        />
        <path
          d='M13.2668 -0.00927734C13.2826 0.0701675 13.2933 0.15067 13.2987 0.231661C13.2987 1.96416 13.2987 3.70041 13.2987 5.42916C13.2987 5.65322 13.2748 5.73291 13.049 5.59979C9.96419 3.77854 6.87733 1.9601 3.78841 0.144474C3.7255 0.0994931 3.66627 0.0490021 3.61133 -0.00646496L13.2668 -0.00927734Z'
          fill='#0353B4'
        />
        <path
          d='M1.75781 24.0048C1.83389 23.9373 1.91372 23.8747 1.99688 23.8173C5.69024 21.6429 9.38361 19.4701 13.077 17.2988C13.1328 17.266 13.1912 17.237 13.2434 17.2051C13.332 17.2838 13.2992 17.3701 13.2992 17.4451C13.2992 19.5276 13.2992 21.6098 13.2992 23.6916C13.2939 23.7959 13.2832 23.8998 13.2673 24.0029L1.75781 24.0048Z'
          fill='#0253B4'
        />
        <path
          d='M20.7326 24.0103C20.7172 23.9543 20.7068 23.8969 20.7016 23.8388C20.7016 22.0706 20.7016 20.3025 20.6963 18.5344C20.6963 18.2775 20.7777 18.301 20.9442 18.3985C24.0319 20.2191 27.1203 22.0378 30.2092 23.8547C30.2728 23.8994 30.3321 23.9506 30.3863 24.0075L20.7326 24.0103Z'
          fill='#0354B4'
        />
      </g>
      <defs>
        <clipPath id='clip0_7501_653'>
          <rect width='34' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

import React, { useState } from 'react';
import Modal from 'react-modal';
import 'react-quill/dist/quill.snow.css';
import { Button } from '../../../components/forms/Buttons/Button';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CustomQuill from '../CustomQuill';
import { UploadFile } from '../../../components/Icons/UploadFileIcon';
import { getPosts, postPosts } from '../../../services/Feeds/FeedService';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';

Modal.setAppElement('#root');
const customStyle = {
  content: {
    width: '50%',
    height: '87%',
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #ccc',
    background: '#fff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

export const ModalComponent = ({
  isOpen,
  onRequestClose,
  user,
  onPostCreated,
}) => {
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  // const [formattingOptions, setFormattingOptions] = useState({});

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleQuillChange = (content, delta, source, editor) => {
    setValue(content);

    const contents = editor.getContents();

    if (contents && contents.ops) {
      const formats = contents.ops.reduce((acc, op) => {
        if (op.attributes) {
          Object.keys(op.attributes).forEach((key) => {
            if (!acc[key]) {
              acc[key] = op.attributes[key];
            }
          });
        }
        return acc;
      }, {});
      // setFormattingOptions(formats);
    }
  };

  const onBrowseClick = () => {
    const fileInput = document.getElementById('fileInput')
    fileInput.click();
  }

  const onSubmit = () => {
    if (!value || !title) {
      toast.error('required fields empty');
      return;
    }

    // const cleanValue = value.replace(/<[^>]*>/g, ''); // если без тэгов.

    const body = new FormData();
    body.append('author', user?.uuid);
    body.append('title', title);
    body.append('content', value);
    if (selectedFile) {
      body.append('file_uploads', selectedFile);
    }

    // body.append('created_at', new Date().toDateString
    // console.log( 'author', body.get('author'))
    // console.log( 'content', body.get('content'))
    console.log('files', body.get('file_uploads'));
    // console.log( 'created_at', body.get('created_at'))

    postPosts(body)
      .then((res) => {
        toast.success('post created successfully');
        onPostCreated();
        navigate(`/feed`);
        onRequestClose();
        setTitle('');
        setValue('');
        setSelectedFile('');
        getPosts();
      })
      .catch((error) => {
        console.error('Ошибка при выполнении POST-запроса:', error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel='Example Modal'
      style={customStyle}
    >
      <div className='flex flex-col overflow-x-hidden items-start justify-start'>
        <div className='flex flex-row justify-start items-center cursor-pointer w-auto h-auto p-2 ml-3  '>
          <img
            alt='img'
            className='w-14 h-14 rounded-3xl'
            src={user.profile_picture === false || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
          />
          <h3 className='pl-2'>
            {user.first_name} {user.last_name}{' '}
          </h3>
        </div>
        <div className='flex flex-col w-full justify-start mt-4 mb-6'>
          <input
            name='title'
            className=' w-full h-8 border border-gray-300 px-2 outline-none'
            placeholder='Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className='overflow-x-hidden h-96 w-full'>
          <CustomQuill value={value} onChange={handleQuillChange} />
        </div>
      </div>
      <div className='flex items-center justify-end'>
        {selectedFile ? (
          <label className='flex justify-start items-start'>
            <Button onClick={onBrowseClick}>Browse File <ArrowUpTrayIcon className=' ml-2 w-5 h-5'/> </Button>{' '}
            <span className=' mt-2 pl-2 text-xs italic'>{selectedFile.name}</span>{' '}
            <input id='fileInput' onChange={handleFileChange} className='hidden' type='file' />
          </label>
        ) : (
          <label className='flex flex-col'>
            <Button onClick={onBrowseClick}>Browse File <ArrowUpTrayIcon className=' ml-2 w-5 h-5'/> </Button>
            <input id='fileInput'  onChange={handleFileChange} className='hidden' type='file' />
            <span className='ml-8 text-gray-500'>(jpg,jpeg,png)</span>
          </label>
        )}
        <Button className={'ml-auto mr-4'} onClick={onSubmit}>
          Create
        </Button>
      </div>
    </Modal>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { getStartedStudyPlan } from '../../../../services/studyPlans/studyPlanService';
import PlacedholderCard from '../../../../components/cards/PlaceholderCard';
import StudyPlanCard from '../../../../components/cards/StudyPlanCard';
import NoContentCard from '../../../../components/cards/NoContentCard';

const ProjectSets = ({ uuid }) => {
  const [projectSets, setProjectSets] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(() => {
    setLoading(true);
    getStartedStudyPlan(uuid)
      .then((res) => {
        setProjectSets(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [uuid]);

  useEffect(() => {
    getData();

    return () => {};
  }, [getData]);

  return (
    <div className='flex flex-col w-full p-4 pt-0'>
      <div className='flex flex-row flex-wrap gap-4'>
        {loading ? (
          <PlacedholderCard />
        ) : projectSets.length ? (
          projectSets?.map((sp) => (
            <StudyPlanCard
              key={sp?.uuid}
              studyPlan={sp}
              refreshData={getData}
            />
          ))
        ) : (
          <div className='w-full flex flex-row justify-center py-6 text-gray-400 text-lg'>
            No content
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectSets;

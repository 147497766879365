import bilAPI from '../api';

export const getProjectAssignments = ({
  uuid,
  is_in_progress,
  student,
  mentor,
  project,
  submitted,
  organization,
  has_mentor,
  page,
  page_size,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/submissions/project_assignment/`, {
          params: {
            uuid,
            is_in_progress,
            student,
            mentor,
            project,
            submitted,
            organization,
            has_mentor,
            page,
            page_size,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const postProjectAssignment = ({
  is_in_progress,
  student,
  mentor,
  project,
}) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/submissions/project_assignment/`, {
          is_in_progress,
          student,
          mentor,
          project,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getProjectAssignment = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/submissions/project_assignment/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const patchProjectAssignment = (
  uuid,
  { is_in_progress, student, mentor, project },
) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/submissions/project_assignment/${uuid}/`, {
          is_in_progress,
          student,
          mentor,
          project,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const deleteProjectAssignment = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/submissions/project_assignment/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getAccounts = ({ role }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/account/`, {
          params: {
            role,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

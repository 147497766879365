import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postStudyPlan } from '../../services/studyPlans/studyPlanService';
import bgImage from '../../assets/images/bg.jpg';
import { Button } from '../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../../utils/contexts/UserContext';
import Input from '../../components/forms/Inputs/InputBil';
import { toast } from 'react-toastify';
import RichTextEditor from '../../components/forms/Inputs/RichTextEditor';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const CreateStudyPlan = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [setTitle, setSetTitle] = useState('');
  const [setDescription, setSetDescription] = useState('');

  return (
    <div>
      <div className='flex  flex-col'>
        <div
          className='h-48 flex flex-row'
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
          }}
        >
          <div className='w-1/3 px-4 py-8'>
            <Button
              text={t('buttons.back')}
              color='secondary-solid'
              Icon={ChevronLeftIcon}
              onClick={() => navigate(-1)}
            />
          </div>
          <div className='text-white w-2/3 flex flex-col h-full justify-end gap-8 px-8 py-8'>
            <div className='text-2xl lg:text-4xl font-medium pl-4'>
              {t('studyplans.project_set_constructor')}
            </div>
            <div className='flex flex-row justify-end'>
              <Button
                color={'success'}
                text={t('buttons.next')}
                onClick={() => {
                  if (!setTitle || !setDescription) {
                    toast.error(t('roadmap.required_fields_empty'));
                    return;
                  }
                  setLoadingCreate(true);
                  const body = new FormData();
                  body.append('title', setTitle);
                  body.append('description', setDescription);
                  body.append('author', user?.uuid);

                  postStudyPlan(body)
                    .then((res) => {
                      toast.success(t('roadmap.saved_successfully'));
                      navigate(`/study-plans/edit/${res?.uuid}`);
                    })
                    .catch(() => {})
                    .finally(() => setLoadingCreate(false));
                }}
                loading={loadingCreate}
              />
            </div>
          </div>
        </div>

        <div className='flex lg:flex-row md:flex-row flex-col'>
          <div className='lg:w-1/3 md:w-1/3 px-4'>
            <div
              className='rounded-xl border shadow-lg py-4 px-6 flex flex-col divide-y-2
                w-full bg-white lg:-mt-12 md:-mt-12 -mt-4 font-medium lg:text-lg'
            >
              <div
                className={`py-2 flex flex-row items-center gap-2 cursor-pointer`}
              >
                <DocumentTextIcon className='h-4' />
                {t('studyplans.overview')}
              </div>
            </div>
          </div>
          <div className='lg:w-2/3 md:w-2/3 py-6 px-4'>
            <div className='flex flex-col gap-4'>
              <Input
                label={t('studyplans.project_set_title')}
                onChange={(e) => {
                  setSetTitle(e.target.value);
                }}
                value={setTitle}
                required
              />
              <RichTextEditor
                inputStyles='h-80'
                required
                label={t('studyplans.project_set_description')}
                value={setDescription}
                onChange={(e) => setSetDescription(e)}
              />
              {/* <TextArea
                label={t('studyplans.project_set_description')}
                value={setDescription}
                onChange={(e) => setSetDescription(e.target.value)}
                required
                rows={5}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStudyPlan;

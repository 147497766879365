import React, { useContext, useEffect, useState } from 'react';
import Content from './Contents/Content';
import { postUserProgress } from '../../../../services/courses/userProgressService';
import { UserContext } from '../../../../utils/contexts/UserContext';
import { Button } from '../../../../components/forms/Buttons/Button';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { getLessons } from '../../../../services/courses/lessonService';
import { getSections } from '../../../../services/courses/sectionService';

const Student = ({ data, refreshData, course, section }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [nextLesson, setNextLesson] = useState();
  useEffect(() => {
    console.log(course);

    if (data?.status === 0 && user.uuid) {
      postUserProgress({ lesson: data?.uuid, status: 2, user: user.uuid });
    }
    if (data) {
      getLessons({ order: data?.order + 1, section: data?.section }).then(
        (res) => {
          if (res.results?.length) {
            setNextLesson(res.results[0]);
            return;
          } else if (section) {
            getSections({
              course: section?.course,
              order: section?.order + 1,
            }).then((resSections) => {
              if (resSections.results?.length) {
                getLessons({ section: resSections.results[0]?.uuid }).then(
                  (resLessons) => {
                    setNextLesson(resLessons.results[0]);
                  },
                );
              }
            });
          }
        },
      );
    }
  }, [data, section, user.uuid]);
  return (
    <div className='flex flex-col gap-2 p-4'>
      <div>
        <Link to={`/courses/${course?.uuid}`}>
          <Button
            text={'Back'}
            color='secondary-solid'
            Icon={ChevronLeftIcon}
            onClick={() => {
              navigate({
                to: `/courses/${course?.uuid}`,
              });
            }}
          />
        </Link>
      </div>
      <div className='text-center text-2xl font-bold capitalize'>
        {`Course: ${course?.name} | Section: ${section?.name} | Lesson: ${data?.name}`}
      </div>
      <div className='flex flex-col gap-2 p-8 border rounded-lg'>
        {data?.content.map((c) => {
          return <Content key={c.uuid} data={c} refreshData={refreshData} />;
        })}
      </div>
      <div className='flex flex-row justify-center'>
        {nextLesson && (
          <Link to={`/courses/${course?.uuid}/lesson/${nextLesson.uuid}`}>
            <Button
              text='Go to next Lesson'
              onClick={() => {
                navigate({
                  to: `/courses/${course?.uuid}/lesson/${nextLesson.uuid}`,
                });
                setNextLesson();
              }}
            />
          </Link>
        )}
      </div>
    </div>
  );
};
export default Student;

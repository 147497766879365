import React, { useState } from 'react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';

const Upload = ({ setFile }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
      alert('Please select an image file.');
    }
  };

  const handleClick = () => {
    document.getElementById('imageUploadInput').click();
  };

  return (
    <div className='upload-container flex justify-center items-center'>
      <input
        id='imageUploadInput'
        type='file'
        accept='image/*'
        onChange={handleImageChange}
        className='hidden'
      />

      <div
        className={`relative w-screen h-52   border-gray-400 rounded-lg cursor-pointer flex justify-center items-center overflow-hidden transition-all duration-300 ${
          imagePreview ? 'bg-transparent' : 'bg-custom-gray'
        }`}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Image Preview */}
        {imagePreview && (
          <img
            src={imagePreview}
            alt='Uploaded Preview'
            className={`w-full h-full object-cover rounded-lg transition-opacity duration-300 ${
              isHovered ? 'opacity-50' : 'opacity-100'
            }`}
          />
        )}

        {/* Icon Display - Shown on hover or if no image uploaded */}
        {(!imagePreview || isHovered) && (
          <div className='absolute inset-0 flex justify-center items-center bg-transparent'>
            <ArrowUpTrayIcon className='h-12 w-12 text-gray-600' />
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;
